import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';
import '../styles/style.scss';
import moment from 'moment'
import {ReactComponent as PlusIcon} from '../../assets/images/plus.svg'
import {ReactComponent as DeleteIcon} from '../../assets/images/trash-can-xmark.svg'

import { useHistory } from 'react-router-dom';
import { CreateMapping, RemoveMapping, UpdateMapping, getRoleDetail, removeRole } from '../actionMethods/actionMethods'
import WarningModal from '../../components/WarningModal';
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import { Collapse, Checkbox, Select } from 'antd';
import Pages from './pages.json'
import { getPageList,getSubPageList } from '../../webappPageManagement/actionMethods/actionMethods';
import { getOrgWebpages } from '../actionMethods/actionMethods';
import Scrollbars from 'react-custom-scrollbars';
import {ReactComponent as ArrowDown} from '../../assets/images/arrow-down-long.svg'
import {ReactComponent as ArrowUp} from '../../assets/images/arrow-up-long.svg'
import {ReactComponent as ArrowDownUp} from '../../assets/images/arrow-down-arrow-up.svg'
import {ReactComponent as CircleArrow} from '../../assets/images/caret-right.svg';
import {ReactComponent as CircleArrowDown} from '../../assets/images/caret-down.svg'
import {ReactComponent as EditIcon} from '../../assets/images/pen-to-square.svg'
import {ReactComponent as AddIcon} from '../../assets/images/square-plus.svg'
import {ReactComponent as EyeIcon} from '../../assets/images/eye.svg'
import {ReactComponent as AddSubIcon} from '../../assets/images/plus-octagon.svg'
import LoaderSpinner from '../../assets/images/loader.svg'


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '90%',
        overflow: 'visible'

    },
};


function ClientAppManagement(props) {
  let history = useHistory();
    const { Panel } = Collapse;
    const {Option} = Select

  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';

  const [modalGroup,SetModalGroup]= useState(false);   
  const [modalGroupEdit,SetModalGroupEdit]= useState(false);   
  const [modalPage,SetModalPage]= useState(false);   
  const [modalPageEdit,SetModalPageEdit]= useState(false);   
  const [modalAdd,SetModalAdd]= useState(false);   
  const [modalAddEdit,SetModalAddEdit]= useState(false);   
  const [modalDelete,SetModalDelete]= useState(false);   
  const [dataGroup,SetDataGroup] = useState([]);  
  const [searchPage,SetSearchPage] = useState("");
  const [permission,SetPermission]=useState([])
  const [permissionAPICall,SetPermissionAPICall]=useState(false);
  const [deleteID,SetDeleteID] =useState('');
  const [title,SetTitle]=useState('');
  const [description,SetDescription]= useState('');

  const [modalSucess,SetModalSucess]= useState(false);
  const [modalError,SetModalError]= useState(false);
  const [inc,setInc]=useState(0);
  const [checkedMainPages, setCheckedMainPages] = useState([]);
  const [checkedSubPages, setCheckedSubPages] = useState([]);
  const [pageList,SetPageList] = useState([])
  const [subPageList,SetSubPageList] = useState([])

  const [isOpen, setIsOpen] = useState(false);
  const [isSubOpen, setIsSubOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedSubPage, setSelectedSubPage] = useState(null);
  const [page_id,SetPageID] = useState('');
  const [mapping_id,SetMappingID]= useState('')
  const [sub_page_id,SetSubPageID]= useState('')
  const [pageVersion,SetPageVersion]= useState('');
  const [subPageVersion,SetSubPageVersion]= useState('');
  const [subPageVersionList,SetSubPageVersionList] = useState([])
  const [pageVersionList,SetPageVersionList] = useState([])
  const [pageAll,SetPageAll] = useState([])
  const [loader,SetLoader]= useState(true);
  const [pageIDError,SetPageIDError]= useState('')
  const [subPageIDError,SetSubPageIDError]= useState('')
  const [pageVersionError,SetPageVersionError]= useState('')
  const [subPageVersionError,SetSubPageVersionError]= useState('')
  const [mappedPages,SetMappedPages]= useState([])
  const [mappedPagesAll,SetMappedPagesAll]= useState([]);
  const [search,SetSearch]= useState('');
  const [activeAccord,SetActiveAccord]= useState([])
  const [editModal,SetEditModal]= useState(false)
  const [modalSub,SetModalSub]= useState(false)
  const [deleteModal,SetDeleteModal]= useState(false)
  const [apiCall,SetAPICall]= useState(false);
  const [pageName,SetPageName]= useState("")
  const [createdPage,SetCreatedPage]= useState([]);
  
  useEffect(()=>{
   if((userDetails.org_type && userDetails.org_type!=="oem") || !userDetails.firstLoad ) {
        history.push('/dashboard');
   }
  },[])
  useEffect(()=>{
    let mp=[...mappedPagesAll];
    let arr=[];
    for(let i=0;i<mp.length;i++){
      if(mp[i].page_name.toLowerCase().trim().includes(search.toLowerCase().trim())){
        arr.push(mp[i]);
      }else{
       let subPage =  mp[i].sub_pages.filter(el=>el.sub_page_name.toLowerCase().trim().includes(search.toLowerCase().trim()))
       if(subPage.length> 0){
        let newArr = mp[i];
        newArr.sub_pages = subPage;
        arr.push(newArr);
       }
      }
    }
    SetMappedPages(arr)


  },[search])
  
    
  useEffect(() => {

        let requestBody={}
        requestBody.session=session
        requestBody.org_id=userDetails.org_id
        requestBody.role_id =userDetails.role;
        getRoleDetail(requestBody).then(res=>{
            if(res.status==200 || res.code==200){
                SetPermission(JSON.parse(res.data[0].role))

                SetPermissionAPICall(true)
            }
            if(res.status==300){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)        
            }
        }).catch()


    }, []);
  function transformedData(data){

                const groupedData = {};

// Iterate through the original data
data.forEach(entry => {
  // Check if the page_name is already in the grouped data
  if (groupedData[entry.page_name]) {
    // If it exists, push the version to the existing array
    groupedData[entry.page_name].versionList.push({page_id:entry.page_id,page_name:entry.page_name,url:entry.url,version:entry.version});
  } else {
    // If it doesn't exist, create a new entry with the version as an array
    let subFIlter = entry.sub_pages?entry.sub_pages:[];
    let subAr=[]
    subFIlter.forEach(subElm=>{
        
        if (subAr[subElm.sub_page_name]) {
            
            subAr[subElm.sub_page_name].versionList.push({page_id:subElm.page_id,sub_page_id:subElm.sub_page_id,page_name:subElm.page_name,sub_page_name:subElm.sub_page_name,url:subElm.sub_web_page_url,version:subElm.sub_webpage_version});

        }else{
            
            subAr[subElm.sub_page_name] = {
                created_at: subElm.created_at,
                page_id: subElm.page_id,
                page_name: subElm.page_name,
                sub_page_name:subElm.sub_page_name,
                sub_page_id:subElm.sub_page_id,
                url: subElm.sub_web_page_url,
                version:subElm.sub_webpage_version,
                versionList: [{page_id:subElm.page_id,sub_page_id:subElm.sub_page_id,page_name:subElm.page_name,sub_page_name:subElm.sub_page_name,url:subElm.sub_web_page_url,version:subElm.sub_webpage_version}]
            };

        }
        
    })
 



    const result2 = Object.values(subAr);

    groupedData[entry.page_name] = {
      created_at: entry.created_at,
      page_id: entry.page_id,
      page_name: entry.page_name,
      url: entry.url,
      version:entry.version,
      versionList: [{page_id:entry.page_id,page_name:entry.page_name,url:entry.url,version:entry.version}],
      sub_pages:result2
    };
  }
});

// Convert the grouped data object back to an array
const result = Object.values(groupedData);

return result


  }
      function AccordionHandler(val,type='secondary'){
        
        let ActivList = [...activeAccord];
        let index = ActivList.indexOf(val)
        if(index !== -1){  
            ActivList.splice(index, 1);
            SetActiveAccord(ActivList);
        } 
        else if(type=='primary'){
            SetActiveAccord([val]);
     }  
      else {  
            ActivList.push(val);
            SetActiveAccord(ActivList);
        }
        

    }

  useEffect(()=>{
    let requestBody={}
    requestBody.session=userDetails.session
    requestBody.org_id=userDetails.org_id
    let pages=[]
    let addedPages=[]
    getOrgWebpages(requestBody).then(res=>{
      SetAPICall(true)

      Object.keys(res).forEach((key)=>{
      let single={
        ...res[key].find(el=>el.page_name== key && el.sub_page_name==null && el.sub_page_url== null)
      }
      addedPages.push(res[key].find(el=>el.page_name== key && el.sub_page_name==null && el.sub_page_url== null))
      single.sub_pages = res[key].filter(el=>el.page_name== key && el.sub_page_name!==null && el.sub_page_url!== null);
      single.url= single.page_url
      pages.push(single); 

        
      })
      SetMappedPages(pages);
      SetMappedPagesAll(pages);
      SetCreatedPage(addedPages);
   
    })

  },[inc]);

  useEffect(()=>{
        let requestBody={}
    requestBody.session=userDetails.session
    requestBody.org_id=userDetails.org_id

        let arr = []
        getPageList(requestBody).then(res => {
            if(res.status==200 || res.code ==200){
              

                getSubPageList(requestBody).then(res2=>{
                    if(res2.status==200 || res2.code ==200){
                        let pageParent= res.webpages;
                        let childPages = res2.sub_webpages;
                        

                        for(let i=0;i<pageParent.length;i++){
                            let subCurrent= childPages.filter(el=>el.page_id ==pageParent[i].page_id )
                            arr.push({...pageParent[i],sub_pages:subCurrent})
                            
                        }
                    }
              
                SetPageList(transformedData(arr));
                SetLoader(false);

                })

            }
        })

  },[])

  function ShowCardListPage(data){
    let arr= [];
    if(data.length> 0){

    for (let i=0;i<data.length;i++){
      arr.push(            
      <Row key={'contentGroup'+i} className={activeAccord.indexOf(i)!==-1?'contentGroup active':'contentGroup'}>
            <div className='group-header'> 
             <Col lg={3} className="align-middle">
                 {/* <span className='align-with-title' style={{width:"50px"}}><Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} /></span> */}
                 {data[i]?.sub_pages.length>0?<span className='accordion-icon' onClick={()=>AccordionHandler(i,'primary')} >
                   {activeAccord.indexOf(i)!==-1|| search.length > 0?<CircleArrowDown/>:<CircleArrow />}   
                 </span>:""}
                 <span className='align-title-left'>
                    <h4 className='textCap' >{data[i].page_name?data[i].page_name.toLowerCase():""}</h4>
                 </span>
               
             </Col>
             <Col lg={3} className="align-middle">
                 <div className='inlineVersion'>
      <div className='versionInlineList' key={data[i].webpage_id?data[i].webpage_id:"rand"+new Date().getTime()}>
        {data[i].webpage_version?data[i].webpage_version:""}
        <span className='tootlipVersionImage'>
          <img src={data[i].url && data[i].url.startsWith("http")?data[i].url:"https://traceplus.ai/wp-content/uploads/2023/12/dummy_750x750_ffffff_cccccc.png"} alt={`Version ${data[i].page_id}`} />
        </span>
      </div>
   
      </div>
             </Col>
             
             <Col lg={6} className="align-middle">
              
        {pageList.find(elm=>elm.page_id == data[i].webpage_id)?.sub_pages.length>0?<button className='btn btn-smaller btn-icon' 
                    onClick={()=>addSubModalShow(data[i],"Parent")}
                    
                    ><AddSubIcon/><span></span></button>:""}  
                    
              
                    <button className='btn btn-smaller btn-icon' 
                    onClick={()=>editModalShow(data[i],"Parent")}
                    
                    ><EditIcon/><span></span></button>  

             <button className='btn btn-smaller btn-icon btn-delete'
               onClick={()=>
                modalDeleteModal(data[i]?.page_name,data[i].mapping_id)}
               
               ><DeleteIcon/><span></span></button>
                            </Col>
             </div>            
               {data[i].sub_pages.length>0?
               <div className={activeAccord.indexOf(i)!==-1 || search.length> 0?'group-content active':'group-content'} >
                      {
                        data[i].sub_pages.map(el=>
                            {

                            return( 
                            
                            <Row className='headerGroup headerGroupSubPages'>

            <Col lg={3} className="align-middle">
               <span class="align-title-left"><h4 class="textCap">{el?.sub_page_name?.toLowerCase()}</h4></span>         
            </Col>
            <Col lg={3} className="align-middle">
               
                                 <div className='inlineVersion'>
      <div className='versionInlineList' key={"sub_page"+el.sub_page_id}>
        {el.sub_web_page_version}
        <span className='tootlipVersionImage'>
        
          <img src={el?.sub_page_url?.startsWith("http")?el.sub_page_url:"https://traceplus.ai/wp-content/uploads/2023/12/dummy_750x750_ffffff_cccccc.png"} alt={`Version ${el?.sub_page_id}`} />
        </span>
      </div>

</div>
        
            </Col>
            <Col lg={6} className="align-middle" style={{textAlign:"right"}}>
                 
               
                    <button className='btn btn-smaller btn-icon' 
                    // onClick={()=>props.openModalEditSubPage(el,"Sub Page Version")}
                    onClick={()=>editModalShow(el,"child")}

                    ><EditIcon/><span></span></button>  
        <button className='btn btn-smaller btn-icon btn-delete'
        //  onClick={()=>{props.modalDeleteSubPage(el.sub_page_name,el.sub_page_id)}}
        onClick={()=> modalDeleteModal(el.sub_page_name,el.mapping_id)}
         ><DeleteIcon/><span></span></button>

            </Col>
        </Row> 
) 
                            
                        })
                      }
  
                      
             </div>
             :''}
         </Row>)
    }

    }else if(apiCall===false){
                  
        }
        else{
               

           arr.push( 
                    <Row className='contentGroup nofound' key={'contentGroup nofound'}>
                        <div className='group-header'>
                            <Col className='align-middle'>
                                <h6 className='listNOFound' style={{marginBottom:"0px"}}>No Data found</h6>
                            </Col>
                        </div>
                    </Row>
                    ) 
        }
    return arr;


  }

  useEffect(()=>{
    let subPage= pageList.find(el=> el.page_id == page_id || el.page_name == page_id);
    SetPageVersionList(subPage && subPage.versionList?subPage.versionList:[]);

    SetSubPageList(subPage &&subPage.sub_pages.length > 0?subPage.sub_pages:[]);
    // if(!editModal){
    //   SetPageVersion('');
    //   SetSubPageVersion('');
    // }

  },[page_id,pageList])

  useEffect(()=>{
    if(sub_page_id!=""){
    let subVersionList = subPageList.find(el=> el.sub_page_id == sub_page_id || el.sub_page_name == sub_page_id );
        
    SetSubPageVersionList(subVersionList && subVersionList.versionList.length > 0 ?subVersionList.versionList:[]);    
        if(!editModal){
    
    SetSubPageVersion('');
    }

    }


  },[sub_page_id,subPageList,editModal])





    function handleCloseModal(){
        SetModalPage(false)
        SetModalGroup(false)
        SetModalAdd(false)
        SetModalGroupEdit(false)
        SetModalPageEdit(false)
        SetSubPageID('');SetSubPageVersion('');SetSubPageID('');SetPageVersion('');
        
    }

    function modalOpenAddEmp(){
        SetModalAdd(true)
    }

    function modalOpenGroupEdit(){
        SetModalGroupEdit(true)
    }
    function modalOpenPageEdit(){
        SetModalPageEdit(true)
    }

    function modalDeleteModal(title,id){
        
        let checkSub = mappedPages.find(el=>el.mapping_id == id);
        if(checkSub &&  checkSub.sub_pages.length > 0){
          SetTitle(<><strong>Fail to delete</strong></>);        
          SetDescription(<>Fail to delete page having sub child pages. Please Delete <strong style={{color:"red"}}>Sub Pages first</strong> </>);        
          SetModalError(true);
        }else{
        SetDeleteID(id)
        SetMappingID(id)
        SetTitle(<><strong>{title}</strong> Mapping</>);     
        SetModalDelete(true)
        }

    }
    
    function handleCloseModalWarning(){
        SetModalDelete(false);
    }

function AddCreateHandler(){
    // history.push('/add-Page');
    
    
}
    function handleDeleteConfirm(){
        let requestBody={};
        requestBody.mapping_id=mapping_id
        requestBody.org_id = userDetails.org_id
        requestBody.session = userDetails.session
      console.log("Here child sub child exist ", mapping_id, mappedPages)
        if(mapping_id)

        RemoveMapping(requestBody).then(res=>{
          if(res.status==200 || res.code == 200){
            SetTitle("Mapping is deleted");
            setInc((prev)=>prev+1)
            SetModalDelete(false);
            SetModalSucess(true);
            
          }else{
            SetTitle("Failed to delete Mapping");
            SetDescription(res?.message);
            SetModalError(false);
          }
        })
      
        // removeRole(requestBody).then(res=>{
        //     if(res.status==200){
        //         setInc((prev)=>prev +1)
        //         SetTitle(title);
        //         SetDescription(res.message?res.message:'');
        //         SetModalSucess(true);
        //         SetModalDelete(false);    
                
        //     }else{
        //         SetTitle("Failed to delete");
        //         SetDescription(res.message?res.message:'');
        //         SetModalError(true);
        //         //SetModalDelete(false);
        //     }
            
        // })
      //  SetModalDelete(false);


    }
    function handleCloseModalSucess(){

      SetPageID('');SetSubPageVersion('');SetSubPageID('');SetPageVersion('');
      SetModalAdd(false);
      SetModalSub(false)
      SetEditModal(false)
        SetModalSucess(false);
    }
    function handleCloseModalError(){
        SetModalError(false);
        SetTitle('');
        SetDescription('');
    }
    


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsSubOpen(false);
  };
  const toggleSubDropdown = () => {
    setIsSubOpen(!isOpen);
    setIsOpen(false);
  };

  const handleOptionClick = (option) => {
    setSelectedPage(option);
    setIsOpen(false);
  };
  const handleSubOptionClick=(option)=>{
    setSelectedSubPage(option);
    setIsSubOpen(false);
  }

  function CreatePageHandler(){
    let errorCheck=true;

    if(page_id==""){
      errorCheck=false;
      SetPageIDError("Required");
    }else{
      SetPageIDError("");
    }


    if(pageVersionList.length > 0 && pageVersion==""){
      errorCheck=false;
      SetPageVersionError("Required");
    }else{
      SetPageVersionError("");
    }

    // if(sub_page_id=="" && subPageList.length> 0){
    //   errorCheck=false;
    //   SetSubPageIDError("Required");
    // }else{
    //   SetSubPageIDError("");
    // }

    // if(subPageVersion=="" && subPageVersionList.length> 0){
    //   errorCheck=false;
    //   SetSubPageVersionError("Required");
    // }else{
    //   SetSubPageVersionError("");
    // }
    //if all pass then proceed.
    if(errorCheck){
      let requestBody={
        "webpage_id": pageVersion,
        "sub_webpage_id": subPageVersion?subPageVersion:0,
        "org_id": userDetails.org_id,
        "session" : userDetails.session
      }
      CreateMapping(requestBody).then(res=>{
        if(res.status==200 || res.code ==200){
          SetTitle(<h4>Mapping is created</h4>);
          SetModalSucess(true)
          setInc((prev)=>prev+1);
          
        }else{
          SetTitle(<h4>Failed to Create</h4>);
          SetDescription(res?.message);
          SetModalError(true);
        }
      }) 
  }
}

function editModalShow(data,type){

  if(type=="child"){
    SetEditModal(true);
    SetPageName(data.page_name)
    SetPageID(data.page_name);
    SetPageVersion(data.webpage_id);
    SetSubPageID(data.sub_page_name)
    SetSubPageVersion(data.sub_webpage_id)
    SetMappingID(data.mapping_id);

    SetModalSub(true)
  }else{
    SetEditModal(true);
    SetPageID(data.page_name);
    SetMappingID(data.mapping_id);
    SetPageVersion(data.webpage_id);
    SetModalAdd(true);
  }

  
  

}

function addSubModalShow(data,type){


  // SetEditModal(true);
  SetPageID(data.webpage_id);
  SetPageName(data.page_name)
  // SetMappingID(data.mapping_id)
  SetPageVersion(data.webpage_id);
  

  // SetSubPageID(data.sub_webpage_id)
  // SetSubPageVersion(data.sub_webpage_id)
  SetModalSub(true);

}

function editMappingHandler(){
 let requestBody=  {
    "mapping_id" : mapping_id,
    "webpage_id": pageVersion,
    "sub_webpage_id": subPageVersion?subPageVersion:0,
    "org_id": userDetails.org_id,
    "session" : userDetails.session
}
UpdateMapping(requestBody).then(res=>{
  if(res.status==200 || res.code ==200){
    SetTitle("Mapping is Updated");
    SetModalSucess(true)
    setInc((prev)=>prev + 1)
  }else{
    SetTitle("Failed to update");
    SetDescription(res?.message);
    SetModalError(true)
  }
})



}
    return (
         <div className="main-content side-content pt-0">

<div className="container-fluid">
{permissionAPICall && permission.View=='True'?
    <div className="inner-body">


    <div className="page-header">
            <Row>
            <Col lg={4}>
                        </Col>
                        <Col lg={2}></Col>
                
                <Col lg={6} className="header-btn">
                <input type="text" className='form-control' placeholder="Search Page" value={search} onChange={(e)=>SetSearch(e.target.value) } style={{width:200,display:"inline-block"}} />
               
                    {permission && permission.Create=='True'?<button className='btn btn-large btn-header btn-transparent btn-icon' onClick={()=>SetModalAdd(true)}><PlusIcon/> Add Mapping</button>:<button className='btn btn-large btn-header btn-transparent btn-icon disabled hover-message disabled' disabled><PlusIcon/> Add Mapping<span className='messageTag'>Access Restricted</span></button>}
                    
                </Col>
                </Row>
    </div>

        

    </div>:permissionAPICall && permission.View=='False'?<Col lg={12}><div className='errorPermission'>Access Restricted to this page, Please contact administrator</div></Col>:""}   

        <div className='roleList'>

            <div className='br-seperator-row'>                
        <Row className='headerGroup' >
            <Col lg={3} className="align-middle">
                {/* <span className='align-with-title' style={{width:"50px"}}><Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} /></span> */}
                <span className="filter-parent" >Page Name</span>
                
            </Col>
            <Col lg={3} className="align-middle" style={{textAlign:"left"}}>
            <span className="filter-parent" >Version</span>
            </Col>
            <Col lg={6} className="align-middle role-management-act">Action</Col>
        </Row> 
        {props.loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>:
        
        ShowCardListPage(mappedPages)}
            

        </div>
        </div>


</div>

    <ReactModal 
        isOpen={modalAdd}
        style={customStyles}
    >
      
      			<div className='headerAreaTeam headerAreaTeam2'>
				{editModal?<h4>Edit Page To Organization</h4>:<h4>Add Page To Org</h4>}

				 <button className='btn btn-close' onClick={()=>{SetModalAdd(false);SetEditModal(false);SetPageID('');SetSubPageVersion('');SetSubPageID('');SetPageVersion('');}}>x</button></div>
			<div className='bodyAreaTeam'>
      <Row>
        <Col lg={12}>
        <div className='form-field'>
          <label>Select Page</label>
        <Select
          onChange={(e)=>{SetPageID(e);}}
          value={page_id}
        >
          {pageList.map((item)=>{
            if(editModal){
              return <Option key={item.page_id} value={item.page_id}>{item.page_name}</Option>
            }else if(!createdPage.find(el => el &&  el.page_name &&  el.page_name == item.page_name)){
                return <Option key={item.page_id} value={item.page_id}>{item.page_name}</Option>
            }else{

            }
            
          })}
        </Select>
        {pageIDError!=""?<div className='errorSmall'>Please Select Page</div>:""}

        </div>
        
        {page_id!="" && pageVersionList.length>0?
          <div className='form-field'>
             <label>Select Version</label>            
             <div className='pageVersionList'>
                {pageVersionList.map(item=>{
                  return <div className={pageVersion == item.page_id?'pageVersionCheck active':'pageVersionCheck'}><div className='aboveLabel' onClick={()=>SetPageVersion(item.page_id)}>{item.version}</div><img src={item.url}/></div>
                })}
                </div>
                {pageVersionError!=""?<div className='errorSmall'>Please Select Version</div>:""}
         </div>:""}
        </Col>
      </Row>
</div>

      
      			<div className='footerAreaTeam'>
				{editModal?<button type="submit"  className='btn btn-submit' style={{width:"100%"}} onClick={editMappingHandler}  >Update</button>:<button type="submit"  className='btn btn-submit' style={{width:"100%"}} onClick={CreatePageHandler}  > Add Page</button>}
			</div>
    </ReactModal>

    <ReactModal 
        isOpen={modalSub}
        style={customStyles}
    >
      
      <div className='headerAreaTeam headerAreaTeam2'>
{editModal?<h4>Edit Sub Page To Org</h4>:<h4>Add Sub Pages To Org</h4>}				

				 <button className='btn btn-close' onClick={()=>{SetModalSub(false);SetEditModal(false);SetPageID('');SetSubPageVersion('');SetSubPageID('');SetPageVersion('');}}>x</button></div>
			<div className='bodyAreaTeam'>
      <Row>
        <Col lg={12}>
        <div className='form-field'>
          <label>Select Page</label>
        <input type="text" readOnly className='form-control' value={pageName} />
        </div>
        </Col>
               <Col lg={12}>

        {subPageList.length > 0?          <div className='form-field'>
          <label>Select Sub Page</label>
        <Select
          onChange={(e)=>SetSubPageID(e)}
          value={sub_page_id}
        >
          {subPageList.map((item)=>{
            return <Option key={item.sub_page_name} value={item.sub_page_name}>{item.sub_page_name}</Option>
          })}
        </Select>
            {subPageIDError!=""?<div className='errorSmall'>Please Select Sub Page</div>:""}
        </div>:""}
        {page_id!="" && subPageVersionList.length>0?<div className='form-field'>
             <label>Select Version</label>
             <div className='pageVersionList'>
                {subPageVersionList.map(item=>{
                  return <div className={subPageVersion == item.sub_page_id?'pageVersionCheck active':'pageVersionCheck'}><div className='aboveLabel' onClick={()=>SetSubPageVersion(item.sub_page_id)}>{item.version}</div><img src={item.url}/></div>
                })}
                {subPageVersionError!=""?<div className='errorSmall'>Please SelectSub Page Version</div>:""}
                </div>
         </div>:""}
        </Col>
      </Row>
</div>

      
      			<div className='footerAreaTeam'>
				{editModal?<button type="submit"  className='btn btn-submit' style={{width:"100%"}} onClick={editMappingHandler}  >Edit SubPage</button>:<button type="submit"  className='btn btn-submit' style={{width:"100%"}} onClick={CreatePageHandler}  > Add Mapping</button>}
			</div>
    </ReactModal>




              <WarningModal modalWarning={modalDelete} handleDeleteConfirm={handleDeleteConfirm} handleCloseModalWarning={handleCloseModalWarning} title={title}/>
            <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
                <ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />
           
           
           

</div>
              )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(ClientAppManagement))


