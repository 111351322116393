import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import ReactModal from 'react-modal';
import '../../styles/group.scss';
import { AutoComplete } from 'antd';
import { Select } from 'antd';
import '../../styles/addEmployee.scss';
import {Radio, Steps, Button, message ,DatePicker,Switch } from 'antd';
import { PropertyKeys } from 'ag-grid-community';
import Scrollbars from 'react-custom-scrollbars';
import { getLocationList } from '../../../sitemanagement/actionMethods/actionMethods';
import { CreateAsset} from '../../actionMethods/actionMethods';
import SucessModal from '../../../components/SucessModal';
import ErrorModal from '../../../components/ErrorModal';
import {ReactComponent as UPIcon} from '../../../assets/images/chevron-up.svg'
import {ReactComponent as HelpIcon} from  '../../../assets/images/circle-info.svg'
import LoaderSpinner from  '../../../assets/images/loader.svg'

import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { CreateShift,getSubDepartmentList } from '../../../usermanagement/actionMethods/actionMethods'
import moment from 'moment';
import {getProductivityList} from '../../../productivemanagement/actionMethods/actionMethods'


const { Option } = Select;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        maxHeight: '90%'
    },
	
};



function AddAsset(props) {
    const [value, setValue] = useState('');
	const { Step } = Steps;
	const [current, setCurrent] = React.useState(0);
	const [historyEnable,SetHistoryEnable]= useState(false);
	const [productive,SetProductive]=useState([]);
	const [nonproductive,SetNonProductive]=useState([]);
	const [lessproductive,SetLessProductive]=useState([]);
	const [name,SetName]=useState([])
	const [nameError,SetNameError]=useState([])
	
	const [loader,SetLoader]=useState(true);

	const [shift,SetShift] =useState([]);
	const [shiftID,SetShiftID] =useState([]);
	const [shiftToggle,SetShiftToggle]=useState(false);
	const [shiftName,SetShiftName]=useState('')
	const [shiftNameError,SetShiftNameError]=useState('')
	const [shiftStartTime,SetStartTime]=useState('')
	const [shiftStartTimeError,SetStartTimeError]=useState('')
	const [shiftEndtTime,SetEndTime]=useState('')
	const [shiftEndtTimeError,SetEndTimeError]=useState('')


	const [nonSelectProductive,SetNonSelectProductive]= useState([])
	const [fileError,SetFileError] =useState('');

	let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
	let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
	let org_id = userDetails.org_id?userDetails.org_id:""
	 
	
	const[locationList,SetlocationList] =useState([])
	const [previewProfile,SetPreviewProfile] =useState('');
    const [file, setFile] = React.useState("");
	const [subDepartment,SetSubDepartment]=useState([]);
	const [subDepartmentID,SetSubDepartmentID]=useState(props.subGroupID?props.subGroupID:'');
	const [department_id,SetDepartmentID] =useState('');


	const [modalSucess,SetModalSucess] = useState(false);
    const [modalError,SetModalError] = useState(false); 
    const [title,SetTitle] =useState('');
    const [descriptionMessage,SetDescriptionMessage] =useState('');
    const [descriptionError,SetDescriptionError] =useState('');
	const reg = /^\S.*$/;
	
	const [productiveList,SetProductiveList]=useState([])
	const [productiveID,SetProductiveID]=useState('');
	const [shiftMessage,SetShiftMessage] = useState('');
	const [modalSucessShift,SetModalSucessShift] = useState(false);

	

	
	const steps = [
		{
		  title: 'Asset Info',
		  step: '01',
		  content: 'First-content',
		},
		{
			title: 'Productivity',
			step: '02',
			content: 'First-content',
		},
	  ];
  
  
	useEffect(()=>{
		let requestBody={}
		requestBody.session = session
		requestBody.org_id = org_id
		requestBody.tag_type= 'personal'

		getLocationList(requestBody).then(res=>{
			if(res.status==200){
				SetlocationList(res.data)
				SetLoader(false)
			}
			
		})
		getProductivityList(session,org_id).then(res=>{
			if(res.status==200){
				
				SetProductiveList(res.data);
			}
		})
	},[])

	function onChangeProductivitCat(data){
		
		SetProductiveID(data);
		let preselect = productiveList.find(item=>item.id == data);
		if(preselect){

			let prod = JSON.parse(preselect.productivity);


			let product = props.locationType.find(item=>item.loc_type=='productive')
			let nonprod = props.locationType.find(item=>item.loc_type=='non_productive')
			let lessprod = props.locationType.find(item=>item.loc_type=='less_productive')


            SetProductive(prod[product.id])
            SetLessProductive(prod[lessprod.id])
            SetNonProductive(prod[nonprod.id])
		}
	}
	useEffect(()=>{
		SetShift(props.shift);
	  },[props.shift])
	

	const prev = () => {
		setCurrent(current - 1);
	};

	const next = () => {
		
		
		if(name==''){
			SetNameError('Name is Required')
		}else if(name.length > 255){
			SetNameError('Length of Name should be less than 255 character')
		}
		else if((shift.length == 0 || shiftToggle) && shiftName=='' ){
			SetShiftNameError('shift name is required')
		}
		else if((shift.length == 0 || shiftToggle) && shiftName.length> 32 ){
			SetNameError('');
			SetShiftNameError('max length 32 character')

		}else if((shift.length == 0 || shiftToggle) && shiftStartTime=='' ){
			SetShiftNameError('')
			SetStartTimeError("Shift Start time require")
		}else if((shift.length == 0 || shiftToggle) && shiftStartTime=='' ){
		
			SetStartTimeError("")
			SetEndTimeError("Shift End time require")
		}
		else if(shiftToggle || shiftID=='other'){
			SetShiftNameError('')
			SetStartTimeError('')
			SetEndTimeError("")
			SetShiftMessage('Please Create Shift First Before Proceed');
		}
		else{
			SetShiftMessage('');
			SetShiftNameError('')
			SetStartTimeError('')
			SetEndTimeError("")
			
			setCurrent(current + 1);
		}
		
	};

	  function ChangeRadio(e,val){
		let val2 = parseInt(e.target.value);
		if(e.target.checked){
			if(val=="productive"){
				let arr=[...productive];
				arr.push(val2)
				SetProductive(arr)
				
				let arr2 = [...lessproductive]
				if(arr2.indexOf(val2) > -1){
					arr2.splice(arr2.indexOf(val2), 1);
					SetLessProductive(arr2);
				}
			
				let arr3 = [...nonproductive]
				if(arr3.indexOf(val2) > -1){
					arr3.splice(arr3.indexOf(val2), 1);
					SetNonProductive(arr3);	
				}

				let arr4= [...nonSelectProductive];
				if(arr4.indexOf(val2) > -1){
					arr4.splice(arr4.indexOf(val2), 1)
					SetNonSelectProductive(arr4)	
				}

			}
			else if(val=='lessproductive'){
				let arr=[...lessproductive];
				arr.push(val2)
				
				SetLessProductive(arr)

				let arr2 = [...productive]
				if(arr2.indexOf(val2) > -1){
					arr2.splice(arr2.indexOf(val2), 1);
					SetProductive(arr2);
				}	

				let arr3 = [...nonproductive]
				if(arr3.indexOf(val2) > -1){
					arr3.splice(arr3.indexOf(val2), 1);
					SetNonProductive(arr3);
				}

				let arr4= [...nonSelectProductive];
				if(arr4.indexOf(val2) > -1){
					arr4.splice(arr4.indexOf(val2), 1)
					SetNonSelectProductive(arr4)
				}	
				
			}else{
				let arr=[...nonproductive];
				arr.push(val2)
			
				SetNonProductive(arr)
				let arr2 = [...lessproductive]
				if(arr2.indexOf(val2) > -1){
					arr2.splice(arr2.indexOf(val2), 1);
					SetLessProductive(arr2);
				}				
				let arr3 = [...productive]
				if(arr3.indexOf(val2) > -1){
					arr3.splice(arr3.indexOf(val2), 1);
					SetProductive(arr3);
				}

				let arr4= [...nonSelectProductive];
				if(arr4.indexOf(val2) > -1){
					arr4.splice(arr4.indexOf(val2), 1)
					SetNonSelectProductive(arr4)
				}
			}
	  }
	}
	useEffect(()=>{
		let requestBody ={}
		requestBody.session=session
		requestBody.org_id=org_id
		requestBody.dept_id = props.group_id	
		
		SetSubDepartmentID('')
		SetDepartmentID(props.group_id);
				getSubDepartmentList(requestBody).then(res=>{
					if(res.status==200 || res.code==200){
						let item = res.data.filter(item=>item.id== subDepartmentID);
						
						if(item){
							SetSubDepartment(res.data);
						}else{
							SetSubDepartmentID('')
							SetSubDepartment(res.data);
						}
		
					}
		})

	},[props.group_id])
	useEffect(()=>{

		SetSubDepartmentID(props.subGroupID)

	},[props.subGroupID])

	const onChangeShift = (data) => {
        SetShiftID(data);
			if(data=='other'){

				SetShiftToggle(true)
			}else{
				SetShiftToggle(false)
			}
      };
	 
	  function handleCloseModalSucessShift(){
		SetModalSucessShift(false);
	 }


function AddUserHandler(){

	let arr = [];
	let arr2=[];


	
	for(let i =0 ;i<locationList.length;i++){
		let locid=locationList[i].id;
		if(productive.indexOf(locationList[i].id)> -1 || nonproductive.indexOf(locationList[i].id)> -1 || lessproductive.indexOf(locationList[i].id)> -1 ){
					
		}else{				
			arr.push(locationList[i].id);
		}
	}
	SetNonSelectProductive(arr);

	if(name==''){
		SetNameError('Required name of Asset')	
	}else if(name.length > 255){
		SetNameError('Length of Name should be less than 255 character')	
	}
	
	if(arr.length>0){

	}else{
		let requestBody={}
		requestBody.employee_name =name;
		requestBody.org_id= org_id
		requestBody.type ='asset'
		requestBody.department_id =department_id
		requestBody.sub_dept_id= subDepartmentID
		requestBody.session= session
		requestBody.shift= shiftID


		///requestBody.productivity={"1":productive,"2":lessproductive,'3':nonproductive};

		let prod = props.locationType.find(item=>item.loc_type=='productive')
		let nonprod = props.locationType.find(item=>item.loc_type=='non_productive')
		let lessprod = props.locationType.find(item=>item.loc_type=='less_productive')
		requestBody.productivity={[prod.id]:productive,[lessprod.id]:lessproductive,[nonprod.id]:nonproductive};


		CreateAsset(requestBody,file).then(res=>{
			if(res.status==200|| res.code==200){
				SetTitle(<h4>Asset is added</h4>)
				SetDescriptionMessage('')
				SetModalSucess(true);
			}else{
				SetTitle(<h4>{res.message}</h4>)
				SetDescriptionMessage('Fail to add')
				SetModalError(true);
			}
		})

		// SetTitle(<h4>Asset is added</h4>)
		// SetDescriptionMessage('')
		// SetModalSucess(true);
	}


}

function handleCloseModalSucess(){
	props.Reload();
	props.handleCloseModal()
	SetModalSucess(false)
  }
  
function handleCloseModalError(){
	SetModalError(false)
}

function onChangeStep(step){


		if(step==0){
		
			if(name==''){
				SetNameError('Shift Name is required')
			}else if(name.length > 255){
				SetNameError('Shift Name is required')
			}else if((shift.length == 0 || shiftToggle) && shiftName.length> 32 ){
				
				SetShiftNameError('max length 32 character')
			}else if((shift.length == 0 || shiftToggle) && shiftStartTime=='' ){
				
				SetShiftNameError('')
				SetStartTimeError("Shift Start time require")
			}else if((shift.length == 0 || shiftToggle) && shiftStartTime=='' ){
				
				SetStartTimeError("")
				SetEndTimeError("Shift End time require")
			}
			else if(shiftToggle || shiftID=='other'){
				SetShiftNameError('')
				SetStartTimeError('')
				SetEndTimeError("")
				SetShiftMessage('Please Create Shift First Before Proceed');
			}
			else{
				SetShiftMessage('');
				SetShiftNameError('')
				SetStartTimeError('')
				SetEndTimeError("")
				setCurrent(step);
			}
		

		}else{
			setCurrent(step);
		}
		
  }

function loadFile(event){

    let arry = event.target.files[0].name.split('.');
    let lastElement = arry[arry.length - 1];
  
    if(lastElement=='jpeg' || lastElement=='png' || lastElement=='webp' || lastElement=='jpg' ){
        SetPreviewProfile(URL.createObjectURL(event.target.files[0]));
        setFile(event.target.files[0]);    
    }else{
        SetFileError('Only Support JPEG & PNG')
        SetPreviewProfile('');
        setFile('');    
        //setFile(event.target.files[0]);    
        setTimeout(function(){
        SetFileError('')
        },3000)
    }
}


const onChangeSubDepartment = (data) =>{
	SetSubDepartmentID(data);
}

function CreateShiftHandler(){

	let requestBody2= {};
	requestBody2.session=session
	requestBody2.org_id=org_id
	requestBody2.shift_name= shiftName
	requestBody2.start_time =moment(shiftStartTime).format('HH:mm:ss') 
	requestBody2.end_time = moment(shiftEndtTime).format('HH:mm:ss')
	if(shiftStartTime=='' || shiftEndtTime==''|| shiftName=='' ){
		if(shiftName==''){SetShiftNameError('Shift Name is required')}else{SetShiftNameError('')}
		if(shiftStartTime==''){SetStartTimeError('Shift Start Time is required')}else{SetStartTimeError('')}
		if(shiftEndtTime==''){SetEndTimeError('Shift End Time is required')}else{SetEndTimeError('')}


	}else{

	
	CreateShift(requestBody2).then(res=>{
		if(res.status==200){
			props.refreshShift();
			SetShiftID(res.data.shift_id);
			SetShiftName('');

			SetStartTime('');
			SetEndTime('')
			SetShiftToggle(false);
			SetTitle(<h4>Shift is created</h4>)
			SetDescriptionMessage(res.message)
			SetModalSucessShift(true)
		}else{
			SetTitle(<h4>{res.message}</h4>)
			SetDescriptionMessage('Fail to create shift')
			SetModalError(true)

			setCurrent(0);
			SetTitle(res.message);
			SetDescriptionMessage('Shift is not created')
			SetModalError(true)
		}
	})
	}
  }


    return(
        <div>
            <div className='modalHeader'>
                    <h4>Add Asset</h4>
                    <div className='closeMark' onClick={()=>props.handleCloseModal()}>x</div>
                </div>
				{loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>: 
				<div className='modalBody'>
					<Steps current={current} onChange={onChangeStep}>
					{steps.map(item => (
					<Step key={item.title} title={item.title} />
					))}

					<select
						placeholder="Select Productive Type"
						optionFilterProp="children"
						onChange={(e)=>{onChangeProductivitCat(e.target.value) }}
						value={productiveID}
						className={current==1?'inlast-step form-control activeShow':'inlast-step form-control hide'}
						
					>
						<option value={''}>{'Select'}  </option>
						{productiveList.map(item=>{
							return <option value={item.id} key={"productibity"+item.id}>{item.category_name}  </option>
						})}
					</select>
				</Steps>
				{current==0?<div className="steps-content step-user-info">
					<Row>
						<Col lg={12}>
							<div className='field-row'>
									<label>Name*<div className='posRel'><HelpIcon /><span className='helpSmall'>Max Length 255 character</span></div></label>
									<input type="text" className={nameError!=''?'form-control has-error':'form-control'} value={name} onChange={(e)=>{if(reg.test(e.target.value)){SetName(e.target.value);}else if(e.target.value==''){SetName(e.target.value)} }  } />
									{nameError!=''?<div className='errorMessage'>{nameError}</div>:""}
							</div>	
						</Col>
						<Col lg={6}>
							<div className='field-row'>
                      		  <label>Department*<div className='posRel'><HelpIcon /><span className='helpSmall'>Select Department want to assign for this employee</span></div></label>
								<input type="text" className='form-control' value={props.group_name} disabled="disabled" />
								</div>
						</Col>
							{subDepartment.length>0 ?<Col lg={6}>
							<div className='field-row'>
                      		  <label>Sub Department<div className='posRel'><HelpIcon /><span className='helpSmall'>Select Sub Department if want to assign any</span></div></label>
                                <Select
									placeholder="Select Sub Department"
									optionFilterProp="children"
									onChange={onChangeSubDepartment}
									
									filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									value={subDepartmentID}
									className='textCap'
								>
									{subDepartment.map((item)=>{
										return <Option value={item.id} key={"SubDepartment"+item.id}>{item.sub_dept_name.toLowerCase()}</Option>
									})
									}
									
								</Select>
								</div>
							</Col>:''}
							
							<Col lg={6}>
							<div className='field-row'>
							<label>Shift<div className='posRel'><HelpIcon /><span className='helpSmall'>Shift is required</span></div></label>
                                <Select
									placeholder="Select Time"
									optionFilterProp="children"
									onChange={(val)=>onChangeShift(val)}
								
									filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									value={shiftID}
								>
									{shift.map(item=>{
										return <Option value={item.id} key={item.shift_name}>{item.shift_name} {  item.start_time.split(':')[0]+':'+item.start_time.split(':')[1] } - {item.end_time.split(':')[0]+':'+item.end_time.split(':')[1]} </Option>
									})}
									
									<Option value={'other'} key={'other'}>Create</Option>
								</Select>

								</div>
							</Col>	
							{shiftToggle?<Col lg={6} className="shiftInResponsive">

							<Row>
							<Col lg={3}>
								<label>Shift Name</label>
								<input type="text" value={shiftName} onChange={(e)=>{if(reg.test(e.target.value)){SetShiftName(e.target.value);}else if(e.target.value==''){SetShiftName(e.target.value)} }  } className='form-control'  />	
								{shiftNameError!=''?<div className='errorMessage'style={{lineHeight:1}}>{shiftNameError}</div>:""}
							</Col>
							<Col lg={3} className="timePickMy">
								<label>Start Time</label>

								<LocalizationProvider dateAdapter={AdapterMoment}>
										<TimePicker

											value={shiftStartTime}
											onChange={(newValue) => {
												SetStartTime(newValue);
											}}
											ampm={false}
											renderInput={(params) => <TextField {...params} />}
										/>
										</LocalizationProvider>
										{shiftStartTimeError!=''?<div className='errorMessage' style={{lineHeight:1}}>{shiftStartTimeError}</div>:""}
							</Col>
							<Col lg={3} className="timePickMy">
								<label>End Time</label>

								<LocalizationProvider dateAdapter={AdapterMoment}>
										<TimePicker
											value={shiftEndtTime}
											onChange={(newValue) => {
												SetEndTime(newValue);
											}}
											ampm={false}
											renderInput={(params) => <TextField {...params} />}
										/>
										</LocalizationProvider>
										{shiftEndtTimeError!=''?<div className='errorMessage' style={{lineHeight:1}}>{shiftEndtTimeError}</div>:""}
							</Col>
							<Col lg={3} className="shiftcreatebutton" >
								<div className='btn btn-submit' style={{marginTop:"20px"}} onClick={CreateShiftHandler}>Create Shift</div>
							</Col>
						</Row>
						{shiftMessage!=''?<div className='errorMessage' style={{lineHeight:1}}>{shiftMessage}</div>:""}
					</Col>:""}

						<Col lg={6}>
							<div className='field-row'>
								<label >Add Profile</label>
                                <input type="file"  id="file" placeholder="browse" accept="image/png, image/gif, image/jpeg" onChange={(e)=>loadFile (e) } />
                                <label htmlFor="file" className='fileUploadStyle'><span>Browse file</span><small>{file?file.name:""}</small> </label>
								{fileError!=''?<div className='errorMessage'>{fileError}</div>:''}
                            </div>
                        </Col>
						<Col lg={6}>
						 {previewProfile?<div className='previewArea'>
                                <div className='icon-row profile-icon'  >
                                     
                                      <div className='headerIconProfile'><img src={previewProfile} className="avatar-size"/> </div>
                                        <div className='headerIconName'>
                                                 <span className='author-name'>{ name!=''?<>{name}</>:'Full Name'} </span>
                                                <span className='author-name author-name-role'><><span>Role</span> Some Role </></span>
                                        </div>
                                        <span className='icon-toggle'>
                                            <UPIcon/>
                                        </span>                                        
                                </div>
                            </div>:""}
						</Col>


					</Row>							
				</div>:""}

				{current==1?<div className="steps-content step-user-info">
				
					<Row className='header-table-2 header-table-3'>
						<Col lg={3} className='label-disp label-check' style={{textAlign:"left"}}>Location Name</Col>
						<Col lg={3} className='label-check'>Productive</Col>
						<Col lg={3} className='label-check'>Less Productive</Col>
						<Col lg={3} className='label-check'>Non Productive</Col>
                   </Row>
					<Scrollbars style={{maxHeight:300,height:300}}>
						<div className={'accord-role active'} >
							{locationList.map((item)=>{
									return (
										<Row key={"departmentID"+item.id} className= {nonSelectProductive.indexOf(item.id)> -1?'cotnent-table error-highlight':'cotnent-table'}>
										<Col lg={3} className='label-disp textCap'>{item.location_name.toLowerCase()}</Col>
										<Col lg={3} className='label-check'><Radio value={item.id} checked={productive.indexOf(item.id)!=-1?true:false} onChange={(e)=>ChangeRadio(e,'productive')}></Radio></Col>
										<Col lg={3} className='label-check'><Radio value={item.id} checked={lessproductive.indexOf(item.id)!=-1?true:false}  onChange={(e)=>ChangeRadio(e,'lessproductive')}  ></Radio></Col>
										<Col lg={3} className='label-check'><Radio value={item.id} checked={nonproductive.indexOf(item.id)!=-1?true:false} onChange={(e)=>ChangeRadio(e,'nonproductive')}></Radio></Col>					  
									</Row>   
									)
							})
							}						
						</div>
					</Scrollbars>						
				</div>:""}
				<div className="steps-action">
					
							
				{current > 0  &&  (
					<Button style={{ margin: '0 8px' }} onClick={() => prev()} className={'btn btn-primary'}>
						Back
					</Button>
					)}

					{current < steps.length - 1 && (
					<Button type="primary" onClick={() => next()} className={'btn btn-primary'}>
						Next
					</Button>
					)}
					{current === steps.length - 1 && (
					<Button type="primary" onClick={AddUserHandler} className={'btn btn-primary'}>
						Add Asset
					</Button>
					)}
				</div>
    
                </div>}
				<SucessModal modalSucess={modalSucessShift} handleCloseModalSucess={handleCloseModalSucessShift} title={title} description={descriptionMessage} key="addemployeeShiftAsset" />
				<SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={descriptionMessage}  key={'addAsset'}/>
				<ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={descriptionMessage}  key={'addAssetError'} />

        </div>
    )
}
export default AddAsset;