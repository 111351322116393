import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Modal from 'react-modal'
import Aside from './aside';
import traceplusLogo from '../assets/traceplusImages/trace_logo.png';

import { logoutUser } from '../login/actionMethods/actionMethods';

import SpinnerLoader from '../assets/images/loader.svg'

import moment from 'moment';

import { useHistory } from 'react-router-dom';
import Avatar from '../assets/images/avatar.png'
import { Row,Col } from 'react-bootstrap';
import {ReactComponent as Bell} from '../assets/images/bell.svg'
import {ReactComponent as DownIcon} from '../assets/images/chevron-down.svg'
import {ReactComponent as UPIcon} from '../assets/images/chevron-up.svg'
import {ReactComponent as Bar} from '../assets/images/bars.svg'
import {ReactComponent as Close} from '../assets/images/xmark.svg'
import { CommonHeading } from '../common/commonHeading';

import {ReactComponent as Camera} from '../assets/images/camera.svg'

const customStyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.75)'
    },
    content: {
        position: 'absolute',
        width: '25rem',
        height: '13rem',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '1px solid #ccc',
        background: '#fff',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '4px',
        outline: 'none',
        padding: '0'
    }
}

const customStyle2 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '90%'
    },
};

function TopHeader(props) {
let history = useHistory();
   
   const [showLoader, updateShowLoader] = useState(false)
   const [showLogout, setShowLogout] = useState(false)
   const [timer,SetTimer]=useState(''); 
   const [activeStateProfile,SetActiveStateProfile] = useState(props.activeStateProfile);
    const [dateAuto,SetDateAuto] = useState('')
    const [activePage,SetActivePage]=useState(window.location.pathname.split('/').slice(-1)[0])

    const [showLogoutTimer, setShowLogoutTimer] = useState(false)
    const [logoutTimer30, setlogoutTimer30] = useState(10);
    const [resetTimer,SetRestTimer]=useState(false);
    const [showChangeProfile,SetShowChangeProfile] = useState(false);
    const [previewProfile,SetPreviewProfile] =useState('');
    const [file, setFile] = React.useState("");
    const [fileError,SetFileError] =useState('');
    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let userLogin = localStorage.getItem('isLoggedInGatewayTraceAdmin')

    useEffect(()=>{
        let page =window.location.pathname.split('/').slice(-1)[0]
        SetActivePage(window.location.pathname.split('/').slice(-1)[0]);
         if(window.location.pathname.includes('role-detail')){
            SetActivePage('role-detail')
          
         }else if(window.location.pathname.includes('edit-role')){
            SetActivePage('edit-role')
         }else if(window.location.pathname.includes('add-role')){
            SetActivePage('add-role')
         }
         else if(window.location.pathname.includes('team-management')){
            SetActivePage('team')
         }
         else{
            SetActivePage(page)
         }
    },[props])
    
    useEffect(()=>{
        SetActiveStateProfile(props.activeStateProfile)
     },[props.ToggleProfile])


    useEffect(()=>{
        if (localStorage.getItem('userLoginDetailsTraceAdmin')=='loggedIn') {
          localStorage.removeItem('isLoggedInGatewayTraceAdmin');
          localStorage.removeItem('userLoginDetailsTraceAdmin');
          history.push(`/login`);
        }
      },[])
    
const [menuActive,SetMenuActive]=useState(false);

setInterval(function(){
  
    SetDateAuto(moment().format("DD MMM  hh:mm:ss"))
  },1000)
  
//let userDetails = JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin'))
let timerIdle = null;
let resetT;

if(userLogin && userDetails=='loggedIn'){
    localStorage.removeItem('isLoggedInGatewayTraceAdmin');
    localStorage.removeItem('userLoginDetailsTraceAdmin')
}

useEffect(()=>{
    if(logoutTimer30==0 && resetTimer===false){
        handleLogout();
    }else if(logoutTimer30==0 && resetTimer===true){
        SetRestTimer(false);
    }else{

    }
},[logoutTimer30])

useEffect(()=>{

    let interval;
    let dispTimer=30;
    if(showLogoutTimer){
        for(let i=0;i<=dispTimer;i++){
            interval = setTimeout(function(){
                setlogoutTimer30(dispTimer - i);    
            }, 1000*i);
    }
    }
    if(showLogoutTimer===false){
        startIdleTime()
        setlogoutTimer30(10);
    }

  
},[showLogoutTimer])

    function startIdleTime(){
        if(userLogin){
            timerIdle = setInterval(function(){
                clearInterval(timerIdle);
                setShowLogoutTimer(true);
            },270000000);
        }
    }

    function closeIdleTime(){
        clearInterval(timerIdle);
    }

    useEffect(()=>{
         clearTimeout(resetT)
    },[resetTimer])
    
function handleLogout() {
    updateShowLoader(true)
    localStorage.removeItem('isLoggedInGatewayTraceAdmin')
    localStorage.removeItem('userLoginDetailsTraceAdmin')
    localStorage.removeItem('tokenAuthAdmin')
    props.history.push(`/login`)
}

const handleLogoutModal = () => {
    setShowLogout(false)
    setShowLogoutTimer(false);
    SetRestTimer(true);
        closeIdleTime()
}

function MenuChange(){
    SetMenuActive(!menuActive);
}

function handleLogout() {
	updateShowLoader(true)
	
    let requestBody={};
    requestBody.session = userDetails.session
    requestBody.token = localStorage.getItem('tokenAuthAdmin')?localStorage.getItem('tokenAuthAdmin'):'tituw3958589'


    logoutUser(requestBody).then(res=>{

        localStorage.removeItem('isLoggedInGatewayTraceAdmin')
        localStorage.removeItem('userLoginDetailsTraceAdmin')
        localStorage.removeItem('userLoginDetails')
        localStorage.removeItem('tokenAuthAdmin')
        props.history.push(`/login`)

    }).catch();     
  
    // localStorage.removeItem('userLoginDetails')
    // localStorage.removeItem('selectedDate')
    // localStorage.removeItem('tokenAuthTrace')
}

function ToggledMenu(){
    props.ToggledMenu()
}
function loadFile(event){

    let arry = event.target.files[0].name.split('.');
    let lastElement = arry[arry.length - 1];
  
    if(lastElement=='jpeg' || lastElement=='png' || lastElement=='webp' || lastElement=='jpg' ){
        SetPreviewProfile(URL.createObjectURL(event.target.files[0]));
        setFile(event.target.files[0]);    
    }else{
        SetFileError('Only Support JPEG & PNG')
        SetPreviewProfile('');
        setFile('');    
        //setFile(event.target.files[0]);    
        setTimeout(function(){
        SetFileError('')
        },3000)
    }

}




return (
    <React.Fragment>
        <div className="main-header side-header sticky">

        <div className="headerMainDiv">			
            <div className="container-fluid">
                
                <div className="main-header-center">
                    <div className="responsive-logo">
                        <a href="/dashboard">
                            <img
                             //src={userDetails.org_logo?userDetails.org_logo:traceplusLogo} 
                             src={userDetails && userDetails.org_logo ? userDetails.org_logo : traceplusLogo}
                             alt="TracePlus Logo"
                             onError={(e) => {
                                 e.target.src = traceplusLogo
                             }}
                             className="mobile-logo" />
                        </a>
                    </div>
                </div>
                <div className="main-header-profile">
                    <Row>
                        <Col lg={7}>
                            {activePage=='dashboard'?<CommonHeading title="Welcome To Dashboard" MainTitle="Dashboard" redirect={'/dashboard'} />:''}
                            {activePage=='role-management'?<CommonHeading title="Role Management" MainTitle="Role Management" redirect={'/dashboard'}/>:''}
                            {activePage=='user-management'?<CommonHeading title="User Management" MainTitle="User Management" redirect={'/dashboard'} />:''}
                            {activePage=='site-management'?<CommonHeading title="Site Management" MainTitle="Site Management" redirect={'/dashboard'} />:''}
                            {activePage=='device-management'?<CommonHeading title="Device Management" MainTitle="Device Management" redirect={'/device-management'} />:''}
                            {activePage=='device-monitor'?<CommonHeading title="Device Monitor" MainTitle="Device Monitor" redirect={'/device-monitor'} />:''}
                            {activePage=='add-role'?<CommonHeading title="Add Role" MainTitle="Add Role" redirect={'/role-management'}/>:''}
                            {activePage=='edit-role'?<CommonHeading title="Edit Role" MainTitle="Edit Role" redirect={'/role-management'} />:''}
                            {activePage=='user-setting'?<CommonHeading title="User Settings" MainTitle="User Settings" redirect={'/organization-management'} />:''}
                            {activePage=='role-detail'?<CommonHeading title="View Role" MainTitle="View Role" redirect={'/role-management'} />:'' }
                            {activePage=='asset-management'?<CommonHeading title="Asset Management" MainTitle="Asset Management" redirect={'/asset-management'} />:'' }
                            {activePage=='geo-map-management'?<CommonHeading title="Geo Map Management" MainTitle="Geo Map Management" redirect={'/geo-map-management'} />:'' }
                            {activePage=='productive-management'?<CommonHeading title="Productive Management" MainTitle="Productive Management" redirect={'/productive-management'} />:'' }
                            {activePage=='team'?<CommonHeading title="Team Management" MainTitle="Team Management" redirect={'/team-management'} />:'' }
                            {activePage=='organization-management'?<CommonHeading title="organzation Management" MainTitle="Organization Management" redirect={'/organization-management'} />:'' }
                            {activePage=='supervisor-management'?<CommonHeading title="Supervisor Management" MainTitle="Supervisor Management" redirect={'/supervisor-management'} />:'' }
                            {activePage=='cycle-count-management'?<CommonHeading title="Cycle Count Management" MainTitle="Cycle Count Management" redirect={'/supervisor-management'} />:'' }
                            {activePage=='webapp-page'?<CommonHeading title="Web App Page Management" MainTitle="Web App Page Management" redirect={'/webapp-page'} />:'' }
                        </Col>
                        <Col lg={5} className="float-end">
                        {dateAuto}
                            <div className='icon-row profile-icon'  >
                                    <div className='headerIconProfile' onClick={()=>SetShowChangeProfile(true)}><div className='overlayEdit'><Camera/></div><img src={Avatar} className="avatar-size"/> </div>
                                    <div className='headerIconName' onClick={()=>SetActiveStateProfile(!activeStateProfile)}>
                                             <span className='author-name'>{userDetails&& userDetails.empName?userDetails.empName:'Admin'}</span>
                                            <span className='author-name author-name-role'>{userDetails&& userDetails.role_name?<><span>Role</span>{userDetails.role_name}</>:''}</span>
                                    </div>
                                    <span className='icon-toggle' onClick={()=>SetActiveStateProfile(!activeStateProfile)}>
                                        {activeStateProfile?<UPIcon/>:<DownIcon/>}
                                    </span>                                        
                            </div>
                            <div className={activeStateProfile?'subProfileActive subProfile':'subProfile'}>
                                <ul>
                                    <li onClick={()=>{setShowLogout(true);props.ToggleProfile() }}><span>Logout</span></li>
                                </ul>
                            </div>
                                
                        </Col>
                    </Row>
                </div>
                <Modal
            isOpen={showLogout}
            style={customStyle}
        >
            <div className='logoutModal'>
                <div className="logoutTitle">Logout</div>
                <div className='logoutDesc'>Are you sure you want to Logout?</div>

                <div className='actionButtons'>
                    <span className='btnText borderRight' onClick={handleLogoutModal}>No</span>
                    <span className='btnText okBtn' onClick={handleLogout}>Yes, Logout</span>
                </div>
            </div>
        </Modal>

        <Modal
            isOpen={showChangeProfile}
            style={customStyle2}
        >
            <div>
                <div className='modalHeader'>
                        <h4>Update Picture</h4>
                        <div className='closeMark' onClick={()=>SetShowChangeProfile(false)}>x</div>
                    </div>
                    <div className='modalBody'>
                        <div className='field-row'>
                            <input type="file"  id="file" placeholder="browse" accept="image/png, image/jpeg" onChange={(e)=>loadFile (e) } />
                            <label for="file" className='fileUploadStyle'><span>Browse file</span><small>{file?file.name:""}</small> </label>
                            {fileError!=''?<div className='errorMessage'>{fileError}</div>:''}
                        </div>
                        
                        {previewProfile?<div className='previewArea'>
                            <div className='icon-row profile-icon'  >
                                 
                                  <div className='headerIconProfile'><img src={previewProfile} className="avatar-size"/> </div>
                                    <div className='headerIconName' onClick={()=>props.ToggleProfile()}>
                                             <span className='author-name'>{userDetails&& userDetails.empName?userDetails.empName:'Admin'}</span>
                                            <span className='author-name author-name-role'>{userDetails&& userDetails.role_name?<><span>Role</span>{userDetails.role_name}</>:''}</span>
                                    </div>
                                    <span className='icon-toggle' onClick={()=>props.ToggleProfile()}>
                                        {activeStateProfile?<UPIcon/>:<DownIcon/>}
                                    </span>                                        
                            </div>
                        </div>:""}

                        
                        <button className='btn btn-primary btn-100' onClick={()=>SetShowChangeProfile(false)}>Update Picture</button>    
                    </div>
            </div>
        </Modal>

        <Modal
            isOpen={showLogoutTimer}
            style={customStyle}
        >
            <div className='logoutModal'>
                <div className="logoutTitle">Logout</div>
                <div className='logoutDesc'>Do You want to continue, Or You will be logout in <span className='counttimer'>{logoutTimer30}</span></div>

                <div className='actionButtons'>
                    <span className='btnText borderRight' onClick={handleLogoutModal}>No</span>
                    <span className='btnText okBtn' onClick={handleLogout}>Yes, Logout</span>
                </div>
            </div>
        </Modal>
                <div className="main-header-left">
                    <a className={props.menuActive?"main-header-menu-icon close-icon":"main-header-menu-icon" }  href="#" id="mainSidebarToggle"  onClick={()=>props.ToggledMenu()}><span>{props.menuActive?<Close/>:<Bar/>}</span></a>
                </div>
            </div>
            </div>
        </div>
        

        <Modal
            isOpen={showLogout}
            style={customStyle}
        >
            <div className='logoutModal'>
                <div className="logoutTitle">Logout</div>
                <div className='logoutDesc'>Are you sure you want to Logout?</div>

                <div className='actionButtons'>
                    <span className='btnText borderRight' onClick={handleLogoutModal}>No</span>
                    <span className='btnText okBtn' onClick={handleLogout}>Yes, Logout</span>
                </div>
            </div>
        </Modal>

    </React.Fragment>
)
}



export default connect(null, {})(withRouter(TopHeader))

