import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import ReactModal from 'react-modal';
import '../../styles/group.scss';
import { AutoComplete } from 'antd';
import { Select } from 'antd';
import '../../styles/addEmployee.scss';
import {Radio, Steps, Button, message ,DatePicker,Switch } from 'antd';
import { PropertyKeys } from 'ag-grid-community';
import Scrollbars from 'react-custom-scrollbars';
import {UpdateSuborg, getDepartmentList,getSubDepartmentList} from '../../actionMethods/actionMethods'
import { getLocationList } from '../../../sitemanagement/actionMethods/actionMethods';
import { EditUser , getAvailableTag } from '../../actionMethods/actionMethods';
import SucessModal from '../../../components/SucessModal';
import ErrorModal from '../../../components/ErrorModal';
import LoaderSpinner from '../../../assets/images/loader.svg'
import {ReactComponent as UPIcon} from '../../../assets/images/chevron-up.svg'
import {ReactComponent as HelpIcon} from  '../../../assets/images/circle-info.svg'

import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { CreateShift,getShifttList } from '../../actionMethods/actionMethods';
import moment from 'moment';
import { maxWidth } from '@mui/system';
import { getProductivityList } from '../../../productivemanagement/actionMethods/actionMethods';



const { Option } = Select;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        maxHeight: '90%'
    },
};



function EditEmployee(props) {

    const [value, setValue] = useState('');
	const { Step } = Steps;
	const [current, setCurrent] = React.useState(0);
	const [historyEnable,SetHistoryEnable]= useState(false);
	const [productive,SetProductive]=useState([]);
	const [nonproductive,SetNonProductive]=useState([]);
	const [lessproductive,SetLessProductive]=useState([]);
	const [firstname,SetFirstName]=useState([])
	const [firstnameError,SetFirstNameError]=useState([])
	const [lastname,SetLastName]=useState('')
	const [lastnameError,SetLastNameError]=useState('')
	const [email,SetEmail]=useState('')
	const [emailError,SetEmailError]=useState('')
	const [password,SetPassword]=useState('')
	const [passwordError,SetPasswordError]=useState('')
	const [description,SetDescription] =useState('');
	const [address,SetAddress]=useState('')
	const [addressError,SetAddressError]=useState('')
	const [emp_id,SetEMPID] =useState('')
	const [emp_idError,SetEMPIDError] =useState('')
	const [nonSelectProductive,SetNonSelectProductive]= useState([])
	const [formatProductiveAPI,SetFormatProductiveAPI] =useState([]);
	const [subDepartment,SetSubDepartment]=useState([]);
	const [subDepartmentID,SetSubDepartmentID]=useState('');
	const [availableTag,SetAvailTag] =useState(props.availableTag?props.availableTag:[]);
	const [locationList,SetlocationList] =useState([])
    const [dropdownOpt,SetDropDownOpt]=useState([])
	const [shiftToggle,SetShiftToggle]=useState(false);
	const [shiftName,SetShiftName]=useState('')
	const [shiftNameError,SetShiftNameError]=useState('')
	const [shiftStartTime,SetStartTime]=useState('')
	const [shiftStartTimeError,SetStartTimeError]=useState('')
	const [shiftEndtTime,SetEndTime]=useState('')
	const [shiftEndtTimeError,SetEndTimeError]=useState('')
	const [department_list,SetDepartMentList] = useState([])
	const [department_id,SetDepartmentID] =useState('');
	const [department_idError,SetDepartmentIDError] =useState('');
	const [role_id,SetRoleID] =useState('');
	const [role_idError,SetRoleIDError] =useState('');
    const [selectedTag,SetSelectedtag] = useState('')
	const [employeeID,SetEmployeeID] = useState('')	
	const [loader,SetLoader] = useState(true);
	const [suborgid, setSubOrgId] = useState('');
	const [modalSucess,SetModalSucess] = useState(false);
	const [modalSucessShift,SetModalSucessShift] = useState(false);
	const [modalError,SetModalError] = useState(false);
	const [title,SetTitle] =useState('');
	const [descriptionMessage,SetDescriptionMessage] = useState('')
	const [descriptionError,SetDescriptionError] = useState('')

	const [previewProfile,SetPreviewProfile] =useState('');
    const [file, setFile] = React.useState("");
	const [shift,SetShift] =useState([]);
	const [shiftID,SetShiftID] = useState('');
	const [assignedTag ,SetAssignedTag] =useState(true);
    const [fileError,SetFileError] =useState('');
	const [productiveList,SetProductiveList]=useState([])
	const [productiveID,SetProductiveID]=useState('');
	const [shiftMessage,SetShiftMessage] = useState('');
	const[sublist,setsublist]=useState([])
	const reg = /^\S.*$/;

	const timeVal =[
		"1:00 AM","02:00 AM","03:00 AM","04:00 AM","05:00 AM","06:00 AM","07:00 AM","08:00 AM","09:00 AM","10:00 AM","11:00 AM","12:00 AM",
		"1:00 PM","02:00 PM","03:00 PM","04:00 PM","05:00 PM","06:00 PM","07:00 PM","08:00 PM","09:00 PM","10:00 PM","11:00 PM","12:00 PM",	
	]
	let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
	let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
	let org_id = userDetails.org_id?userDetails.org_id:""
	let sub_org_id = userDetails.sub_org_id?userDetails.sub_org_id:""
	
	const steps = [
		{
		  title: 'User Info',
		  step: '01',
		  content: 'First-content',
		},
		{
		  title: 'Working Detail',
		  step: '02',
		  content: 'Second-content',
		}
	  ];

	  useEffect(()=>{
		SetShift(props.shift);
	  },[props.shift])

	  const next = () => {
		if(current==0){
			var validRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
			if(firstname==''){
				SetFirstNameError('First name is required');
			}else if(firstname.length > 255){
				SetFirstNameError('Length of first name should be less than 255 character');
			}
		
			else if(description.length > 255 ){
				SetDescriptionError('Length of designation should be less than 255 character ')
			}else if(role_id==''){
				SetRoleIDError('Role name is required')
			}
			else{
				SetRoleIDError('')
				setCurrent(current + 1);	
			}

			
		}
		else if(current==1){
		if(department_id==''){
			SetDepartmentIDError('Department name is required');
		}else if(emp_id==''){
			SetDepartmentIDError('');
			SetEMPIDError('Unique employee id is required');
		}
		else if(emp_id.length > 255){
			SetEMPIDError('Employee ID length should be less than 255 character');
		}
		else if((shift.length == 0|| shiftToggle)  && shiftName=='' ){
			SetEMPIDError('');
			SetShiftNameError('Shift name is required')
		}
		else if((shift.length == 0 || shiftToggle) && shiftName.length> 32 ){
			SetEMPIDError('');
			SetShiftNameError('max length 32 character')
		}else if((shift.length == 0 || shiftToggle) && shiftStartTime=='' ){
			SetEMPIDError('');
			SetShiftNameError('')
			SetStartTimeError("Shift Start time require")
		}else if((shift.length == 0 || shiftToggle) && shiftStartTime=='' ){
			SetEMPIDError('');
			SetStartTimeError("")
			SetEndTimeError("Shift End time require")
		}
		else if(shiftToggle || shiftID=='other'){
			SetShiftNameError('')
			SetStartTimeError('')
			SetEndTimeError("")
			SetShiftMessage('Please Create Shift First Before Proceed');
		}
		else{
			SetShiftMessage('');
			SetShiftNameError('')
			SetStartTimeError('')
			SetEndTimeError("")
			
			setCurrent(current + 1);
		}	

		}else{
			setCurrent(current + 1);
		}


		
	  };
	
	  const prev = () => {
		setCurrent(current - 1);
	  };
	  
	  function onChangeHistory(value){
		SetHistoryEnable(value)
	  }


	
	useEffect(()=>{
		let requestBody={}
		requestBody.session = session
		requestBody.org_id = org_id
		requestBody.tag_type='personal'
		getDepartmentList(requestBody).then(res=>{
			if(res.status==200){
				SetDepartMentList(res.data)
			}
		})
		getLocationList(requestBody).then(res=>{
			if(res.status==200){
				SetlocationList(res.data)
			}			
		})

		// getProductivityList(session,org_id).then(res=>{
		// 	if(res.status==200){
		// 		let a = []
		// 		let data = res.data
		// 		// console.log("hhnhh",typeof(data),data[0])
		// 		for(let i = 0; i< data.length; i++){
		// 			if(data[i].type == "regular_worker"){
		// 				//a.concat(res.data[i])
		// 				a = [...a, data[i]]
		// 			}
		// 		}

		// 		// console.log("hhnhh",a)
		// 		SetProductiveList(a);
		// 	}
			
		// })
		getProductivityList(session,org_id).then(res=>{
			if(res.status==200){
				SetProductiveList(res.data);
			}
			
		})
	},[])

		useEffect(()=>{

			SetAvailTag(props.availableTag);
		},[props])

	
	useEffect(()=>{
		console.log("props.employeeDetail",props.employeeDetail)
		if(props.employeeDetail!=''){

			SetFirstName(props.employeeDetail?.name)
			SetEmail(props.employeeDetail?.emp_email)
			SetDescription(props.employeeDetail?.description?props.employeeDetail?.description:'')
			SetDepartmentID(props.employeeDetail?.department_id)
			SetSubDepartmentID(props.employeeDetail?.sub_dept_id?props.employeeDetail?.sub_dept_id:'')
			SetEMPID(props.employeeDetail?.emp_id)
			SetShiftID(props.employeeDetail?.shift);
			SetRoleID(props.employeeDetail?.role_id)
			let productive= props.employeeDetail && props.employeeDetail.productivity?JSON.parse(props.employeeDetail.productivity):[] ;

			let prod = props.locationType.find(item=>item.loc_type=='productive')
			let nonprod = props.locationType.find(item=>item.loc_type=='non_productive')
			let lessprod = props.locationType.find(item=>item.loc_type=='less_productive')



			if(productive!=null && productive[prod.id]){
				SetProductive(productive[prod.id])
			}
			if(productive!=null && productive[lessprod.id]){
				SetLessProductive(productive[lessprod.id])
				
			}
			if(productive!=null && productive[nonprod.id]){
				SetNonProductive(productive[nonprod.id])
			}

		}

		setTimeout(function(){
			SetLoader(false);
		},1000)

	},[props.employeeDetail])

	function onChangeProductivitCat(data){
		
		SetProductiveID(data);
		let preselect = productiveList.find(item=>item.id == data);
		if(preselect){

			let prod = JSON.parse(preselect.productivity);
			let product = props.locationType.find(item=>item.loc_type=='productive')
			let nonprod = props.locationType.find(item=>item.loc_type=='non_productive')
			let lessprod = props.locationType.find(item=>item.loc_type=='less_productive')
			
            SetProductive(prod[product.id])
            SetLessProductive(prod[lessprod.id])
            SetNonProductive(prod[nonprod.id])
		}
	}
	
	useEffect(()=>{
		if(department_id!=''){

		
		let requestBody ={}
		requestBody.session=session
		requestBody.org_id=org_id
		requestBody.dept_id = department_id
		if(sublist == ''){
			requestBody.sub_org_id=sub_org_id
		}else{
			requestBody.sub_org_id=suborgid
		}
			getSubDepartmentList(requestBody).then(res=>{
				
				if(res.status==200 || res.code==200){
					let item = res.data.filter(item=>item.id== subDepartmentID);
					if(item){
						SetSubDepartment(res.data);
					}else{
						SetSubDepartmentID('')
						SetSubDepartment(res.data);
					}
				}
			})
		}
	},[department_id])

	function loadFile(event){

		let arry = event.target.files[0].name.split('.');
		let lastElement = arry[arry.length - 1];
	  
		if(lastElement=='jpeg' || lastElement=='png' || lastElement=='webp' || lastElement=='jpg' ){
			SetPreviewProfile(URL.createObjectURL(event.target.files[0]));
			setFile(event.target.files[0]);    
		}else{
			SetFileError('Only Support JPEG & PNG')
			SetPreviewProfile('');
			setFile('');    
			//setFile(event.target.files[0]);    
			setTimeout(function(){
			SetFileError('')
			},3000)
		}
	
	}
	
	
    const onSelect = (data) => {

      };
      const onChange = (data) => {
        setValue(data);
			if(data=='other'){

				SetShiftToggle(true)
			}else{
				SetShiftToggle(false)
			}

      };
	  const onChangeShift = (data) => {
        SetShiftID(data);
			if(data=='other'){

				SetShiftToggle(true)
			}else{
				SetShiftToggle(false)
			}

      };
	  
	const onChangeDepartment = (data)=>{
		SetDepartmentID(data);
	}

	const onChangeSubDepartment = (data) =>{
		SetSubDepartmentID(data);
	}
    const onChangeRole =(data)=>{
		SetRoleID(data);	
	}
	  

      function onChangeSelect(value) {

      }

	  function onChangeStep(step){
		var validRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		if(current==0){
			if(firstname==''){
				SetFirstNameError('First name is required');
			}
			else if(firstname.length > 255){
				SetFirstNameError('Length of first name should be less than 255 character');
			}
			else if(description.length > 255 ){
				SetDescriptionError('Length of designation should be less than 255 character ')
			}

			else{
				setCurrent(step);	
			}
		}

		else{
			setCurrent(step);
		}
		//setCurrent(step);
		
	  }

	  function CreateShiftHandler(){

		let requestBody2= {};
		requestBody2.session=session
		requestBody2.org_id=org_id
		requestBody2.shift_name= shiftName
		requestBody2.start_time =moment(shiftStartTime).format('HH:mm:ss') 
		requestBody2.end_time = moment(shiftEndtTime).format('HH:mm:ss')
		if(shiftStartTime=='' || shiftEndtTime==''|| shiftName=='' ){
			if(shiftName==''){SetShiftNameError('Shift Name is required')}else{SetShiftNameError('')}
			if(shiftStartTime==''){SetStartTimeError('Shift Start Time is required')}else{SetStartTimeError('')}
			if(shiftEndtTime==''){SetEndTimeError('Shift End Time is required')}else{SetEndTimeError('')}
	
	
		}else{
		CreateShift(requestBody2).then(res=>{
			if(res.status==200){
				props.refreshShift();
				SetShiftID(res.data.shift_id);
				
				SetShiftName('');
				SetStartTime('');
				SetEndTime('')
				SetShiftToggle(false);

				SetTitle(<h4>Shift is created</h4>)
				SetDescriptionMessage(res.message)
				SetModalSucessShift(true)
			}else{
				SetTitle(<h4>{res.message}</h4>)
				SetDescriptionMessage('Fail to create shift')
				SetModalError(true)

				setCurrent(1);
				SetTitle(res.message);
				SetDescriptionMessage('Shift is not created')
				SetModalError(true)
			}
		})
	}


	  }

	  function handleCloseModalSucess(){
		SetModalSucess(false)
		props.handleCloseModal()
		props.Reload();
	}

	function handleCloseModalSucessShift(){
		SetModalSucessShift(false);
	}

	function handleCloseModalError(){
		SetModalError(false)
		//props.handleCloseModal()
	}



	  function ChangeRadio(e,val){

		let val2 = parseInt(e.target.value);

		if(e.target.checked){
			if(val=="productive"){
				let arr=[...productive];
				arr.push(val2)
				SetProductive(arr)
				
				let arr2 = [...lessproductive]
				if(arr2.indexOf(val2) > -1){
					arr2.splice(arr2.indexOf(val2), 1);
					SetLessProductive(arr2);
				}
				

				let arr3 = [...nonproductive]
				if(arr3.indexOf(val2) > -1){
					arr3.splice(arr3.indexOf(val2), 1);
					SetNonProductive(arr3);	
				}

				let arr4= [...nonSelectProductive];
				if(arr4.indexOf(val2) > -1){
					arr4.splice(arr4.indexOf(val2), 1)
					SetNonSelectProductive(arr4)	
				}

			}
			else if(val=='lessproductive'){
				let arr=[...lessproductive];
				arr.push(val2)

				SetLessProductive(arr)

				let arr2 = [...productive]
				if(arr2.indexOf(val2) > -1){
					arr2.splice(arr2.indexOf(val2), 1);
					SetProductive(arr2);
				}	

				let arr3 = [...nonproductive]
				if(arr3.indexOf(val2) > -1){
					arr3.splice(arr3.indexOf(val2), 1);
					SetNonProductive(arr3);
				}

				let arr4= [...nonSelectProductive];
				if(arr4.indexOf(val2) > -1){
					arr4.splice(arr4.indexOf(val2), 1)
					SetNonSelectProductive(arr4)
				}	
				
			}else{
				let arr=[...nonproductive];
				arr.push(val2)
				SetNonProductive(arr)

				let arr2 = [...lessproductive]
				if(arr2.indexOf(val2) > -1){
					arr2.splice(arr2.indexOf(val2), 1);
					SetLessProductive(arr2);
				}				
				let arr3 = [...productive]
				if(arr3.indexOf(val2) > -1){
					arr3.splice(arr3.indexOf(val2), 1);
					SetProductive(arr3);
				}

				let arr4= [...nonSelectProductive];
				if(arr4.indexOf(val2) > -1){
					arr4.splice(arr4.indexOf(val2), 1)
					SetNonSelectProductive(arr4)
				}
			}
		// }else{
		// 	let arr=[...productive];
		// 	let arr2=[...nonproductive];
		// 	let arr3=[...lessproductive];

		// 	if(val=="productive"){
		// 		let index = arr.indexOf(val2)
		// 		if(arr.indexOf(val2)!=-1 && ( arr2.indexOf(val2)!=-1 || arr3.indexOf(val2)!=-1) ){  
		// 			arr.splice(arr.indexOf(val2), 1)
		// 		}   
		// 		SetProductive(arr)
		// 	}
		// 	else if(val=="lessproductive"){
		// 		if(arr3.indexOf(val2)!=-1 && ( arr2.indexOf(val2)!=-1 || arr.indexOf(val2)!=-1) ){  
		// 			SetLessProductive(arr3.splice(arr3.indexOf(val2), 1) )
		// 		}   
		// 	}else{
		// 		if(arr2.indexOf(val2)!=-1 && ( arr3.indexOf(val2)!=-1 || arr.indexOf(val2)!=-1) ){  
		// 			SetNonProductive(arr2.splice(arr2.indexOf(val2), 1))
		// 		}   
		// 	}
		// }
		
	  }
	}

	

function AddUserHandler(){

	let arr = [];
	let arr2=[];
	//Assign tag is
	var validRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	if(assignedTag===false){

		if(firstname==''){
			SetFirstNameError('First name is required');
		}else if(firstname.length > 255){
			SetFirstNameError('Length of first name should be less than 255 character');
		}
		if(email.length > 255 ){
			SetEmailError('Length of Last name should be less than 255 character ')
		}

		 if(description.length > 255 ){
			SetDescriptionError('Length of designation should be less than 255 character ')
		}if(role_id==''){
			SetRoleIDError('Role name is required')
		}

		if(firstname!='' &&firstname.length <= 255 &&  description.length<=255 ){
				SetFirstNameError('');
				SetDescriptionError('');
				SetRoleIDError('');

				
				let requestBody={};		

				requestBody.new_tag_serial = ''
				// requestBody.description = description
				requestBody.org_id= org_id
				requestBody.department_id=department_id
				requestBody.sub_org_id=sub_org_id
				requestBody.emp_id=emp_id
				requestBody.employee_id=props.employeeID;
				requestBody.shift = shiftID
				requestBody.sub_dept_id = subDepartmentID
				requestBody.employee_name= firstname
				requestBody.session=session	
				requestBody.assign_tag="False"

					EditUser(requestBody,file).then(res=>{
						if(res.status==200|| res.code==200){
							SetTitle(<><strong>{firstname}</strong> employee is updated</>);
							SetDescriptionMessage('')
							SetModalSucess(true)
							
						}else{
							SetTitle(res.message);
							SetDescriptionMessage(<><strong>{firstname}</strong> employee is not updated</>)
							SetModalError(true)
						}
					})
		}
		

	}else{

		let requestBody={};
		
		requestBody.employee_id=props.employeeID;
		requestBody.emp_id=props.employeeID;
		requestBody.new_tag_serial = selectedTag==''||selectedTag=='Select tag, If need to change'?props.employeeDetail?.tag_serial:selectedTag;
		
		requestBody.existing_tag_serial = props.employeeDetail?.tag_serial
		//requestBody.description = description
		requestBody.org_id= org_id
		requestBody.role_id= role_id
		requestBody.department_id=department_id

		requestBody.emp_id=emp_id;
		requestBody.shift = shiftID!=''?shiftID:props.employeeDetail?.shift
		//requestBody.email=email
		requestBody.sub_dept_id = subDepartmentID
		requestBody.employee_name= firstname
		requestBody.session=session		
		if(sub_org_id !=0){
			requestBody.sub_org_id= suborgid
		}else{
			requestBody.sub_org_id= sub_org_id
		}
		
		requestBody.assign_tag=assignedTag===true?"True":"False"
		
			EditUser(requestBody,file).then(res=>{
				if(res.status==200|| res.code==200){
					if(sub_org_id!=0 && String(sub_org_id) !== sublist  && res.status==200 ){
						let requestBody3={}
							requestBody3.department_id=department_id
							requestBody3.sub_department_id=subDepartmentID
							requestBody3.employee_id=props.employeeID
							requestBody3.org_id=org_id
							requestBody3.sub_org_id=sublist
							console.log("sublist2",sublist)
							requestBody3.session=session
					UpdateSuborg(requestBody3).then(res2=>{
						if(res2.status==200|| res2.code==200){
							SetTitle(<><strong>{firstname}</strong> employee is updated with Sub Org </>);
							SetDescriptionMessage('')
							SetModalSucess(true)
						}else{
							SetTitle(res2.message);
							SetDescriptionMessage(<><strong>{firstname}</strong> employee Updated Sub Org not updated</>)
							SetModalError(true)
						}
					})	
		
					}else{
						SetTitle(<><strong>{firstname}</strong> employee is updated</>);
					SetDescriptionMessage('')
					SetModalSucess(true)
					}
					
					
					
				}else{
					SetTitle(res.message);
					SetDescriptionMessage(<><strong>{firstname}</strong> employee is not updated</>)
					SetModalError(true)
				}
				// console.log(sub_org_id,sublist,"else")
			// if(sub_org_id!=0 && String(sub_org_id) !== sublist && sub_org_id !== [] && res.status==200 ){
			// 	let requestBody3={}
			// 		requestBody3.department_id=department_id
			// 		requestBody3.sub_department_id=subDepartmentID
			// 		requestBody3.employee_id=props.employeeID
			// 		requestBody3.org_id=org_id
			// 		requestBody3.sub_org_id=sublist
			// 		console.log("sublist2",sublist)
			// 		requestBody3.session=session
			// UpdateSuborg(requestBody3).then(res2=>{
			// 	if(res2.status==200|| res2.code==200){
			// 		SetTitle(<><strong>{firstname}</strong> employee is updated </>);
			// 		SetDescriptionMessage('')
			// 		SetModalSucess(true)
			// 	}else{
			// 		SetTitle(res2.message);
			// 		SetDescriptionMessage(<><strong>{firstname}</strong> employee is not updated</>)
			// 		SetModalError(true)
			// 	}
			// })	

			// }
			})
			
	
}

}
const handleSublistChange = (val, label) => {
    setsublist(val);
  setSubOrgId(val);

  };


    return(

        <div>
			
            <div className='modalHeader'>
                    <h4>Edit Employeee</h4>
                    <div className='closeMark' onClick={()=>props.handleCloseModal()}>x</div>
                </div>
            {loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>:<div className='modalBody'>
			{ assignedTag===true||assignedTag===false?
				<Steps current={current} onChange={onChangeStep}>
					{steps.map((item) => {

					if(item.title=='Productivity'){
						return  <><Step key={item.title} title={item.title} />

						</>
					}else{
						return		<Step key={item.title} title={item.title} />
					}
				}
					)}


					
				</Steps> :""}
				<select
						placeholder="Select Productive Type"
						optionFilterProp="children"
						onChange={(e)=>{onChangeProductivitCat(e.target.value); }}
						value={productiveID}
						className={current==2?'inlast-step form-control activeShow':'inlast-step form-control hide'}
						
					>
						<option value={''}>{'Select'}  </option>
						{productiveList.map(item=>{
							return <option value={item.id} key={"productibity"+item.id}>{item.category_name}  </option>
						})}
						
					</select>
					<form autoComplete='off'>
				{current==0?<div className="steps-content step-user-info">
					<Row>
						<Col lg={12}>
							<div className='field-row'>
									<label>Name* <div className='posRel'><HelpIcon /><span className='helpSmall'>Max Length 255 character</span></div></label>
									<input type="text" className={firstnameError!=''?'form-control has-error':'form-control'} value={firstname} onChange={(e)=>{if(reg.test(e.target.value)){SetFirstName(e.target.value);}else if(e.target.value==''){SetFirstName(e.target.value)} }  } />
									{firstnameError!=''?<div className='errorMessage'>{firstnameError}</div>:""}
							</div>	
						</Col>

						<Col lg={6}>
							<div className='field-row'>
									<label>Existing Tag <strong>{props.employeeDetail?.tag_serial}</strong><div className='posRel'><HelpIcon /><span className='helpSmall'>Select new tag if want to change</span></div></label> 
									
									<Select
									placeholder="Select Department"
									optionFilterProp="children"
									onChange={(val)=>SetSelectedtag(val)}
									className={department_idError!==''?'form-control has-error':'form-control'}
									filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									showSearch
									defaultValue={selectedTag}
									
								>
									<Option value={''} key={"Tag0"}>{'Select tag, If need to change'}</Option>
									{availableTag.length > 0 && availableTag!=''?availableTag.map((item)=>{
										return <Option value={item.serial_num} key={"availTag"+item.serial_num}>{item.serial_num}</Option>
									}):""
									}
									
								</Select>
								
							</div>	
						</Col>

						<Col lg={1} style={{maxWidth:"125px"}}>
						<div className='field-row'>
									<label style={{marginBottom:"10px" ,fontSize:13}}>Assign Tag</label>
									<Switch style={{marginTop:"10px"}}  onChange={(e)=>SetAssignedTag(!assignedTag)} checked={assignedTag} />
							</div>
						</Col>
						
						<Col lg={5}>
							<div className='field-row'>
								<label style={{marginBottom:"10px"}}>Add Profile Image</label>
                                <input type="file"  id="file" placeholder="browse" accept="image/png, image/gif, image/jpeg" onChange={(e)=>loadFile (e) } />
                                <label htmlFor="file" className='fileUploadStyle'><span>Browse file</span><small>{file?file.name:""}</small> </label>
								{fileError!=''?<div className='errorMessage'>{fileError}</div>:''}
                            </div>
                        	
						</Col>
						<Col lg={6}>
						{userDetails.org_type === "oem"  && userDetails.sub_org_id > 0 ? (
						<div className='field-row'>
						<label style={{ marginBottom: "10px" }}>Select Sub Organisation</label>
						<Select
							placeholder="Select suborganisation"
							optionFilterProp="children"
							//onChange={handleSublistChange}
							onChange={(value) => {
								handleSublistChange(value);
								onChangeDepartment(''); 
							}}
							//className={sublist ==''?'form-control has-error':'form-control '}
							filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							//defaultValue={props.productiveIDData.type}
							value={sublist}
							// key={'lcoationNameMapping'}
						>
							<Option value={''} key={"Tag0"}>{'Select a sub orgnaization'}</Option>
						{props.suborglist.map((element) => (
															<Option key={element.id} value={element.id}>
															{element.name.toLowerCase()}
															</Option>
														))}
						</Select>
						</div>
                        ) :("")}
						</Col>
						<Col lg={4}>
						{previewProfile?<div className='previewArea'>
                                <div className='icon-row profile-icon'  >
                                     
                                      <div className='headerIconProfile'><img src={previewProfile} className="avatar-size"/> </div>
                                        <div className='headerIconName'>
                                                 <span className='author-name'>{ firstname!='' &&lastname!=''?<>{firstname} {lastname}</>:'Full Name'} </span>
                                                <span className='author-name author-name-role'>{role_id!=''?<><span>Role</span>{props.roleListData.filter((item)=>item.id == role_id).length> 0?props.roleListData.filter((item)=>item.id == role_id).name:"some role"}</>:<><span>Role</span> Some Role </>}</span>
                                        </div>
                                        <span className='icon-toggle'>
                                            <UPIcon/>
                                        </span>                                        
                                </div>
                            </div>:""}
						</Col>

					</Row>						

				</div>:""}
				{current==1?<div className="steps-content">
						<Row>
							<Col lg={6}>
							<div className='field-row'>
                      		  <label>Department*<div className='posRel'><HelpIcon /><span className='helpSmall'>Select Deperatment name for this employee</span></div></label>
								{department_id?<Select
									placeholder="Select Department"
									optionFilterProp="children"
									onChange={onChangeDepartment}
									className={department_idError!==''?'form-control has-error textCap':'form-control textCap'}
									filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									defaultValue={department_id}
								>
									{department_list.map((item)=>{
										return <Option value={item.id} key={"Department"+item.id}>{item.name.toLowerCase()}</Option>
									})
									}
									
								</Select>:<Select
									placeholder="Select Department"
									optionFilterProp="children"
									onChange={onChangeDepartment}
									className={department_idError!==''?'form-control has-error textCap':'form-control textCap'}
									filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									defaultValue={department_id}
								>
									{department_list.map((item)=>{
										return <Option value={item.id} key={"Department"+item.id}>{item.name.toLowerCase()}</Option>
									})
									}
									
								</Select> }
								{department_idError!=''?<div className='errorMessage'>{department_idError}</div>:""}
								</div>


								
							</Col>

							{subDepartment.length>0 ?<Col lg={6}>
							<div className='field-row'>
                      		  <label>Sub Department<div className='posRel'><HelpIcon /><span className='helpSmall'>Sub Deperatment selection for this employee</span></div></label>
                                {subDepartmentID?<Select
									placeholder="Select Sub Department"
									optionFilterProp="children"
									onChange={(val)=>SetSubDepartmentID(val)}
									
									filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									value={subDepartmentID}
									className='textCap'
								>
									{subDepartment.map((item)=>{
										return <Option value={item.id} key={"SubDepartment"+item.id}>{item.sub_dept_name.toLowerCase()}</Option>
									})
									}
									
								</Select>:<Select
									placeholder="Select Sub Department"
									optionFilterProp="children"
									onChange={onChangeSubDepartment}
									
									filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									value={subDepartmentID}
									className='textCap'
								>
									{subDepartment.map((item)=>{
										return <Option value={item.id} key={"SubDepartment"+item.id}>{item.sub_dept_name.toLowerCase()}</Option>
									})
									}
									
								</Select> }
								</div>
							</Col>:''}

	
					<Col lg={6}>
							<div className='field-row'>
                      		  <label>Employee ID<div className='posRel'><HelpIcon /><span className='helpSmall'>Max Length 255 character</span></div></label>
                                <input type="text" className={emp_idError!==''?'form-control has-error':'form-control'} onChange={(e)=>{if(reg.test(e.target.value)){SetEMPID(e.target.value);}else if(e.target.value==''){SetEMPID(e.target.value)} }  } value={emp_id} readOnly />
								{emp_idError!=''?<div className='errorMessage'>{emp_idError}</div>:""}
								</div>
							</Col>
													
							{shift.length > 0?<Col lg={6} className="shiftInResponsive">
							<div className='field-row'>
							<label>Shift* <div className='posRel'><HelpIcon /><span className='helpSmall'>Employee shift time is required</span></div></label>
                            {shiftID?
								<Select
										placeholder="Select Time"
										optionFilterProp="children"
										onChange={onChangeShift}
									
										filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										value={shiftID}
									>
									{shift.map(item=>{
										return <Option value={item.id} key={item.shift_name}> {item.shift_name} {  item.start_time.split(':')[0]+':'+item.start_time.split(':')[1] } - {item.end_time.split(':')[0]+':'+item.end_time.split(':')[1]} </Option>
									})}
									<Option value={'other'} key={'other'}>Create</Option> 
								</Select>:							<Select
									placeholder="Select Time"
									optionFilterProp="children"
									onChange={onChangeShift}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									value={shiftID}
								>
									{shift.map(item=>{
										return <Option value={item.id} key={item.shift_name}> {item.shift_name} {  item.start_time.split(':')[0]+':'+item.start_time.split(':')[1] } - {item.end_time.split(':')[0]+':'+item.end_time.split(':')[1]} </Option>
									})}
									<Option value={'other'} key={'other'}>Create</Option> 
								</Select>
								}
								</div>
							</Col>:""}	
							{shiftToggle || shift.length==0?<Col lg={6}>
							<Row>
							<Col lg={3}>
								<label>Shift Name</label>
								<input type="text" value={shiftName} onChange={(e)=>{if(reg.test(e.target.value)){SetShiftName(e.target.value);}else if(e.target.value==''){SetShiftName(e.target.value)} }  } className='form-control' />
								{shiftNameError!=''?<div className='errorMessage'style={{lineHeight:1}}>{shiftNameError}</div>:""}
							</Col>
							<Col lg={3} className="timePickMy" >
								<label>Start Time</label>
								<LocalizationProvider dateAdapter={AdapterMoment}>
									<TimePicker
										value={shiftStartTime}
										onChange={(newValue) => {
											SetStartTime(newValue);
										}}
										ampm={false}
										renderInput={(params) => <TextField {...params}
										/>}
									/>
								</LocalizationProvider>
								{shiftStartTimeError!=''?<div className='errorMessage' style={{lineHeight:1}}>{shiftStartTimeError}</div>:""}
							</Col>
							<Col lg={3} className="timePickMy" >
								<label>End Time</label>
								<LocalizationProvider dateAdapter={AdapterMoment}>
									<TimePicker
										value={shiftEndtTime}
										onChange={(newValue) => {
											SetEndTime(newValue);
										}}
										ampm={false}
										renderInput={(params) => <TextField  {...params} 
										/>}
									/>
								</LocalizationProvider>
								{shiftEndtTimeError!=''?<div className='errorMessage' style={{lineHeight:1}}>{shiftEndtTimeError}</div>:""}
							</Col>
							<Col lg={3} className="shiftcreatebutton" >
								<div className='btn btn-submit' style={{marginTop:"20px"}} onClick={CreateShiftHandler}>Create Shift</div>
							</Col>


						</Row>
						{shiftMessage!=''?<div className='errorMessage' style={{lineHeight:1}}>{shiftMessage}</div>:""}
					</Col>:""}
						</Row>




				</div>:""}
				
				</form>
				<div className="steps-action">
				{/* {assignedTag===false &&  (
					<Button type="primary" onClick={AddUserHandler} className={'btn btn-primary'}>
						Update Employee
					</Button>
					)} */}				
				{current > 0 && (assignedTag===true||assignedTag===false) && (
					<Button style={{ margin: '0 8px' }} onClick={() => prev()} className={'btn btn-primary'}>
						Back
					</Button>
					)}

					{current < steps.length - 1  && (assignedTag===true||assignedTag===false) && (
					<Button type="primary" onClick={() => next()} className={'btn btn-primary'}>
						Next
					</Button>
					)}
					{current === steps.length - 1  && (assignedTag===true||assignedTag===false) && (
					<Button type="primary" onClick={AddUserHandler} className={'btn btn-primary'}>
						Update Employee
					</Button>
					)}
				</div>
				
				<SucessModal modalSucess={modalSucessShift} handleCloseModalSucess={handleCloseModalSucessShift} title={title} description={descriptionMessage} key="editEMployeeSucessShift" />
				<SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={descriptionMessage} key="editEMployeeSucess" />
		<ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={descriptionMessage}   key="editEMployeeFail" />

                </div>}
        </div>
    )
}
export default EditEmployee;