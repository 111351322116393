import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import ReactModal from 'react-modal';
import '../../styles/group.scss';
import LoaderSpinner from '../../../assets/images/loader.svg'
import {editSubDepartment} from '../../actionMethods/actionMethods'
import SucessModal from '../../../components/SucessModal';
import ErrorModal from '../../../components/ErrorModal';
import {ReactComponent as HelpIcon} from  '../../../assets/images/circle-info.svg'

import { Select } from 'antd';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        maxHeight: '90%'
    },
};



function EditSubGroup(props) {
    const { Option } = Select;
    const [value, setValue] = useState('');
	const [group_name,SetGroupName]= useState(props.group_name!=null?props.group_name:"");
    const [subGroupID,SetSubGroupID]= useState(props.subGroupID!=null?props.subGroupID:'')
	const [group_id,SetGroupID]=useState(props.group_id?props.group_id:'')
    const [Subgroup_name,SetSubGroupName]= useState(props.subgroup_name);
	const [group_nameError,SetGroupNameError]= useState('');
	const [loader,SetLoader]=useState(false)
    const [department_id,SetDepartmentID] =useState(props.group_id)
    const [department_idError,SetDepartmentIDError] =useState('')

    const [modalSucess,SetModalSucess] = useState(false);
    const [modalError,SetModalError] = useState(false); 
    const [title,SetTitle] =useState('');
    const [description,SetDescription] =useState('');
    const reg = /^\S.*$/;

    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
    let org_id = userDetails.org_id?userDetails.org_id:""

    function handleCloseModalSucess(){
		props.Reload();
        props.handleCloseModal()
        SetModalSucess(false)
	  }
      
	  function handleCloseModalError(){
		SetModalError(false)
	  }    

    

      function SubmitHandlerGroup(){

          if(department_id==''){
              SetDepartmentIDError('Department Name is required')
          }
		  else if(Subgroup_name==''){
            SetDepartmentIDError('')
            SetGroupNameError("Sub Department is required.")
          }else if(Subgroup_name.length > 255){
            SetGroupNameError("Sub Department length should be less than 255 character")
          }
          else if(Subgroup_name!='' && group_id!='' && subGroupID!='' ){	
            SetDepartmentIDError('')
            SetGroupNameError('');
			let requestBody={}
            requestBody.session=session
            requestBody.org_id=org_id
            requestBody.department_id= department_id
            requestBody.sub_department_name=Subgroup_name
            requestBody.sub_department_id= subGroupID

            SetLoader(true);
            editSubDepartment(requestBody).then(res=>{
                SetLoader(false);
                if(res.status==200){
                    SetTitle(<h4>Sub Department is updated</h4>)
                    SetDescription('')
                    SetModalSucess(true)
            }else{
                    SetTitle(<h4>{res.message}</h4>)
                    SetDescription('Sub Department is not updated')
                    SetModalError(true)
                   
            }
                if(res.status==300){
                    localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                    localStorage.removeItem('userLoginDetailsTraceAdmin')
                    props.history.push(`/login`)
                }

            })
            
		  }else{
			  SetGroupNameError('Sub Department Name is required')
		  }
	  }

    return(

        <div>
             <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
		<ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />
			{loader?<div className='loaderContainer loaderContainerSmall'><img src={LoaderSpinner}/></div>:<>
            <div className='modalHeader'>
                    <h4>Edit Sub Department</h4>
                    <div className='closeMark' onClick={()=>props.handleCloseModal()}>x</div>
                </div>
                <div className='modalBody'>
                    <div className='field-row'>
                        <label>Department Name* <div className='posRel'><HelpIcon /><span className='helpSmall'>Select any department name if need to change</span></div></label>
                        <Select
                            placeholder="Select Department"
                            optionFilterProp="children"
                            onChange={(val)=>SetDepartmentID(val)}
                            className={department_idError!==''?'form-control has-error':'form-control'}
                            filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            defaultValue={department_id}
                        >
                            {props.parentGroup.map((item)=>{
                                return <Option value={item.id} key={"Department"+item.id}>{item.name.toLowerCase()}</Option>
                            })
                            }
                        </Select>
                        {department_idError!=''?<div className='errorMessage'>{department_idError}</div>:""}
                    </div>

                    <div className='field-row'>
                        <label>Sub Department Name* <div className='posRel'><HelpIcon /><span className='helpSmall'>Max Length 255 character</span></div></label>
                        <input type="text" className={group_nameError!=''?'form-control error':'form-control'} value={Subgroup_name} onChange={(e)=>{if(reg.test(e.target.value)){SetSubGroupName(e.target.value);}else if(e.target.value==''){SetSubGroupName(e.target.value)} }  } /> 
                        {group_nameError!=''?<div className='errorMessage'>{group_nameError}</div>:""}
                    </div>
                    <button className='btn btn-primary btn-100' onClick={SubmitHandlerGroup}>Update Sub Department</button>    
                </div>
				</> }
        </div>
    )
}
export default EditSubGroup;