import React, { useState, useEffect,useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ExcelJs from "exceljs";
import '../style/styles.scss';
import {getClientDBList} from '../actionMethods/actionMethods'
import LoaderSpinner from '../../assets/images/loader.svg'
import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';


function ProductivityList(props){

    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
    let org_id = userDetails.org_id?userDetails.org_id:""


    const [emplyeeList,SetemplyeeList] =useState([]);
    const [emplyeeListAll,SetemplyeeListAll] =useState([]);
    const [WidthContainer,setWidthContainer] = useState(0);
    const elementRef = useRef(null);   
    const [loader,Setloader] =useState(true);
    
    
    useEffect(() => {
        setWidthContainer(elementRef.current.getBoundingClientRect().width);
    
    }, [elementRef]);

    useEffect(()=>{
    if (props.permission) {
        getClientDBList(session, org_id).then(res => {
          if (res.status === 200) {
            let filteredList = res.data;
            console.log("filtered data",filteredList)
            SetemplyeeList(filteredList);
            SetemplyeeListAll(res.data);
            Setloader(false);
          }
        });}
      },[props.permission,props.inc])

    useEffect(()=>{
        if(props.searchEmployee.length> 0){

            let filter = emplyeeListAll.filter( item=> item.name.toString().toLowerCase().includes(props.searchEmployee.toString().toLowerCase()     ) )
            SetemplyeeList(filter)
        }else{
            SetemplyeeList(emplyeeListAll)
        }

    },[props.searchEmployee]) 

    const exportToExcel = () => {
        // Create a workbook and a worksheet
        const workbook = new ExcelJs.Workbook();
        const worksheet = workbook.addWorksheet("Employee Data");
    
        // Add headers to the worksheet
        worksheet.addRow(["Name", "Worker ID", "Site", "Agency", "Worker Type"]);
    
        // Add data to the worksheet
        emplyeeList.forEach(employee => {
          worksheet.addRow([
            employee.name,
            employee.worker_id,
            employee.site,
            employee.agency,
            employee.worker_type
          ]);
        });
    
        // Create a blob from the Excel file
        workbook.xlsx.writeBuffer().then(buffer => {
          const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    
          // Trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "employee_data.xlsx";
          link.click();
        });
      };
  
    function showCardemplyeeList(){
       
      let arr = <div className="ag-theme-alpine" style={{ height: '500px', width: '100%' }}>
        <AgGridReact
            rowData={emplyeeList}
            pagination={true}
            domLayout='autoHeight'
            paginationPageSize={100} // Set the number of rows per page
            defaultColDef={{
                             sortable: true,
                             resizable: true,
                             flex:1,
                            //  width: 235,   
                             height:38,
                             headerHeight:38
                         }}
         >
             <AgGridColumn 
                 field="name"
                 headerName={"Name"}
                //  width={WidthContainer - 220}
             ></AgGridColumn>
          <AgGridColumn 
                 field="worker_id"
                 headerName={"Worker ID"}
                //  width={WidthContainer - 220}
             ></AgGridColumn>
             <AgGridColumn 
                 field="site"
                 headerName={"Site"}
                //  width={WidthContainer - 220}
             ></AgGridColumn>
              <AgGridColumn 
                 field="agency"
                 headerName={"Agency"}
                //  width={WidthContainer - 220}
             ></AgGridColumn>
             <AgGridColumn 
                 field="worker_type"
                 headerName={"Worker Type"}
                //  width={WidthContainer - 220}
             ></AgGridColumn>
        </AgGridReact>
         </div>                
return arr;

    } 
    return(<div ref={elementRef}>
         {/* <button onClick={exportToExcel}>Export to Excel</button> */}
            {loader?<div className='loaderContainer'>
                    <img src={LoaderSpinner}/></div>:showCardemplyeeList()}
                     
                </div>)
}


const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(ProductivityList))
