import React, { useState, useEffect,useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';
import '../../styles/style.scss';

import moment from 'moment';
import { Checkbox, Divider,AutoComplete  } from 'antd';
import {ReactComponent as ArrowDown} from '../../../assets/images/arrow-down-long.svg'
import {ReactComponent as ArrowUp} from '../../../assets/images/arrow-up-long.svg'
import {ReactComponent as ArrowDownUp} from '../../../assets/images/arrow-down-arrow-up.svg'
import {ReactComponent as CircleArrow} from '../../../assets/images/caret-right.svg';
import {ReactComponent as CircleArrowDown} from '../../../assets/images/caret-down.svg'
import {ReactComponent as EditIcon} from '../../../assets/images/pen-to-square.svg'
import {ReactComponent as DeleteIcon} from '../../../assets/images/trash-can-xmark.svg'
import {ReactComponent as AddIcon} from '../../../assets/images/square-plus.svg'
import CreateCategory from './CreateCategory';
import EditCategory from './EditCategory';
import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getCategoryList} from '../../actionMethods/actionMethods'
import LoaderSpinner from '../../../assets/images/loader.svg'

function CategoryList(props){
    const defaultCheckedList = ['Apple', 'Orange'];
    const plainOptions = ['Apple', 'Pear', 'Orange'];

    const [checkedList, setCheckedList] = React.useState(defaultCheckedList);
    const [indeterminate, setIndeterminate] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);    
    const [activeAccord,SetActiveAccord] =useState([]);
    const [WidthContainer,setWidthContainer]=useState('');
    const [locationList,SetLocationList] =  useState([]);
    const [locationListDefault,SetLocationListDefault] =  useState([]);
    const [loader,SetLoader]=useState(true);
    const elementRef = useRef(null);   
    const [permission,SetPermission] =useState([]);
    const [permissionCategory,SetPermissionCategory] =useState([]);
    const [permissionLocation,SetPermissionLocation] =useState([]);
    


    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
    let org_id = userDetails.org_id?userDetails.org_id:""

    

    const [uniqueCat,SetUniqueCat]=useState([]);    
    const [availableTag,SetAvailTag]=useState(props.availableTag?props.availableTag:[]);

    useEffect(()=>{
        SetPermissionCategory(JSON.parse(props.permission[0].category))
        SetPermissionLocation(JSON.parse(props.permission[0].location))
        // console.log("cycv",props.permission[0])
    },[props.permission])

    useEffect(() => {    
            setWidthContainer(elementRef.current.getBoundingClientRect().width);
    }, [elementRef]); 
    
    
    const onChange = list => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
      };
    
      const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
      };

      useEffect(()=>{
        let data = [...locationListDefault];
        let arr= [];
        
        if(props.searchLocation.length > 0){

            for(let i=0;i<data.length;i++){
                
     
                if(data[i].name.toString().toLowerCase().includes(props.searchLocation.toLowerCase())){
                    arr.push(data[i]);
                }else{
                    if(data[i].location && data[i].location.length > 0){
                       
                        let filter = data[i].location.filter(item => item.location_name.toString().toLowerCase().includes(props.searchLocation.toLowerCase()) ||
                        item.tag_serial.toString().toLowerCase().includes(props.searchLocation.toLowerCase()) )
                       
                        if(filter.length>0){
                           arr.push({name:data[i].name,location:filter})
                         }
                    }else{
                       
                    }
                  
                }

                SetLocationList(arr);

            }
            
           // SetLocationList(locationListDefault);
        }else{
            SetLocationList(locationListDefault);

        }

      },[props.searchLocation])


    useEffect(()=>{
        
        let arr=[];
        
        let requestBody2={};
        requestBody2.session=session
        requestBody2.org_id=org_id
        getCategoryList(requestBody2).then(res2=>{

            if(res2.status == 200){
            let arrCat=res2.data;
            let requestBody={}
            requestBody.session= session;
            requestBody.org_id= org_id;
                let arr=[];
            for(let i=0;i<res2.data.length;i++){
                let loc = res2.data[i];
                loc.count = res2.data[i].location.length;
                arr.push(loc);
            }

            SetLocationList(arr);                
            SetLocationListDefault(arr)
            
            /* 
                getLocationList(requestBody).then(res=>{
                    
                        let data=res.data;
                        if(res.status==200|| res.code==200){

                            let data = res.data;
                            for(let i=0;i<arrCat.length;i++){
                            let CatPush =  res.data.filter((item) => {
                                return item.categories.toString().toLowerCase().includes(arrCat[i].name.toLowerCase());
                            })
                            arr.push({name:arrCat[i].name,data:CatPush?CatPush:[],id:arrCat[i].id,count:CatPush?CatPush.length:0})

                        }   
                        SetLocationList(arr);                
                        SetLocationListDefault(arr)
                        SetLoader(false);
                        }
                })
             */
            }else{
                SetLocationList([]);                
                SetLocationListDefault([])
    
            }

        SetLoader(false);

    })


    },[props.inc])  

    const [sort,SetSort]=useState('desc');
    const[sortKey,SetSortKey] =useState('');  


    function ChangeSorting(key){
        SetSort( sort=='desc'?'asc':'desc');
        SetSortKey(key);
    }

    useEffect(()=>{
        let arr= [...locationListDefault];
        
        if(sortKey=='Category'){

            arr = arr.sort((a, b) => {
                a = a.name.toUpperCase()
                b = b.name.toUpperCase()
                return sort === 'desc' ? (a == b ? 0 : b > a ? 1 : -1) : (a == b ? 0 : a > b ? 1 : -1)
            })


        }
        if(sortKey=='tag_count'){

            arr = arr.sort(function (x, y) {
                return sort === 'desc' ?y.count - x.count:x.count-y.count;
            });
    
        }

        SetLocationList(arr);


    },[sort,sortKey])

    function AccordionHandler(val,type='secondary'){
        let ActivList = [...activeAccord];
        let index = ActivList.indexOf(val)
        if(index !== -1){  
            ActivList.splice(index, 1);
            SetActiveAccord(ActivList);      
        }
        else if(type=='primary'){
            SetActiveAccord([val]);
     }     
        else {  
            ActivList.push(val);
            SetActiveAccord(ActivList);      
        }
        
    }

    function ShowCardListLocation(){

        let data = [...locationList]
        let arr=[];
        if(data.length>0){
            
        for (let i= 0;i<data.length;i++){
            let index =i+1;            
            arr.push(                 
              <Row  key={"contentCategory"+data[i].name.toLowerCase()} className={activeAccord.indexOf(data[i].name)!==-1 || props.searchLocation.length > 0 ?'contentCategory contentGroup active':'contentCategory contentGroup'}>
                <div className='Category-header group-header'> 
                 <Col lg={5} className="align-middle">
                    {/* <span className='align-with-title' style={{width:"50px"}}><Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} /></span>*/}
                     {data[i].location.length >0 && permissionLocation.View == "True" ?<span className='accordion-icon' onClick={ ()=>AccordionHandler(data[i].name,'primary')} >
                       {activeAccord.indexOf(data[i].name)!==-1 || props.searchLocation.length > 0 ? <CircleArrowDown/>:<CircleArrow />}     
                     </span>:""}
                     <span className='align-title-left'>
                     <h4 className='textCap'>{data[i].name.toLowerCase()}</h4>
                     </span>
                 </Col>
                 <Col lg={3} className="align-middle" style={{textAlign:"center"}}>{data[i].location.length}</Col>
                 <Col lg={4} className="align-middle siteManagementlast">
                     {props.permissionLocation && props.permissionLocation.Create=='True'?props.availableTag.length>0?<button className='btn btn-smaller btn-icon' onClick={()=>props.modalOpenAddEmp(data[i].id,data[i].name)}><AddIcon/><span>Add Location</span></button>:<button className='btn btn-smaller btn-icon disabled hover-message'><AddIcon/><span>Add Location</span><span className='messageTag'>0 Location Tag Available</span></button>:<button className='btn btn-smaller btn-icon disabled hover-message'><AddIcon/><span>Add Location</span><span className='messageTag'>Access Restricted</span></button>}
                      /{permissionCategory && permissionCategory.edit=='True'?<button className='btn btn-smaller btn-icon' onClick={()=>props.modalOpenCategoryEdit(data[i].id,data[i].name)}><EditIcon/><span>Edit</span></button>:<button className='btn btn-smaller btn-icon btn-disabled hover-message disabled' disabled><EditIcon/><span>Edit</span><span className='messageTag'>Access Restricted</span></button>} / 
                      {permissionCategory && permissionCategory.remove=='True'?<button className='btn btn-smaller btn-icon' onClick={()=>props.modalDeleteModal('Category',data[i].id,data[i].name)} ><DeleteIcon/><span>Delete</span></button>:<button className='btn btn-smaller btn-icon hover-message disabled'><DeleteIcon/><span>Delete</span><span className='messageTag'>Access Restricted</span></button>}</Col>
                 </div>
                 <div  className={activeAccord.indexOf(data[i].name)!==-1 || props.searchLocation.length > 0?'Category-content active':'Category-content'}>
                 <div className="ag-theme-alpine" style={{height:(60 + ( 40* data[i].location.length + 1 ) ) , width: '100%'}}>
                <AgGridReact
                   rowData={data[i].location}
                   defaultColDef={{
                        sortable: true,
                        resizable: true,
                        width: ( WidthContainer - 250)/2,
                        height:40,
                        headerHeight:40
                      }}
                      key={data[i].name}

                >
                    <AgGridColumn 
                        field="location_name"
                        headerName={"Location Name"}
                        cellRenderer= {(params)=>locNameRender(params)}
                    ></AgGridColumn>
                    <AgGridColumn 
                        field="tag_serial"
                        headerName={"Tag Serial"}
                        
                    ></AgGridColumn>
                    <AgGridColumn 
                        field="id"
                        headerName={"Action"}
                        cellRenderer= {(params)=>EdiButtonRender(params,data[i].id)}
                        width={160}
                    ></AgGridColumn>
                </AgGridReact>
                </div>                 
                
                
                 </div>
                </Row>
                
                ) 
                
        }
    }else{
        arr.push( 
            <Row className='contentGroup nofound' key={'contentGroupNoFound'}>
                <div className='group-header'>
                    <Col className='align-middle'>
                        <h6 className='listNOFound' style={{marginBottom:"0px"}}>No Data found</h6>
                    </Col>
                </div>
            </Row>
            ) 
    }

        return arr;

    }
    
    function editHandlerAlert(params,id){
        props.modalLocationEditModal(params,id);
    }
    function locNameRender(params){
  
        var op =document.createElement('div');

        var eGui = document.createElement('div');
      
        eGui.innerHTML= '<div class="elementCell textCap">'+params.value.toLowerCase()+'</div>';

return eGui;

            
    }
    function EdiButtonRender(params,cat_id){
        

        var op =document.createElement('div');

        var eGui = document.createElement('div');
      
        eGui.addEventListener('click', event => {
          if(event.target.getAttribute("data-action")=='edit'){
            editHandlerAlert(params.data,cat_id)
          }else if(event.target.getAttribute("data-action")=='delete'){


            props.modalDeleteModal('Location',params.data.id,params.data.location_name)
          }
        });
      
        var eGui2 = document.createElement('div');
        console.log("permission",permissionLocation)
         eGui.innerHTML= permissionLocation && permissionLocation.edit=='True'?'<div class="element-icon ant-table-cell">'+
                            '<button class="btn btn-edit btn-smaller btn-icon" data-action="edit" data-el="'+params.value+'"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M432 320c-8.836 0-16 7.164-16 16V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h112C184.8 96 192 88.84 192 80S184.8 64 176 64H63.1C28.65 64 0 92.65 0 128v320c0 35.35 28.65 64 63.1 64h319.1c35.35 0 63.1-28.65 63.1-64L448 336C448 327.2 440.8 320 432 320zM497.9 42.19l-28.13-28.14c-9.373-9.373-21.66-14.06-33.94-14.06s-24.57 4.688-33.94 14.06L162.4 253.6C153.5 262.5 147.4 273.9 144.9 286.3l-16.66 83.35c-1.516 7.584 4.378 14.36 11.72 14.36c.7852 0 1.586-.0764 2.399-.2385l83.35-16.67c12.39-2.479 23.77-8.566 32.7-17.5l239.5-239.5C516.7 91.33 516.7 60.94 497.9 42.19zM235.8 326.1c-4.48 4.48-10.13 7.506-16.35 8.748l-53.93 10.79L176.3 292.6c1.244-6.219 4.27-11.88 8.754-16.36l178.3-178.3l50.76 50.76L235.8 326.1zM475.3 87.45l-38.62 38.62l-50.76-50.76l38.62-38.62c4.076-4.076 8.838-4.686 11.31-4.686s7.236 .6094 11.31 4.686l28.13 28.14C479.4 68.9 480 73.66 480 76.14C480 78.61 479.4 83.37 475.3 87.45z"></path></svg> Edit</button>'+
                            
                           '</div>':'<div class="element-icon ant-table-cell">'+
                           '<button class="btn btn-edit btn-smaller btn-icon hover-message disabled" disabled><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M432 320c-8.836 0-16 7.164-16 16V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h112C184.8 96 192 88.84 192 80S184.8 64 176 64H63.1C28.65 64 0 92.65 0 128v320c0 35.35 28.65 64 63.1 64h319.1c35.35 0 63.1-28.65 63.1-64L448 336C448 327.2 440.8 320 432 320zM497.9 42.19l-28.13-28.14c-9.373-9.373-21.66-14.06-33.94-14.06s-24.57 4.688-33.94 14.06L162.4 253.6C153.5 262.5 147.4 273.9 144.9 286.3l-16.66 83.35c-1.516 7.584 4.378 14.36 11.72 14.36c.7852 0 1.586-.0764 2.399-.2385l83.35-16.67c12.39-2.479 23.77-8.566 32.7-17.5l239.5-239.5C516.7 91.33 516.7 60.94 497.9 42.19zM235.8 326.1c-4.48 4.48-10.13 7.506-16.35 8.748l-53.93 10.79L176.3 292.6c1.244-6.219 4.27-11.88 8.754-16.36l178.3-178.3l50.76 50.76L235.8 326.1zM475.3 87.45l-38.62 38.62l-50.76-50.76l38.62-38.62c4.076-4.076 8.838-4.686 11.31-4.686s7.236 .6094 11.31 4.686l28.13 28.14C479.4 68.9 480 73.66 480 76.14C480 78.61 479.4 83.37 475.3 87.45z"></path></svg> Edit<span class="messageTag">Acess Restricted</span></button>'+
                          '</div>';


                          eGui.innerHTML= 
        `<div class="element-icon ant-table-cell">
                            <button class="btn btn-edit btn-smaller btn-icon ${permissionLocation && permissionLocation.edit=='True'?'acessEnable':'hover-message disabled'}" data-action="edit" data-el="${params.value}"${permissionLocation && permissionLocation.edit !== 'True' ? 'disabled' : ''}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M432 320c-8.836 0-16 7.164-16 16V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h112C184.8 96 192 88.84 192 80S184.8 64 176 64H63.1C28.65 64 0 92.65 0 128v320c0 35.35 28.65 64 63.1 64h319.1c35.35 0 63.1-28.65 63.1-64L448 336C448 327.2 440.8 320 432 320zM497.9 42.19l-28.13-28.14c-9.373-9.373-21.66-14.06-33.94-14.06s-24.57 4.688-33.94 14.06L162.4 253.6C153.5 262.5 147.4 273.9 144.9 286.3l-16.66 83.35c-1.516 7.584 4.378 14.36 11.72 14.36c.7852 0 1.586-.0764 2.399-.2385l83.35-16.67c12.39-2.479 23.77-8.566 32.7-17.5l239.5-239.5C516.7 91.33 516.7 60.94 497.9 42.19zM235.8 326.1c-4.48 4.48-10.13 7.506-16.35 8.748l-53.93 10.79L176.3 292.6c1.244-6.219 4.27-11.88 8.754-16.36l178.3-178.3l50.76 50.76L235.8 326.1zM475.3 87.45l-38.62 38.62l-50.76-50.76l38.62-38.62c4.076-4.076 8.838-4.686 11.31-4.686s7.236 .6094 11.31 4.686l28.13 28.14C479.4 68.9 480 73.66 480 76.14C480 78.61 479.4 83.37 475.3 87.45z"></path></svg> Edit<span class="messageTag">Acess Restricted</span></button>
                            <button class="btn btn-edit btn-smaller btn-rightNOspace ${permissionLocation && permissionLocation.remove=='True'?'acessEnable':'hover-message disabled'}" data-action="delete" data-el="${params.value}"${permissionLocation && permissionLocation.remove !== 'True' ? 'disabled' : ''}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 128C391.2 128 384 135.2 384 144v288c0 26.47-21.53 48-48 48h-224C85.53 480 64 458.5 64 432v-288C64 135.2 56.84 128 48 128S32 135.2 32 144v288C32 476.1 67.89 512 112 512h224c44.11 0 80-35.89 80-80v-288C416 135.2 408.8 128 400 128zM432 64h-96l-33.63-44.75C293.4 7.125 279.1 0 264 0h-80C168.9 0 154.6 7.125 145.6 19.25L112 64h-96C7.201 64 0 71.2 0 80C0 88.8 7.201 96 16 96h416C440.8 96 448 88.8 448 80C448 71.2 440.8 64 432 64zM152 64l19.25-25.62C174.3 34.38 179 32 184 32h80c5 0 9.75 2.375 12.75 6.375L296 64H152zM148.7 363.3c6.242 6.246 16.37 6.254 22.62 0L224 310.6l52.69 52.69c6.242 6.246 16.37 6.254 22.62 0c6.25-6.25 6.25-16.38 0-22.62L246.6 288l52.69-52.69c6.25-6.25 6.25-16.38 0-22.62s-16.38-6.25-22.62 0L224 265.4L171.3 212.7c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L201.4 288l-52.69 52.69C142.4 346.9 142.4 357.1 148.7 363.3z"></path></svg> Delete <span class="messageTag">Acess Restricted</span></button>
                         </div>`;
        
    //     permissionLocation && permissionLocation.edit=='True'?'<div class="element-icon ant-table-cell">'+
    //     '<button class="btn btn-edit btn-smaller btn-icon" data-action="edit" data-el="'+params.value+'"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M432 320c-8.836 0-16 7.164-16 16V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h112C184.8 96 192 88.84 192 80S184.8 64 176 64H63.1C28.65 64 0 92.65 0 128v320c0 35.35 28.65 64 63.1 64h319.1c35.35 0 63.1-28.65 63.1-64L448 336C448 327.2 440.8 320 432 320zM497.9 42.19l-28.13-28.14c-9.373-9.373-21.66-14.06-33.94-14.06s-24.57 4.688-33.94 14.06L162.4 253.6C153.5 262.5 147.4 273.9 144.9 286.3l-16.66 83.35c-1.516 7.584 4.378 14.36 11.72 14.36c.7852 0 1.586-.0764 2.399-.2385l83.35-16.67c12.39-2.479 23.77-8.566 32.7-17.5l239.5-239.5C516.7 91.33 516.7 60.94 497.9 42.19zM235.8 326.1c-4.48 4.48-10.13 7.506-16.35 8.748l-53.93 10.79L176.3 292.6c1.244-6.219 4.27-11.88 8.754-16.36l178.3-178.3l50.76 50.76L235.8 326.1zM475.3 87.45l-38.62 38.62l-50.76-50.76l38.62-38.62c4.076-4.076 8.838-4.686 11.31-4.686s7.236 .6094 11.31 4.686l28.13 28.14C479.4 68.9 480 73.66 480 76.14C480 78.61 479.4 83.37 475.3 87.45z"></path></svg> Edit</button>'+
        
    //    '</div>':'<div class="element-icon ant-table-cell">'+
    //    '<button class="btn btn-edit btn-smaller btn-icon hover-message disabled" disabled><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M432 320c-8.836 0-16 7.164-16 16V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h112C184.8 96 192 88.84 192 80S184.8 64 176 64H63.1C28.65 64 0 92.65 0 128v320c0 35.35 28.65 64 63.1 64h319.1c35.35 0 63.1-28.65 63.1-64L448 336C448 327.2 440.8 320 432 320zM497.9 42.19l-28.13-28.14c-9.373-9.373-21.66-14.06-33.94-14.06s-24.57 4.688-33.94 14.06L162.4 253.6C153.5 262.5 147.4 273.9 144.9 286.3l-16.66 83.35c-1.516 7.584 4.378 14.36 11.72 14.36c.7852 0 1.586-.0764 2.399-.2385l83.35-16.67c12.39-2.479 23.77-8.566 32.7-17.5l239.5-239.5C516.7 91.33 516.7 60.94 497.9 42.19zM235.8 326.1c-4.48 4.48-10.13 7.506-16.35 8.748l-53.93 10.79L176.3 292.6c1.244-6.219 4.27-11.88 8.754-16.36l178.3-178.3l50.76 50.76L235.8 326.1zM475.3 87.45l-38.62 38.62l-50.76-50.76l38.62-38.62c4.076-4.076 8.838-4.686 11.31-4.686s7.236 .6094 11.31 4.686l28.13 28.14C479.4 68.9 480 73.66 480 76.14C480 78.61 479.4 83.37 475.3 87.45z"></path></svg> Edit<span class="messageTag">Acess Restricted</span></button>'+
    //   '</div>';
      
    //   permissionLocation && permissionLocation.remove=='True'?'<div class="element-icon ant-table-cell">'+
    //     '<button class="btn btn-edit btn-smaller btn-rightNOspace" data-action="delete" data-el="'+params.value+'"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 128C391.2 128 384 135.2 384 144v288c0 26.47-21.53 48-48 48h-224C85.53 480 64 458.5 64 432v-288C64 135.2 56.84 128 48 128S32 135.2 32 144v288C32 476.1 67.89 512 112 512h224c44.11 0 80-35.89 80-80v-288C416 135.2 408.8 128 400 128zM432 64h-96l-33.63-44.75C293.4 7.125 279.1 0 264 0h-80C168.9 0 154.6 7.125 145.6 19.25L112 64h-96C7.201 64 0 71.2 0 80C0 88.8 7.201 96 16 96h416C440.8 96 448 88.8 448 80C448 71.2 440.8 64 432 64zM152 64l19.25-25.62C174.3 34.38 179 32 184 32h80c5 0 9.75 2.375 12.75 6.375L296 64H152zM148.7 363.3c6.242 6.246 16.37 6.254 22.62 0L224 310.6l52.69 52.69c6.242 6.246 16.37 6.254 22.62 0c6.25-6.25 6.25-16.38 0-22.62L246.6 288l52.69-52.69c6.25-6.25 6.25-16.38 0-22.62s-16.38-6.25-22.62 0L224 265.4L171.3 212.7c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L201.4 288l-52.69 52.69C142.4 346.9 142.4 357.1 148.7 363.3z"></path></svg> Delete</button>'+
        
    //    '</div>':'<div class="element-icon ant-table-cell">'+
    //    '<button class="btn btn-edit btn-smaller btn-rightNOspace hover-message disabled" disabled><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 128C391.2 128 384 135.2 384 144v288c0 26.47-21.53 48-48 48h-224C85.53 480 64 458.5 64 432v-288C64 135.2 56.84 128 48 128S32 135.2 32 144v288C32 476.1 67.89 512 112 512h224c44.11 0 80-35.89 80-80v-288C416 135.2 408.8 128 400 128zM432 64h-96l-33.63-44.75C293.4 7.125 279.1 0 264 0h-80C168.9 0 154.6 7.125 145.6 19.25L112 64h-96C7.201 64 0 71.2 0 80C0 88.8 7.201 96 16 96h416C440.8 96 448 88.8 448 80C448 71.2 440.8 64 432 64zM152 64l19.25-25.62C174.3 34.38 179 32 184 32h80c5 0 9.75 2.375 12.75 6.375L296 64H152zM148.7 363.3c6.242 6.246 16.37 6.254 22.62 0L224 310.6l52.69 52.69c6.242 6.246 16.37 6.254 22.62 0c6.25-6.25 6.25-16.38 0-22.62L246.6 288l52.69-52.69c6.25-6.25 6.25-16.38 0-22.62s-16.38-6.25-22.62 0L224 265.4L171.3 212.7c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L201.4 288l-52.69 52.69C142.4 346.9 142.4 357.1 148.7 363.3z"></path></svg> Delete<span class="messageTag">Acess Restricted</span></button>'+
    //   '</div>';
                          
         return eGui;
    }
    
    function HeaderEmpSubTab(){
        return (
            <Row className='subHeaderEmptTable' >
                <Col lg={6}>Location Name <span className='filter-icon'><ArrowDownUp/></span></Col>
                <Col lg={3}>Tag Seria <span className='filter-icon'><ArrowDownUp/></span></Col>
                <Col lg={3}>Action</Col>
            </Row>
        )
    }

    return(
        <div className='roleList' ref={elementRef} >
             <div className='br-seperator-row'>   
        <Row className='headerCategory headerGroup' >
             
            <Col lg={5} className="align-middle">
                {/*<span className='align-with-title' style={{width:"50px"}}><Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} /></span> */}
                <span className="filter-parent" onClick={()=>ChangeSorting('Category')}>Category
                <span className={sortKey=='Category'?'filter-icon active':'filter-icon'} >{sortKey=='Category'?sort=='desc'?<ArrowUp/>:<ArrowDown/>:<ArrowDownUp/>}</span></span>            
            </Col>
            <Col lg={3} className="align-middle" style={{textAlign:"center"}}>
            <span className="filter-parent" onClick={()=>ChangeSorting('tag_count')} >Location Count
                <span className={sortKey=='tag_count'?'filter-icon active':'filter-icon'}>{sortKey=='tag_count'?sort=='desc'?<ArrowUp/>:<ArrowDown/>:<ArrowDownUp/>}</span></span>
            </Col>
            <Col lg={4} className="align-middle siteManagementlast">Action</Col>
        </Row>    
        {loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>:ShowCardListLocation()}


</div>
        </div>
    )
    

}
export default CategoryList;