import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import ReactModal from 'react-modal';
import '../../styles/group.scss';
import { AutoComplete } from 'antd';
import { Select } from 'antd';
import '../../styles/addTeam.scss';
import {Checkbox, Button} from 'antd';
import { PropertyKeys } from 'ag-grid-community';
import Scrollbars from 'react-custom-scrollbars';
import { getLocationList } from '../../../sitemanagement/actionMethods/actionMethods';
import SucessModal from '../../../components/SucessModal';
import ErrorModal from '../../../components/ErrorModal';
import {ReactComponent as UPIcon} from '../../../assets/images/chevron-up.svg'
import {ReactComponent as HelpIcon} from  '../../../assets/images/circle-info.svg'
import LoaderSpinner from  '../../../assets/images/loader.svg'
import {ReactComponent as ArrowDown} from  '../../../assets/images/arrow-down-long.svg'
import {ReactComponent as ArrowUp} from '../../../assets/images/arrow-up-long.svg'
import {ReactComponent as ArrowDownUp} from '../../../assets/images/arrow-down-arrow-up.svg'
import {ReactComponent as CircleArrow} from '../../../assets/images/caret-right.svg';
import {ReactComponent as CircleArrowDown} from '../../../assets/images/caret-down.svg'
import { UpdateTeam } from '../../actionMethods/actionMethods';
import moment from 'moment';
import {getProductivityList} from '../../../productivemanagement/actionMethods/actionMethods'
import { getTeamDetail } from '../../actionMethods/actionMethods';

const { Option } = Select;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        maxHeight: '90%'
    },
	
};



function EditTeam(props) {
	const [historyEnable,SetHistoryEnable]= useState(false);
	const [name,SetName]=useState([])
	const [nameError,SetNameError]=useState([])
	const [loader,SetLoader]=useState(true);
	const [activeAccord,SetActiveAccord] =useState([]);
    const [listEmp,SetListEmp]=useState(props.emp)
	const [manualSelected,SetManualSelected] = useState([])
	const [listsub,Setlistsub] = useState(props.sub)
	
	const [selectedEmp,SetSelectedEmp] =useState([])
	const [selectedEmpError,SetSelectedEmpError] =useState('')
	const [deptSelected,SetDeptSelected]=useState([])
	const [subdeptSelected,SetSubDeptSelected]=useState([])
	const [teamLeaderID,SetTeamLeaderID]=useState('')
	const [teamLeaderError,SetTeamLeaderError]=useState('')

	const [title,SetTitle]=useState('')
	const [description,SetDescription]=useState('')
	const [modalSucess,SetModalSucess]=useState(false)
	const [modalError,SetModalError]=useState(false)
	const[teamData,SetTeamEditData]=useState([]);
	const [activeTab,SetActiveTab] =useState('regular');
	const [searchEmp,SetSearchEmp] =useState('');
	const [searchEmpList,SetSearchEmpList] =useState([]);
	const [searchEmpToggle,SetSearchEmpToggle] =useState(false);
	const [selectedEmp1,SetSelectedEmp1] =useState([])
	const [selectedEmpError1,SetSelectedEmpError1] =useState('')
	let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
	let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
	let org_id = userDetails.org_id?userDetails.org_id:""
	let sub_org_id = userDetails.sub_org_id?userDetails.sub_org_id:""


	useEffect(()=>{
		let requestBody={};
        requestBody.session=session;
        requestBody.org_id=org_id
        requestBody.team_id = props.teamID;
	
        getTeamDetail(requestBody).then(res=>{
            if(res.status==200){

				let deptlist = res.data && res.data[0]?JSON.parse(res.data[0].departments_list):{departments_list:[]}
				SetDeptSelected(deptlist.departments_list);

				let subdeptlist = res.data && res.data[0]?JSON.parse(res.data[0].sub_departments_list):{sub_departments_list:[]}
				SetSubDeptSelected(subdeptlist.sub_departments_list);

				let emplist = res.data && res.data[0]?JSON.parse(res.data[0].employees_list):{employees_list:[]}
				let allemp= props.emp.filter(item=> emplist.employees_list.indexOf(item.emp_id) > -1)

				SetSelectedEmp(allemp);

				SetTeamEditData(res.data)
				SetName(res.data && res.data[0]?res.data[0].team_name:'');
				SetTeamLeaderID(props.teamleaderID)
				
				SetLoader(false);
            }
        })
	},[])


function AccordionHandler(val,type='secondary'){
	let ActivList = [...activeAccord];
	let index = ActivList.indexOf(val)
	if(index !== -1){  
		ActivList.splice(index, 1);
		SetActiveAccord(ActivList);
	} 
	else if(type=='primary'){
		SetActiveAccord([val]);
	 }    
	else {  
		ActivList.push(val);
		SetActiveAccord(ActivList);
	}	
}


function onChangeEmployee(val,dept,subdept,sub){
	let cbox = [...deptSelected];
	let subcbox = [...subdeptSelected]
	if(val.target.checked ){
		let old= [...selectedEmp];
		old.push(sub)
		old = [...new Set(old)];
		SetSelectedEmp(old);
		let checkdept= old.filter(item=>item.dept_id==dept)
		let Allcheckdept= listsub.filter(item=>item.dept_id==dept)
		
		let checksubdept= old.filter(item=>item.sub_dept_id==subdept)
		let Allchecksubdept= listsub.filter(item=>item.sub_dept_id == subdept)
		
		if(checkdept.length==Allcheckdept.length){
			cbox.push(dept)
			SetDeptSelected(cbox)
		}
		if(checksubdept.length==Allchecksubdept.length){
			subcbox.push(subdept)
			SetSubDeptSelected(subcbox)
		}

	}else{
		let allemp = selectedEmp.length>0?selectedEmp:[]
		let index = allemp.findIndex((item)=>item.sub_dept_id == sub.sub_dept_id);
		if(index > -1){
			let all2 =allemp.splice(index, 1);
			SetSelectedEmp(allemp)
			let manual = allemp.map(item=> {return item.emp_id})
			SetManualSelected(manual)
	
		}
		if(subcbox.indexOf(subdept)> -1){
			//subcbox = subcbox.splice(subcbox.indexOf(subdept),1)		
			//delete subcbox[subcbox.indexOf(subdept)];	
			subcbox.splice(subcbox.indexOf(subdept),1)
			SetSubDeptSelected(subcbox);
		}
		
		if(cbox.indexOf(dept)> -1){
			//delete cbox[cbox.indexOf(dept)];	
			cbox.splice(cbox.indexOf(dept),1)		
			SetDeptSelected(cbox)
		}

	}


}
// function onChangeEmployee2(emp){
// 	let cbox = [...deptSelected];
// 	let subcbox = [...subdeptSelected]
// 	let allemp = selectedEmp.length>0?[...selectedEmp]:[]
// 	let index = allemp.findIndex((item)=>item.emp_id == emp.emp_id);
// 	let index2 = cbox.findIndex((item)=>item == emp.dept_id);
// 	let index3 = subcbox.findIndex((item)=>item == emp.sub_dept_id);

// 		if(index > -1){			
// 			allemp.splice(index,1);
// 		}
// 		SetSelectedEmp(allemp);
			
// 		if(index2 > -1){
// 			cbox.splice(index2,1)
// 			SetDeptSelected(cbox)

// 		}
// 		if(index3 > -1){
// 			subcbox.splice(index3,1);
// 			SetSubDeptSelected(subcbox);
// 		}	
// }
const onChangeEmployee3 = (emp) => {
    const updatedOptions = selectedOptions.filter((option) => option !== emp.user_id);
    setSelectedOptions(updatedOptions);

    const updatedEmployees = selectedEmp1.filter((employee) => employee.user_id !== emp.user_id);
    SetSelectedEmp1(updatedEmployees);
  };
const [selectedOptions, setSelectedOptions] = useState([]);

const onChangeDepartmentSelect1 = (value) => {
  setSelectedOptions(value);
  const selectedEmployees = props.teamleaderList.filter((element) =>
	value.includes(element.user_id,element.user_name)
  );
  SetSelectedEmp1(selectedEmployees);
};

function onChangeEmployee2(subDeptId){
	// console.log("sub",subDeptId)
	let cbox = [...deptSelected];
	let subcbox = [...subdeptSelected];
	let allemp = selectedEmp.length > 0 ? [...selectedEmp] : [];
	let index = allemp.findIndex((item) => item.sub_dept_id === subDeptId);
	let index2 = cbox.findIndex((item) => item.dept_id === allemp[index].dept_id);
	let index3 = subcbox.findIndex((item) => item === subDeptId);
  
	if(index > -1){      
	  allemp.splice(index, 1);
	  SetSelectedEmp(allemp);
	}
	
	if(index2 > -1){
	  cbox.splice(index2, 1);
	  SetDeptSelected(cbox);
	}
  
	if(index3 > -1){
	  subcbox.splice(index3, 1);
	  SetSubDeptSelected(subcbox);
	} 
  }
  function onChangeDepartmentSelect(val,dept,child){

	let cbox = [...deptSelected];
	let subcbox = [...subdeptSelected]
	

	if(val.target.checked ){
		SetDeptSelected([...deptSelected,dept])
		let selEmp= [...selectedEmp];
		//selEmp.push(...selectEmp)
		let sub = child
		SetSubDeptSelected([...subcbox,...child.map(item=>item.sub_dept_id)]);
		
		SetSelectedEmp((prev)=>[...prev,...child]);
		
	}else{
		let allemp = [...selectedEmp]
		let subdeptCreate=[];
		// let removeList =allemp.filter(item=>item.dept_id ==  dept);
		let removeList = child;
		for(let i=0;i<removeList.length;i++){
			if(subdeptCreate.indexOf(removeList[i].sub_dept_id) ===-1){
				subdeptCreate.push(removeList[i].sub_dept_id);
				
				if(subcbox.indexOf(removeList[i].sub_dept_id) > -1 ){
					subcbox.splice(subcbox.indexOf(removeList[i].sub_dept_id),1)
				}		
			}
		}
		SetSubDeptSelected(subcbox);

		if(cbox.indexOf(dept)!= -1 && cbox.length>0){
			cbox.splice(cbox.indexOf(dept),1)
			

			//removing all value if exist of department
			allemp = allemp.filter( function( el ) {
				if(removeList.findIndex((item)=>item.sub_dept_id == el.sub_dept_id )== -1){
					return el;
				}
			  });

			allemp = [...new Set(allemp)];
			let manual = allemp.map((item)=>{ if(item){return item.sub_dept_id} }); 
			SetManualSelected(manual);
			SetSelectedEmp(allemp);
			
			SetDeptSelected(cbox)
			
		}else{
	
			
			allemp = allemp.filter( function( el ) {
				if(removeList.findIndex((item)=>item.sub_dept_id == el.sub_dept_id )== -1){
					return el;
				}
			  });

			allemp = [...new Set(allemp)];
			let manual = allemp.map((item)=>{ if(item){return item.sub_dept_id} }); 
			SetManualSelected(manual);
			SetSelectedEmp(allemp);
			SetDeptSelected(cbox)
		}
	}


}




function onChangeSubDepartmentSelect(val,dept,subdept){
	let cbox = [...deptSelected];
	let subcbox = [...subdeptSelected]
	let manuallist = [...manualSelected]
	let allemp = [...selectedEmp]
	let beforeList=allemp; 
	if(val.target.checked){
		let sub= [...subdeptSelected,subdept]
		let dp=[...deptSelected]
		SetSubDeptSelected([...subdeptSelected,subdept])
		let selectEmp = [...selectedEmp] ;
		let newPush = listsub.filter(item=>item.sub_dept_id ==  subdept);
		if(newPush.length> 0){
			selectEmp.push(...newPush);
		}
		SetSelectedEmp(selectEmp)
		let manual = selectEmp.map((item)=>{return item.emp_id})
		let checkDept = selectEmp.filter(item=>item.dept_id==dept) ;
		let AllCheckDept = listEmp.filter(item=>item.dept_id==dept) ;
		if(AllCheckDept.length==checkDept.length){
			dp.push(dept)
			SetDeptSelected(dp)
		}
	    SetManualSelected(manual)
	}
	else{
		let allemp = [...selectedEmp]
		if(subcbox.indexOf(subdept) > -1 ){
			subcbox.splice(subcbox.indexOf(subdept),1)

			let removeList =allemp.filter(item=>item.sub_dept_id ==  subdept);
			//removing all value if exist of sub department
			allemp = allemp.filter( function( el ) {
				if(removeList.findIndex((item)=>item.sub_dept_id == el.sub_dept_id)== -1){
					return el;
				}
			  });

			  allemp = [...new Set(allemp)];

			  SetSelectedEmp(allemp)
			  let manual = allemp.map((item)=>{return item.emp_id})
			  SetManualSelected(manual)
			SetSubDeptSelected(subcbox);
		}else{
			let removeList =allemp.filter(item=>item.sub_dept_id ==  subdept);
			//removing all value if exist of sub department
			allemp = allemp.filter( function( el ) {
				if(removeList.findIndex((item)=>item.emp_id == el.emp_id )== -1){
					return el;
				}
			  });

			  allemp = [...new Set(allemp)];

			  SetSelectedEmp(allemp)
		}
		if(cbox.indexOf(dept)> -1){
			cbox.splice(cbox.indexOf(dept),1)
			SetDeptSelected(cbox)
	
		}
		//SetSelectedEmp(allemp);	
		//console.log("sub",subdeptSelected)
	}
}


function AddTeamHandler(){

	if(name==''){
		SetNameError('Field is Required')
	}
	
	if(selectedEmp1.length==0){
		SetSelectedEmpError1('Team Leader Selection is Required for Create Team')
	}
	if(selectedEmp.length==0){
		SetSelectedEmpError('Employee Selection is Required for Create Team')
	}
	if(name!=''&&selectedEmp.length> 0){
		SetNameError('')
		SetSelectedEmpError('')
		SetSelectedEmpError('')

		let requestBody={};
		requestBody.team_name = name
		requestBody.team_leader_user_id= {team_leader_user_id : selectedEmp1.map((item)=> {if(item){return item.user_id}})}
		requestBody.team_id= props.teamID
		requestBody.departments_list = {departments_list: [] }
		// requestBody.departments_list = {departments_list: deptSelected.map((item)=> {if(item){return item} }) }
		requestBody.sub_departments_list= {sub_departments_list : selectedEmp.map((item)=> {if(item){return item.sub_dept_id}})}
		// requestBody.sub_departments_list= {sub_departments_list : subdeptSelected.map((item)=> {if(item && item!=null){return item} })}
		requestBody.employees_list= {employees_list :[] }
		requestBody.org_id= org_id
		requestBody.session = userDetails.session
		requestBody.sub_org_id= sub_org_id
		UpdateTeam(requestBody).then(res=>{
			if(res.status==200){
				SetTitle(<h4>Team is updated</h4>)
				SetDescription(res.message)
				SetModalSucess(true);
			}else{
				SetTitle(<h4>{res.message}</h4>)
				SetDescription('Team update failed')
				SetModalError(true)
			}
		})
	}

	
}


function ShowGroupCardList(data){
	// console.log("data",data)
	let arr=[]
	if(data.length > 0){
		for(let i=0;i<data.length;i++){
			if (data[i].child) {
		arr.push( 
			listEmp.filter(item => item.dept_name.toString().includes(data[i].name)).length > 0?<Row className={activeAccord.indexOf(i+1)!==-1 ?'contentGroup active':'contentGroup'} key={'department'+data[i].name.toLowerCase()}>
		<div className='group-header'> 
		 <Col lg={10} className="align-middle">
			 {/* <span className='align-with-title' style={{width:"50px"}}><Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} /></span> */}
		   {listEmp.filter(item => item.dept_name.toString().includes(data[i].name)).length > 0
		   ? <span className='accordion-icon' onClick={()=>AccordionHandler(i+1,'primary')} >

			   {activeAccord.indexOf(i+1)!==-1?<CircleArrowDown/>:<CircleArrow />}     
			 </span>:''}
			 <span className='align-title-left'>
				{/* <div className={[...selectedEmp].filter((item)=>item.dept_id == data[i].dept_id).length > 0?'inline partialSelected':'inline' }>
					<Checkbox checked={deptSelected.indexOf(data[i].dept_id)> -1 || listEmp.filter(item=>item.dept_id == data[i].dept_id).length == [...selectedEmp].filter(item=>item.dept_id == data[i].dept_id).length  } onChange={(event)=>onChangeDepartmentSelect(event,data[i].dept_id)}  />
				</div> */}
				<div className={[...selectedEmp].filter((item)=>item.dept_id == data[i].dept_id).length > 0 || data[i].child.filter(item=> subdeptSelected.indexOf(item.sub_dept_id) > -1 ).length > 0?'inline partialSelected':'inline' }><Checkbox checked={deptSelected.indexOf(data[i].dept_id)> -1 || data[i].child.filter(item=> subdeptSelected.indexOf(item.sub_dept_id) > -1 ).length  == data[i].child.length  } onChange={(event)=>onChangeDepartmentSelect(event,data[i].dept_id,data[i].child)}  /></div>
				<div className='inline'><h4 className='textCap'>{data[i].name.toLowerCase()}</h4></div>
				 {/* <div className='sub-stats'><small>Active Sub Department : <strong>{data[i].child?data[i].child.length:0}</strong> Inactive Sub Department <strong>0</strong></small></div> */}
			 </span>
		 </Col>
		 <Col lg={2} className="align-middle" style={{textAlign:"center"}}>{listEmp.filter(item => item.dept_name.toString().includes(data[i].name)).length}</Col>
	
		 </div>
		 
		 {data[i].child?data[i].child.map(element2=>{
			 return(
				<Row className={activeAccord.indexOf(element2.sub_department)!==-1 ? 'contentGroup contentGroupSubCat active':'contentGroup contentGroupSubCat'} key={'SubDepart'+element2.sub_department}>      
					  <div className='group-header group-header-subCat'> 
			 <Col lg={10} className="align-middle">
				 <span className='align-with-title' style={{width:"10px"}}></span>
				 
				 {element2.data.length > 0 ?<span className='accordion-icon' onClick={()=>AccordionHandler(element2.sub_department)} >
				   {/* {activeAccord.indexOf(element2.sub_department)!==-1  ?<CircleArrowDown/>:<CircleArrow />}      */}
				 </span>:''}
				 <span className='align-title-left'>
				 <div className={[...selectedEmp].filter((item)=>item.sub_dept_id == element2.sub_dept_id).length > 0  ?'inline partialSelected':'inline' }>
					<Checkbox checked={deptSelected.indexOf(data[i].dept_id)> -1 || subdeptSelected.indexOf(element2.sub_dept_id)> -1 || listsub.filter(item=>item.sub_dept_id == element2.sub_dept_id).length == [...selectedEmp].filter(item=>item.sub_dept_id == element2.sub_dept_id).length}    onChange={(event)=>onChangeSubDepartmentSelect(event,data[i].dept_id,element2.sub_dept_id)} />
				</div>
				 <div className='inline'>
				 	<h4 >{element2.sub_department}</h4>
				 </div>
				</span>
			 </Col>
			 <Col lg={2} className="align-middle" style={{textAlign:"center"}}>{element2.data.length > 0 && element2.data[0].length > 0?element2.data[0].length:element2.data.length } </Col>
			 
			 </div>
			 <div  className={activeAccord.indexOf(element2.sub_department)!==-1?'group-content group-sub-content active':'group-content group-sub-content'}>
			
				{
					element2.data?element2.data.map(el=>{
						return(<div className='listEmpMap'>
								{/* <h4><Checkbox checked={selectedEmp.length > 0?selectedEmp.findIndex((item)=>item.emp_id ==el.emp_id ) > -1:false} onChange={(event)=>onChangeEmployee(event,data[i].dept_id,element2.sub_dept_id,el)} /> {el.emp_name}</h4> */}

						</div>)
					}):""
				}

				
			</div>    
			 </Row>)

		 }):data[i].data.length > 0 ?
			<div  className={activeAccord.indexOf(i+1)!==-1?'group-content group-sub-content group-sub-content-only-parent  active':'group-content group-sub-content'}>
		 {/* {AGData(data[i].name,data[i].data) } */}

		{data[i].data?data[i].data.map(el=>{
						return(<div className='listEmpMap'>
								{/* <h4><Checkbox checked={selectedEmp.length > 0?selectedEmp.findIndex((item)=>item.emp_id ==el.emp_id ) > -1:false} onChange={(event)=>onChangeEmployee(event,data[i].dept_id,el.sub_dept_id,el)} /> {el.emp_name}</h4> */}

						</div>)
					}):""

				}
		 </div>:""}



		 

	 </Row>:""



		)
		}
	}}else{
		arr.push( 
			<Row className='contentGroup nofound'>
				<div className='group-header'>
					<Col className='align-middle'>
						<h6 className='listNOFound' style={{marginBottom:"0px"}}>No Data found</h6>
					</Col>
				</div>
			</Row>
			) 
	}

	return arr;

}
// useEffect(()=>{
// 	if(searchEmp.length > 0){
// 		let list= [...listsub];
// 	let filter =	list.filter(item=>item.dept_name.toLowerCase().trim().includes(searchEmp.toLowerCase().trim()) || 
// 		 item.emp_name.toLowerCase().trim().includes(searchEmp.toLowerCase().trim())
// 		);
// 		SetSearchEmpList(filter);
// 		SetSearchEmpToggle(true)
// 	}

// },[searchEmp])
// function ShowGroupCardListEmployee(data){
	
// 	if(data.length>0){
// 		let arr=[];
// 		for(let i=0;i<data.length;i++){
// 			let item=data[i];
// 			for(let j=0;j<listsub.length;j++){
// 				let item1 = listsub[j]
// 				console.log(Number(item1.sub_dept_id),Number(item))
// 				if(Number(item) == Number(item1.sub_dept_id)){
// 				console.log("item",item)
			
// 				arr.push(<div className='employeee' key={"employeedTop"+item1.sub_department+i}><span>{item1.sub_department}</span> 
		
// 				<div className='removeEmp' onClick={()=>onChangeEmployee2(item)}>x</div></div>)
// 				}
// 			}
// 		}
// 		return arr;	
// 	}
// }

function ShowGroupCardListEmployee1(data){	
	if(data.length>0){
		let arr=[];
		for(let i=0;i<data.length;i++){
			let item=data[i];
			arr.push(<div className='employeee' key={"employeedTop"+item.user_id+i}><span>{item.user_name}</span> 
			<div className='removeEmp' onClick={()=>onChangeEmployee3(item)}>x</div></div>)
		}
		return arr;	
	}
}
// useEffect(() => {
// 	let employees_list = [...selectedEmp1];
// 	console.log("testing", props.teamleaderList1);
  
// 	if (props.teamleaderList1 && props.teamleaderList1.team_leader_id && props.teamleaderList1.team_leader_name) {
// 	  const leaderIds = props.teamleaderList1.team_leader_id;
// 	  const leaderNames = props.teamleaderList1.team_leader_name;
  
// 	  for (let i = 0; i < leaderIds.length; i++) {
// 		employees_list.push({
// 		  user_id: leaderIds[i],
// 		  user_name: leaderNames[i],
// 		});
// 	  }
// 	}
  
// 	SetSelectedEmp1(employees_list);
// 	setSelectedOptions(employees_list)
//   }, [props.teamleaderList1]);
useEffect(() => {
	let employees_list = [];
	
	if (props.teamleaderList1 && props.teamleaderList1.team && Array.isArray(props.teamleaderList1.team)) {
	  props.teamleaderList1.team.forEach(team => {
		if (team.team_leader_id && team.team_leader_name) {
		  employees_list.push({
			user_id: team.team_leader_id,
			user_name: team.team_leader_name,
		  });
		}
	  });
	}
	// setSelectedOptions(employees_list)
	SetSelectedEmp1(employees_list);
  }, [props.teamleaderList1]);
  

function ShowGroupCardListEmployee(data) {
	if (data.length > 0) {
	  let arr = [];
	  for (let i = 0; i < data.length; i++) {
		let item = data[i];
		for (let j = 0; j < listsub.length; j++) {
		  let item1 = listsub[j];
		  if (Number(item) === Number(item1.sub_dept_id)) {
			arr.push(
			  <div className="employeee" key={"employeedTop" + item1.sub_department + i}>
				<span>{item1.sub_department}</span>
				<div className="removeEmp" onClick={() => onChangeEmployee2(item)}>
				  x
				</div>
			  </div>
			);
			// Add the selected sub-department to the selectedEmp array if it doesn't already exist
			let subDeptIndex = selectedEmp.findIndex((el) => el.sub_dept_id === item1.sub_dept_id);
			if (subDeptIndex === -1) {
			  SetSelectedEmp(prevSelectedEmp => [
				...prevSelectedEmp,
				{
				  emp_id: '',
				  dept_id: item1.dept_id,
				  sub_dept_id: item1.sub_dept_id
				}
			  ]);
			}
		  }
		}
	  }
	  return arr;
	}
  }
  
  useEffect(() => {
	setSelectedOptions(props.teamleaderList1.team_leader_id);
  }, []);


useEffect(()=>{

	if(searchEmp.length > 0){
		let list= [...listsub];
	let filter =	list.filter(item=>item.sub_department.toLowerCase().trim().includes(searchEmp.toLowerCase().trim()) || 
		 item.sub_department.toLowerCase().trim().includes(searchEmp.toLowerCase().trim())
		);
		SetSearchEmpList(filter);
		SetSearchEmpToggle(true)
	}
},[searchEmp])


	return (<>
		<div className='policyTypeTeam'>
			<div className='headerAreaTeam'><h4>Edit Team</h4>
			<div className='SearchArea'>

<input type="placeholder"className='searchFieldheaderRight form-control' placeholder='Search Sub Department...' value={searchEmp} onChange={(e)=>SetSearchEmp(e.target.value)}/>

{searchEmpToggle?
<div className='Search'><div className='searchCLickCLose' onClick={()=>{SetSearchEmpToggle(false);SetSearchEmp('');}}></div>
	
	<div style={{zIndex:999,position:"relative"}}><Scrollbars style={{maxHeight:"200px",height:searchEmpList.length>0? searchEmpList.length *22:20}}>
{searchEmpList.length> 0? searchEmpList.map(item=>{
	return <div className='emplistSearch'> <Checkbox checked={selectedEmp.findIndex(el=> el.sub_dept_id==item.sub_dept_id) > -1} onChange={(event)=>onChangeEmployee(event,item.dept_id,item.sub_dept_id,item)}  />{item.sub_department}</div>
}):<div className='emplistSearch emplistSearchNoResult'>No Subdepartment found</div>}
	</Scrollbars></div></div>:""}

</div>	
			<button className='btn btn-close' onClick={()=>props.handleCloseModal()}>x</button></div>
			{loader?<div style={{textAlign:"center"}}> <img src={LoaderSpinner} /></div>:
			<><div className='bodyAreaTeam'>
			<Row>
				<Col lg={7}>

					<div className='form-field'style={{marginBottom:"10%"}}>
					<div className='leftDiv50'>
						<label>Team Name <span className='required'>*</span></label>
						<input type="text" className='form-control border-single' value={name} onChange={(e)=>SetName(e.target.value)}/>
						{nameError!=''?<div className='error'>{nameError}</div>:""}
					</div>
					<div className='leftDiv50'>
						<label>Select Team Leader <span className='required'>*</span></label>
						{/* <Select
							placeholder="Select User"
							optionFilterProp="children"
							onChange={(val)=>SetTeamLeaderID(val)}
							className={'form-control'}
							showSearch
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={teamLeaderID}

							//defaultValue={'Resarch & Development'}
						>								
								{props.teamleaderList.map(element=>{
									return (<Option value={element.user_id} key={element.user_name+"sub"+element.user_id}>{element.user_name.toLowerCase()}</Option>)

								})}	
								</Select>
							{teamLeaderError!=''?<div className='error'>{teamLeaderError}</div>:""} */}
							 <Select	
									placeholder="Select User"
									optionFilterProp="children"
									onChange={onChangeDepartmentSelect1}
									className={'form-control'}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									showSearch
									value={selectedOptions}
									 mode="multiple"
									 
									 style={{ maxHeight: '200px', overflowY: 'auto' }}
									>
									{props.teamleaderList.map((element) => (
										<Option key={element.user_id} value={element.user_id}>
										{element.user_name.toLowerCase()}
										</Option>
									))}
    							</Select>
					</div>	
					</div>
							{/* {userDetails.org_type === "oem" ? (
			<div className='field-row'>
			<label>Select Sub Organisation</label>
			<Select
				placeholder="Select Sub Organisation"
				optionFilterProp="children"
				// onChange={(val,label)=>{setWorkerType(val);}}
				// className={workerType==''?'form-control has-error':'form-control '}
				// filterOption={(input, option) =>
				// option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
				// }
				// defaultValue={props.productiveIDData.type}
				// value={workerType}
				// key={'lcoationNameMapping'}
			>
				<Option value={"regular_worker"} key={"regular_workerdrope"}>
				{"Sensocore"}
				</Option>
				<Option value={"daily_worker"} key={"regular_workerdrope"}>
				{"traceplus"}
				</Option>
			</Select>
			</div>
		) :("")} */}
					<div className='form-field'>
					<div className='divHead'><div className={activeTab=='regular'?'listHead active':'listHead'} onClick={()=>SetActiveTab('regular') }>Regular Worker</div> <div className={activeTab=='daily'?'listHead active':'listHead disabled'} >Daily Worker</div> </div>
						<Scrollbars style={{height:"300px"}}>
						{activeTab =='regular'?ShowGroupCardList(props.GroupListData):ShowGroupCardList(props.GroupListData)}
						</Scrollbars>
					</div>
				</Col>

				<Col lg={5}>
				<div className='listSelectedEMp' style={{background:"#f9f4f4",height:"200px",overflowY: "scroll"}}>
					{selectedEmpError1!=''?<div className='error'>{selectedEmpError1}</div>:""}
					<h6 style={{ textAlign: "center",fontSize:10, color: "#505a5f" }}>Selected Team Leaders</h6>
					
					{ShowGroupCardListEmployee1(selectedEmp1)}
						
					</div>
					<hr/>
					<div className='listSelectedEMp' style={{background:"#f9f4f4",height:"200px",overflowY: "scroll"}}>
					{selectedEmpError!=''?<div className='error'>{selectedEmpError}</div>:""}
					<h6 style={{ textAlign: "center",fontSize:10, color: "#505a5f" }}>Selected Subdepartments</h6>
					<Scrollbars style={{height:"200px"}}>
					<div>{ShowGroupCardListEmployee(subdeptSelected)}</div>
						{/* {subdeptSelected.map((item,index)=>{
							return <div className='employeee' key={"employeed"+item.sub_department+index}><span>{(listsub.filter(item1 =>item1.sub_dept_id == item)).sub_department}</span><div className='removeEmp' onClick={()=>onChangeEmployee2(item)}>x</div></div>
						})} */}
					</Scrollbars>	
					</div>
					
				
				</Col>
			</Row>
			</div>
			<div className='footerAreaTeam'>
				<button type="submit" className='btn btn-submit' style={{width:"200px"}} onClick={AddTeamHandler}> Update Team</button>
			</div></>}
		</div>
		<SucessModal modalSucess={modalSucess} handleCloseModalSucess={()=>{SetModalSucess(false);props.Reload();props.handleCloseModal();}} title={title} description={description} />
			<ErrorModal modalError={modalError} handleCloseModalError={()=>SetModalError(false)}   title={title} description={description}  />
	</>)
    
    
}
export default EditTeam;