import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import ReactModal from 'react-modal';
import '../../styles/group.scss';
import { editDepartment } from '../../actionMethods/actionMethods';
import { ToastContainer, toast } from 'react-toastify';
import LoaderSpinner from '../../../assets/images/loader.svg'
import SucessModal from '../../../components/SucessModal';
import ErrorModal from '../../../components/ErrorModal';
import {ReactComponent as HelpIcon} from '../../../assets/images/circle-info.svg'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        maxHeight: '90%'
    },
};



function EditGroup(props) {

    const [group_id,SetGroupID]=useState('');  
    const [group_idError,SetGroupIDError]=useState('');  
    const [group_name,SetGroupName]=useState('');  
    const [group_nameError,SetGroupNameError]=useState('');
    const [loader,SetLoader]=useState(false)
    const reg = /^\S.*$/;
    const [modalSucess,SetModalSucess] = useState(false);
    const [modalError,SetModalError] = useState(false); 
    const [title,SetTitle] =useState('');
    const [description,SetDescription] =useState('');


    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
    let org_id = userDetails.org_id?userDetails.org_id:""
  

    function handleCloseModalSucess(){
		props.Reload();
        props.handleCloseModal()
        SetModalSucess(false)
	  }
	  function handleCloseModalError(){
		SetModalError(false)
	  }
  
    
    useEffect(()=>{
        SetGroupID(props.group_id)
        SetGroupName(props.group_name)
    },[])
  
    function SubmitHandlerUpdate(){
		if(group_name==''){	
            SetGroupNameError('Department name is required.')
        }
        else if(group_name.length > 255){
			SetGroupNameError('Department Name length should be less than 255 character');
		}
        else{
            let requestBody={}
            requestBody.session=session
            requestBody.org_id=org_id
            requestBody.department_name=group_name
            requestBody.department_id=group_id
            SetLoader(true);
            editDepartment(requestBody).then(res=>{
                SetLoader(false)
                if(res.status==200){
                    SetTitle(<h4>Department is Updated</h4>)
                    SetDescription('');
                    SetModalSucess(true)
                }else{
                    SetTitle(<h4>{res.message}</h4>)
                    SetDescription('Department not Updated');
                    SetModalError(true)
                }
                if(res.status==300){
                    localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                    localStorage.removeItem('userLoginDetailsTraceAdmin')
                    props.history.push(`/login`)
                }
            })
        }
    }
    return(
        <div>

{loader?<div className='loaderContainer loaderContainerSmall'><img src={LoaderSpinner}/></div>:
        <div><div className='modalHeader'>
                <h4>Edit Department</h4>
                <div className='closeMark' onClick={()=>props.handleCloseModal()}>x</div>
            </div>
            <div className='modalBody'>
                <div className='field-row'>
                    <label>Department Name* <div className='posRel'><HelpIcon /><span className='helpSmall'>Max length 255 character</span></div></label>
                    <input type="text" className='form-control' value={group_name} onChange={(e)=>{if(reg.test(e.target.value)){SetGroupName(e.target.value);}else if(e.target.value==''){SetGroupName(e.target.value)} }  } / >
                    {group_nameError!=''?<div className='errorMessage'>{group_nameError}</div>:""}
                </div>
                <button className='btn btn-primary btn-100' onClick={SubmitHandlerUpdate}>Update Department</button>    
            </div>
    </div>}

    <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
		<ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />
    </div>

    )
}
export default EditGroup;