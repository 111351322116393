import React, { useState, useEffect } from 'react'
import { CommonHeading } from '../../common/commonHeading';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';

import '../../assets/styles/common.scss'


import moment from 'moment'
import 'antd/dist/antd.css';
import spinnerLoader from '../../assets/images/loader.svg'
import { logoutUser } from '../../login/actionMethods/actionMethods';          
import axios from 'axios';

import { getRoleDetail } from '../../rolemanagement/actionMethods/actionMethods';
import { getCategoryList } from '../../sitemanagement/actionMethods/actionMethods';
import LoaderSpinner from  '../../assets/images/loader.svg'

import ExcelTemplate from '../../assets/working_data_template.xlsm'
import {ReactComponent as HelpIcon} from  '../../assets/images/circle-info.svg'

import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import Loader from '../../assets/images/loader.svg'
import '../styles/styles.scss'
import DatePicker from "react-datepicker";
import { read, utils, writeFile } from 'xlsx';
import {getHolidayList,createHoliday,editHoliday} from  '../actionMethods/actionMethods';
import CycleWarningModal from '../../components/CycleWarningModal';

import { Checkbox } from 'antd';
import Scrollbars from 'react-custom-scrollbars';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
// import excel from 'exceljs';
import ExcelJs from "exceljs";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxWidth:'90%',
        maxHeight: '90%'
    },
};

function Holiday(props) {

    

  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
  let sub_org_id =userDetails.sub_org_id?userDetails.sub_org_id:""
  const [file,setFile] =useState('')
   


    const [selectedLocation,SetSelectedLocation]=useState([])

    const [permission,SetPermission]=useState([])
    const [permissionAPICall,SetPermissionAPICall]=useState(false);
    
    const [inc,SetInc]=useState(0);
    const [loader,Setloader]= useState(true);
    const [ title,SetTitle] =useState('')
    const [description,SetDescription]=useState('')
    const [modalSucess,SetModalSucess]= useState(false)
    const [modalError,SetModalError] = useState(false)
    const [loaderImport,SetLoaderImport] =useState(false);
    const [loaderExport,SetLoaderExport] =useState(false);
    const [locationList,SetLocationList] =useState([]);
    const [modalWarning,SetModalWarning] = useState(false);
    const [apiType,SetAPIType] =useState('update')
    const [facility_count_id , Setfacility_count_id] =useState("");
    const [holidayList,SetHolidayList] =useState([{name:"",date:null,created:false}]);
    const [weekDays,SetWeekDays]= useState({sunday:false,monday:true,tuesday:true,wednesday:true,thursday:true,friday:true,saturday:true})
    const [createAPI,SetCreateAPI] = useState(true);
    const [holidayID,SetHolidayID] =useState('');
    const [submitEnable,SetSubmitEnable] =useState(true);

    useEffect(() => {
        if (props.match.path == '/') {
            props.history.push('/dashboard');
        }
        let requestBody={}
        requestBody.session=session
        requestBody.org_id=userDetails.org_id
        requestBody.role_id =userDetails.role;
        getRoleDetail(requestBody).then(res=>{
            if(res.status==200 || res.code==200){
                SetPermission(JSON.parse(res.data[0].dashboard))
                Setloader(false);
                SetPermissionAPICall(true)
            }
            if(res.status==300){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)        
            }
        }).catch();
        
    }, []);

    useEffect(()=>{
        let requestBody={
            session:userDetails.session,
            org_id:userDetails.org_id,
            sub_org_id:userDetails.sub_org_id
        }
    getHolidayList(requestBody).then(res=>{
        if(res.status==200){

            if(res.data.length > 0){
                let weekFormat = JSON.parse(res.data[0].weekdays).weekdays;
                const outputWeekObject = weekFormat.reduce((result, item) => {
                const key = Object.keys(item)[0]; // Extract the key (day) from the object
                result[key] = item[key] === 'yes'; // Assign boolean value based on the string
                return result;
                }, {});
                let holdidayData=JSON.parse(res.data[0].holidays).holidays;
                const outputHolidayArray = []
                for(let i=0;i<holdidayData.length;i++){
                    let item = holdidayData[i]
                    const date = Object.keys(item)[0];
                    const dateformat = moment(Object.keys(item)[0]).toDate(); // Get the date from the object key
                    const name = item[date]; // Get the name from the object value
                   // console.log("dateformat",dateformat)
                    if(!moment(Object.keys(item)[0]).isValid()){
                        
                    }else{
                        outputHolidayArray.push({ name:name, date:dateformat,created:true });
                    }
                }
                // holdidayData.map(item => {
                    
                //     return { name:name, date:dateformat,created:true };
                // });
                SetHolidayID(res.data[0].id)
                SetHolidayList(outputHolidayArray);
                SetWeekDays(outputWeekObject);
                SetSubmitEnable(false);
                            SetCreateAPI(false);

            }


            
                




            




        }

})


    },[])


    function handleCloseModalSucess(){
      SetModalSucess(false);
      if(createAPI){
        window.location.reload();
      }
      
    }

    function handleCloseModalError(){
      
      SetModalError(false);
    }



    async function handleFileUpload(file){
 
        let arry = file.name.split('.');
        let lastElement = arry[arry.length - 1];
        // SetDailyWorkerEnable(false);           
        if(lastElement=='xls' || lastElement=='xlsx' || lastElement=='xlsm' ){    
            let emp=[];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const rows = utils.sheet_to_csv(wb.Sheets[sheets[0]]);

                   //console.log(parseDataHandler(rows));

                }
            }
            reader.readAsArrayBuffer(file);            
        }else{
            SetTitle(<h4>Invalid File</h4>)
            SetModalError(true);
        }
}

function parseDataHandler(rows){
    let loc = [...locationList]
    let arr=[];
    let dataExist=[];
          const data = rows.split("\n");
          let exitLoop=false;
          const weekdays = {};
const holidays = [];
       // console.log("parse data",data);
        let section = ""; // Keep track of whether we're processing "Working Days" or "Holiday"  
        data.forEach(item => {
  if (item === "Working Days,") {
    section = "Working Days";
  } else if (item === "Holiday,") {
    section = "Holiday";
  } else if (item && section) {
    const [dateStr, name] = item.split(",");
    if (section === "Working Days") {
      weekdays[dateStr.toLowerCase().trim()] = name === "Yes";
    } else if (section === "Holiday") {
      holidays.push({ date: moment(dateStr, 'M/D/YY').toDate(), name ,created:false});
    }
  }
});
//console.log("weekdays",weekdays)
SetWeekDays(weekdays);
SetHolidayList(holidays);

// SetTitle(<h4>Please Submit for import</h4>)
// SetModalWarning(true);
//console.log("Weekdays:", weekdays);
//console.log("Holidays:", holidays);



    return arr

}

//   const handleFileUpload = async (event) => {
//     const file = event.target.files[0];
//     console.log("files",file);
//     const workbook = new ExcelJs.Workbook();

// var workbook2 = new ExcelJs.Workbook(); 
// workbook2.xlsx.readFile(file)
//     .then(function() {
//         var worksheet = workbook.getWorksheet('Sheet1');
//         worksheet.eachRow({ includeEmpty: true }, function(row, rowNumber) {
//           console.log("Row " + rowNumber + " = " + JSON.stringify(row.values));
//         });
//     });


//     await workbook.xlsx.readFile(file);
//     const worksheet = workbook.getWorksheet(1); // Assuming you want to process the first sheet
//     const workingDaysRow = 2; // Assuming working day information starts from row 2
//     const holidaysArray = [];

//     const workingDaysObject = {};

//     worksheet.eachRow((row, rowNumber) => {
//         console.log(row);
//       if (rowNumber === workingDaysRow) {
//         row.eachCell((cell, colNumber) => {
//           workingDaysObject[cell.value.toLowerCase()] = true;
//         });
//       } else if (rowNumber > workingDaysRow) {
//         const dateCell = row.getCell(1);
//         const nameCell = row.getCell(2);

//         if (dateCell.value && nameCell.value) {
//           const formattedDate = moment(dateCell.value).format('YYYY-MM-DD');
//           const holidayObject = {
//             name: nameCell.value,
//             date: formattedDate,
//           };
//           holidaysArray.push(holidayObject);
//         }
//       }
//     });

//     console.log('Working Days Object:', workingDaysObject);
//     console.log('Holidays Array:', holidaysArray);
//   };



    
  function slugify(string) {  
    return string.toString().toLowerCase().replace(/[^a-zA-Z0-9]/g,'_');
  }

  function PlusEventHoliday(){
    let list =[...holidayList]
    list.push({name:"",date:"",created:false})
    SetHolidayList(list);
  }

  function removeHoliday(index){
    let list =[...holidayList];
    if(list.length> 1){
        
        if(list[index].created ===true){
        list.splice(index, 1);         
        let ls =[];
        for(let i=0;i<list.length;i++){
            if(list[i].name && moment(list[i].date).isValid()){
                ls.push(list[i]);
            }
        }
        let requestBody={
                "holiday" :ls.map(item=> ({ [moment(item.date).format('YYYY-MM-DD')]:item.name }) ),
                "weekday" : Object.keys(weekDays).map(key => ({
                                [key]: weekDays[key] ? 'yes' : 'no'
                            })),
                "org_id" : userDetails.org_id,
                "sub_org_id" : userDetails.sub_org_id,
                "session" : userDetails.session
        }
       requestBody.holiday_id = holidayID;
        editHoliday(requestBody).then(res=>{
            if(res.status==200){
                
                SetHolidayList(list);
                SetTitle(<h4>Holiday is removed</h4>);
                SetModalSucess(true);
            }else{
                SetTitle(<h4>Fail to remove</h4>);
                SetModalError(true);
            }
        })




        }else{
            list.splice(index, 1);
            SetHolidayList(list);
        }


    
    }
 

  }

  function SetChangeWeekDay(e,day){
    //console.log(e)
    if(e.target.checked){
        SetWeekDays((prev) => ({ ...prev, [`${day}`]: true }));
    }else{
        SetWeekDays((prev) => ({ ...prev, [`${day}`]: false }));
    }
    SetSubmitEnable(true);

  }
  function setDatePickerVal(date, index) {
 // console.log("Before update:", holidayList);
  let updatedList = [...holidayList];
  updatedList[index].date = date;
  updatedList[index].created = false;
  //console.log("After update:", updatedList);
  SetHolidayList(updatedList);
}
 const handleDateChange = (date, index) => {
    const updatedList = [...holidayList];
    updatedList[index].date = date;
      updatedList[index].created = false;
    SetHolidayList(updatedList);
  };

  function SetHolidayHandler(val,index){
    const updatedList = [...holidayList];
    updatedList[index].name = val;
    SetHolidayList(updatedList);
  }
  const addNewHoliday = () => {
    SetHolidayList([...holidayList, null]);
  };


  function submitHandler(){

    let error = false;
    let allHoliday=[...holidayList]
    for(let i=0;i<allHoliday.length;i++){
        if(allHoliday[i].name=="" || allHoliday[i].date =="" || !moment(allHoliday[i].date).isValid()){
            error="true";
        }
    }

    if(error){  
        toast.error("Holiday Name and Date is requird Please check all field");
    }else{


        let requestBody={
                "holiday" :holidayList.map(item=> ({ [moment(item.date).format('YYYY-MM-DD')]:item.name }) ),
                "weekday" : Object.keys(weekDays).map(key => ({
                                [key]: weekDays[key] ? 'yes' : 'no'
                            })),
                "org_id" : userDetails.org_id,
                "sub_org_id" : userDetails.sub_org_id,
                "session" : userDetails.session
        }
    
      if(createAPI){
        createHoliday(requestBody).then(res=>{
            if(res.status==200){
                SetTitle(<h4>Holiday List Created</h4>);
                SetModalSucess(true);
                
            }
        });

      }else{
        requestBody.holiday_id = holidayID;
        editHoliday(requestBody).then(res=>{
            if(res.status==200){
                let holdidayformat=[]
                for(let i=0;i<holidayList.length;i++){
                    let form = holidayList[i]
                    form.created =true;
                    holdidayformat.push(form)
                }
                SetHolidayList(holdidayformat)
                SetTitle(<h4>Holiday is updated</h4>);
                SetModalSucess(true);
            }else{
                SetTitle(<h4>Fail to update</h4>);
                SetModalError(true);
            }
        })

      }  

        
    }
    

  }



    return (

         <div className="main-content side-content pt-0">

<div className="container-fluid">
    <div className="inner-body">

        {loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>: 
        <> 
         {permissionAPICall && permission.View=='True'?<>
        {/* <div className="page-header">
                    <Row>
                
                        <Col lg={4}>
                        </Col>
                        <Col lg={2}></Col>
                        <Col lg={6} className="header-btn">
                            
                            
                        </Col>
                    
                    </Row>
                </div>  */}
<div className='selectArea page-header'>
                        <Row style={{margin:"0px"}}>
                            <Col lg={7} style={{borderRight:"1px solid #ddd"}}>
                                <h6>Week Days</h6>
                                <div className='weeklist'>
                                    <Checkbox onChange={(e)=>SetChangeWeekDay(e,'sunday')} checked={weekDays.sunday}> Sunday</Checkbox>
                                    <Checkbox onChange={(e)=>SetChangeWeekDay(e,'monday')} checked={weekDays.monday}> Monday</Checkbox>
                                    <Checkbox onChange={(e)=>SetChangeWeekDay(e,'tuesday')} checked={weekDays.tuesday}> Tuesday</Checkbox>
                                    <Checkbox onChange={(e)=>SetChangeWeekDay(e,'wednesday')} checked={weekDays.wednesday}> Wednesday</Checkbox>
                                    <Checkbox onChange={(e)=>SetChangeWeekDay(e,'thursday')} checked={weekDays.thursday}> Thursday</Checkbox>
                                    <Checkbox onChange={(e)=>SetChangeWeekDay(e,'friday')} checked={weekDays.friday}> Friday</Checkbox>
                                    <Checkbox onChange={(e)=>SetChangeWeekDay(e,'saturday')} checked={weekDays.saturday}> Saturday</Checkbox>
                                </div>
                            </Col>
                            <Col lg={3} style={{borderRight:"1px solid #ddd"}}><h6>Week-Off Days</h6>
                                 <div className='weeklist'><strong>{Object.keys(weekDays).filter(day => !weekDays[day]).length > 0?Object.keys(weekDays).filter(day => !weekDays[day]).map(day => {
                                    
  return (<div class="weekdays" style={{marginRight:"10px",textTransform:'capitalize'}}>{day}</div>)
}):"No Week-Off"}</strong></div>
                            </Col>
                            <Col><input type="file" style={{display:"none"}} id="importfile" onChange={(e)=>handleFileUpload(e.target.files[0])}/><label for="importfile" style={{marginTop:"5px",float:"right"}} className='btn btn-submit btn-transparent'>Import</label></Col>
                        </Row> 

</div>

                 <div>

                    <div className="br-seperator-row">
                        <Row style={{margin:"0px"}}>
                            <Col lg={5} className='headerAreaCol'>
                                <h4>Name</h4>
                            </Col>
                            <Col lg={5} className='headerAreaCol'>
                                <h4>Date</h4>
                            </Col>
                            <Col lg={2} className='headerAreaCol'>
                                <h4 style={{textAlign:"center",paddingRight:"10px"}}>Action</h4>
                            </Col>
                        </Row>
                       <div className='bodyList'>
                            {
                            holidayList.map((item,index)=>{
                                //console.log(item,index)
                             
                               return (<Row style={{margin:"0px"}} key={"datePicker"+index}>
                                    <Col lg={5} className='bodyAreaCol' >
                                        <input type="text" placeholder='holiday name' className='holidayName form-control' value={item.name} onChange={(e)=>SetHolidayHandler(e.target.value,index)}/></Col>
                                    <Col lg={5} className='bodyAreaCol'>
                                         <DatePicker
                                         className='holidayDate form-control'
                                            selected={item.date}
                                            placeholder="holiday Date"
                                            dateFormat={'MMM dd yyyy'}
                                            onChange={(newDate) => handleDateChange(newDate, index)}
                                        />
                                         </Col>
                                    <Col lg={2} className='bodyAreaCol' style={{textAlign:"center"}}>
                                            <button onClick={()=>removeHoliday(index)} className='btn-add btn-delete'>Delete</button>
                                            {holidayList.length - 1 == index?<button className='btn-add btn-abs-small' onClick={()=>PlusEventHoliday()}>+</button>:""}
                                            </Col>

                            </Row> ) 
                            })
                            
                            
                            
                            }


                        </div>
                     <div className='field-row' style={{margin:"20px 0px 40px",textAlign:'right'}}>
                
                         {holidayList.filter(item=>item.created===false).length> 0 || submitEnable ? <button type="submit" onClick={submitHandler} className='btn btn-submit btn-transparent'>Submit</button>:''}
                    </div>




                    </div>

                 </div>


    
       
            </>:"" }
 
                {permissionAPICall && permission.View=='False'?<Col lg={12}><div className='errorPermission'>Access Restricted to this page, Please contact administrator</div></Col>:''}

    
        </>}


    </div>
</div>

        <CycleWarningModal modalWarning={modalWarning} handleDeleteConfirm={()=>SetModalWarning(false)} handleCloseModalWarning={()=>SetModalWarning(false)} title={title} />
            <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description}  key={'dashboardSucess'}/>
				<ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  key={'dashboardError'} />

</div>
              )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(Holiday))
