import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import ReactModal from 'react-modal';
import '../../styles/Category.scss';
import   '../../../usermanagement/styles/addEmployee.scss';
import {editLocation} from '../../actionMethods/actionMethods'
// import { AutoComplete } from 'antd';
import { Select,Button ,Steps  } from 'antd';
import SucessModal from '../../../components/SucessModal';
import ErrorModal from '../../../components/ErrorModal';
import LoaderSpinner from '../../../assets/images/loader.svg'
import { getAvailableTag } from '../../../usermanagement/actionMethods/actionMethods';

import {ReactComponent as HelpIcon} from '../../../assets/images/circle-info.svg'

const { Option } = Select;
function onChange(value) {

}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        maxHeight: '90%'
    },
};


function EditLocation(props) {
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
  let org_id = userDetails.org_id?userDetails.org_id:""

    const [loader,SetLoader]=useState(true);
    const [value, setValue] = useState('');
    const [name,SetName]=useState(props.locationDetail &&  props.locationDetail.length > 0?props.locationDetail[0].location_name:'');
    const [nameError,SetNameError]=useState('');
    const [description,SetDescription]=useState('');
    const [descriptionError,SetDescriptionError]=useState('');
    const [category_id,SetCategoryID] = useState(props.locationDetail &&  props.locationDetail.length > 0?props.locationDetail[0].category_name:'');
    const [category_idError,SetCategoryIDError] = useState('');
    const [capacity,SetCapacity] =useState(props.locationDetail &&  props.locationDetail.length > 0?parseInt(props.locationDetail[0].capacity):'');
    const [capacityError,SetCapacityError] =useState('');
    const [area_size,SetAreaSize] =useState(props.locationDetail &&  props.locationDetail.length > 0?props.locationDetail[0].area_size:'');
    const [area_sizeError,SetAreaSizeError] =useState('');
    const [loc_type,SetLocType] =useState(1);
    const [loc_typeError,SetLocTypeError] =useState('');
    const [type,SetType] =useState('location')
    const [availTag,SetAvailTag] =useState([]);
    const [tag_serial,SetTagSerial]=useState(props.locationDetail &&  props.locationDetail.length > 0?props.locationDetail[0].tag_serial:'');
    const [tagSerialError,SetTagSerialError] =useState('')
    const existing_tag_serial = props.locationDetail &&  props.locationDetail.length > 0?props.locationDetail[0].tag_serial:'';
    const reg = /^\S.*$/;
    //modal thing 
    const [title,SetTitle] =useState('');
    const [descriptionMessage,SetDescriptionMessage]= useState('')
    const [modalSucess,SetModalSucess] =useState(false);
    const [modalError,SetModalError] = useState(false);
    /*----*/ 

    const { Step } = Steps;
    const [current, setCurrent] = React.useState(0);

    const [location_id,SetLocationID] =useState(props.locationDetail &&  props.locationDetail.length > 0?props.locationDetail[0].location_id:'')
    const [dropdownOpt,SetDropDownOpt]=useState(props.dataCategory?props.dataCategory:[])
    const [locationDetail,SetLocationDetail]= useState(props.locationDetail &&  props.locationDetail.length > 0?props.locationDetail:[])  


    const [locationType,SetlocationType]=useState([]);
    

    useEffect(()=>{
      if(props.locationDetail.length > 0){

        SetName(props.locationDetail[0].location_name);
        SetCategoryID(props.locationDetail[0].category_id)
        SetTagSerial(props.locationDetail[0].tag_serial)
        SetDescription(props.locationDetail[0].description)
        SetCapacity(props.locationDetail[0].capacity)
        SetAreaSize(props.locationDetail[0].area_size)
        SetLocationID(props.locationDetail[0].location_id)        
        setTimeout(function(){
          SetLoader(false)
        },500)
    
      }else{
        setTimeout(function(){
          SetLoader(false)
        },1000)
        
      } 
      
    },[props.locationDetail])
    useEffect(()=>{
      
      SetlocationType(props.locationType)
      },[props.locationType])
  
    useEffect(()=>{
      let requestBody={}
      requestBody.session=session
      requestBody.tag_type='location'
      requestBody.org_id= org_id
      getAvailableTag(requestBody).then(res=>{
       if(res.status==201 || res.status==200){
            SetAvailTag(res.data)
       }
        
      })
    },[])

    
    function handleCloseModalSucess(){
      SetModalSucess(false)
      props.handleCloseModal()
      props.Reload();
    }
    function handleCloseModalError(){
      SetModalError(false)
      props.handleCloseModal()
    }
  
      const onSelect = (data) => {

      };
      
      const onChange = (data) => {
        setValue(data);
      };
      
      const onChangeCat=(data)=>{
        SetCategoryID(data);
      }
      
      const onChangeProductive = (data)=>{
        SetLocType(data);
      };

      function onChangeSelect(value) {


      }

      function onChangeStep(step){
      
        if(current==0){

        }else{
          setCurrent(step);
        }
      
      }

        function AddLocationHandler(){


          if(name==''){
            SetNameError('Location Name is required') 
          }
          if(name.length > 255){
            SetNameError('Location Name length should be less than 255 character') 
          }
            if(category_id==''){
              SetCategoryIDError('invalid')
            }

            if(description==''){
              SetDescriptionError('Description is required');
            }
            if(description.length > 255){
              SetDescriptionError('Description length should be less than 255 character')
            }
            if(capacity==''){
              SetCapacityError('Capacity is required')
            }
            else if(!capacity.toString().match(/^\d+/)){
              SetCapacityError('Only Numeric Value allowed  && value should in range 0.1 - 999999')
            }
            else if(capacity.toString().includes('.')){
              SetCapacityError("Float Value doesn't allow")
            }
            else if(capacity< 1 || capacity > 999999 ){
              SetCapacityError('Capacity should be greater than 1 and  less than 999999')
            }
            else if(capacity.toString().length>= 6){
              SetCapacityError('Capacity should be less than 999999')
            }else{
              SetCapacityError('')
            }

            // if(area_size==''){
            //   SetAreaSizeError('Area Size is required')
            // }else if(!area_size.toString().match(/^\d+/)){
            //   SetAreaSizeError('Only Numeric Value allowed  && value should in range of 0.1 - 999999')
            // }else if(area_size<= 0.1 && area_size>= 999999 && area_size === "0"){
            //   SetAreaSizeError('Area should be greater than 0.1 sq.m   and  less than 999999 sq.m')
            // }else if(area_size.toString().length >= 6){
            //   SetAreaSizeError('Area Size should be less than 999999 sq.m')
            // }
            if (area_size === '') {
              SetAreaSizeError('Area Size is required');
            } else if (!area_size.toString().match(/^\d+$/)) {
              SetAreaSizeError('Only Numeric Value allowed && value should be in the range of 0.1 - 999999');
            } else if (area_size <= 0.1 || area_size >= 999999 || area_size === "0") {
              SetAreaSizeError('Area should be greater than 0.1 sq.m and less than 999999 sq.m and not equal to 0');
            } else if (area_size.toString().length >= 6) {
              SetAreaSizeError('Area Size should be less than 999999 sq.m');
            } else {
              // Clear any existing error message if all conditions pass
              SetAreaSizeError('');
            }
            if(loc_type==''){
              SetLocType('Location Type is required')
            }
            if(tag_serial==''){
              SetTagSerialError('Tag Serial is required');
            }

            if(name!=''&& name.length <= 255 && category_id!==''&&description!=''&& description.length <=255  && capacity!='' && !capacity.toString().includes('.') && capacity.toString().length<=  6 && capacity >= 1 && capacity <= 999999    && area_size!=''&& area_size >= 0.1 && area_size <= 999999 && area_size.toString().length<=6  && loc_type!=''){
                  SetNameError('') 
                  SetCategoryIDError('')
                  SetDescriptionError('')
                  SetCapacityError('')
                  SetAreaSizeError('')
                  SetLocTypeError('')
                  SetTagSerialError('') 
                  let requestBody={}
                  requestBody.name=name
                  requestBody.location_id=location_id.toString()
                  requestBody.new_tag_serial =tag_serial.toString()
                  requestBody.existing_tag_serial = existing_tag_serial.toString();
                  requestBody.description=description?description.toString():''
                  requestBody.org_id= org_id.toString()
                  requestBody.category_id=category_id.toString()
                  requestBody.capacity=capacity.toString()
                  requestBody.area_size=area_size.toString()
                  requestBody.session=session

                  editLocation(requestBody).then(res=>{
                
                    if(res.status==200 || res.code==200){
                      SetTitle(<h4>Location is updated</h4>);
                      SetDescriptionMessage('')
                      SetModalSucess(true)
                    }else{
                      SetTitle(<h4> location is not updated</h4>);
                      SetDescriptionMessage(<>{res.message}</>)
                      SetModalError(true)
                    }
                  })

            }


        }

        function onChangeTag(tag){
          SetTagSerial(tag);
        }
    return(
        <div>
            <div className='modalHeader modalHeader'>
                    <h4>Edit Location</h4>
                    <div className='closeMark' onClick={()=>props.handleCloseModal()}>x</div>
                </div>
              {loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>
              :<div className='modalBody modalBody-AddLocation'>

                  
				<div className="steps-content step-user-info">
					<Row>
          <Col lg={6}>
                  
                  <div className='field-row'>
                      <label>Location Name <div className='posRel'><HelpIcon /><span className='helpSmall'>Max length 255 character</span></div> </label>
                      
                      <input type="text" placeholder="Location Name " className={nameError!=''?'form-control has-error':'form-control'} value={name}  onChange={(e)=>{if(reg.test(e.target.value)){SetName(e.target.value);}else if(e.target.value==''){SetName(e.target.value)} }  }/>           

                  </div>
              </Col>  
              <Col lg={6}>
                    <div className='field-row'>
                        <label>Assign to Category <div className='posRel'><HelpIcon /><span className='helpSmall'>Select Category name for this location</span></div></label>
             {category_id? <Select
                placeholder="Select Category"
                optionFilterProp="children"
                onChange={onChangeCat}
                filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                className={category_idError!=''?'form-control has-error':'form-control'}
                defaultValue={category_id}
            >
              {dropdownOpt.map((element)=>{
                return (

                  <Option value={element.id}>{element.name.toLowerCase()}</Option>
                    )
              })}
            </Select>:<Select
                placeholder="Select Category"
                optionFilterProp="children"
                onChange={onChangeCat}
                filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                className={category_idError!=''?'form-control has-error':'form-control'}
                defaultValue={props.locationDetail?props.locationDetail[0].category_id:category_id}
            >
              {dropdownOpt.map((element)=>{
                return (

                  <Option value={element.id}>{element.name.toLowerCase()}</Option>
                    )
              })}
            </Select>}
            </div>

                    </Col>
                    <Col lg={6}>
                    <div className='field-row'>                    
                        <label>Select Tag <strong>{ props.locationDetail && props.locationDetail[0].tag_serial?  props.locationDetail[0].tag_serial:""}</strong><div className='posRel'><HelpIcon /> <span className='helpSmall'>Select new tag only if want to change</span></div> </label> 
                  <Select
                    placeholder="Select Tag Serial"
                    optionFilterProp="children"
                    onChange={onChangeTag}
                    className={tagSerialError!=''?'form-control has-error':'form-control'}
                    showSearch
                    defaultValue={tag_serial}>
                      
                      {/* {availTag.map((item)=>{
                        {console.log("reload",item[1])}
                      return <Option value={item[1]} key={item[1]}>{item[1]}</Option>
                    })} */}
                    {availTag.map((item) => {
  console.log("reload", item.serial_num);
  return (
    <Option value={item.serial_num} key={item.serial_num}>
      {item.serial_num}
    </Option>
  );
})}

               </Select>
            </div>

                    </Col>
           <Col lg={6}>
							<div className='field-row'>
									<label>Description<div className='posRel'><HelpIcon /> <span className='helpSmall'>Max length 255 character</span></div></label>
                  
									<input type="text"  className={descriptionError!=''?'form-control has-error':'form-control'} value={description}  onChange={(e)=>{if(reg.test(e.target.value)){SetDescription(e.target.value);}else if(e.target.value==''){SetDescription(e.target.value)} }  }  />
                  {descriptionError!=''?<div className='helpSmallError'>{descriptionError}</div>:""} 
							</div>	
						</Col>
            <Col lg={6}>
        <div className='field-row'>
          <label>Capacity (no of employee) <div className='posRel'><HelpIcon /> <span className='helpSmall'>Min 1 and Max 999999</span></div></label> 
          
              <input type="text" className={capacityError!=''?'form-control has-error':'form-control'} value={capacity}  onChange={(e)=>{if(reg.test(e.target.value)){SetCapacity(e.target.value);}else if(e.target.value==''){SetCapacity(e.target.value)} }  } />
              {capacityError!=''?<div className='helpSmallError'>{capacityError}</div>:""} 
                </div>
        </Col>
        <Col lg={6}>
  <div className='field-row'>
    <label>Area Size (Sq.m)<div className='posRel'><HelpIcon /> <span className='helpSmall'>Min 0.1 and Max 999999 sq.m</span></div></label> 
        <input type="text" className={area_sizeError!=''?'form-control has-error':'form-control'} value={area_size}  onChange={(e)=>{if(reg.test(e.target.value)){SetAreaSize(e.target.value);SetAreaSizeError("")}else if(e.target.value==''){SetAreaSize(e.target.value)} }  } />
        { area_sizeError!=''?<div className='helpSmallError'>{area_sizeError}</div>:""} 
        </div>
  </Col>
  {/* <Col lg={6}>
 
  <div className='field-row'>
    <label>Location type<div className='posRel'><HelpIcon /><span className='helpSmall'>Location Type for this current location (eg productive,non productive)</span></div></label> 
      <Select
                showSearch
                placeholder="Select Type"
                optionFilterProp="children"
                onChange={onChangeProductive}
                filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                 defaultValue={loc_type}
                className={loc_typeError!=''?'form-control has-error':'form-control'}
            >
        {locationType.map((item)=>{
                return <Option value={item.id} key={"locationType"+item.id}>{item.loc_type}</Option>
              })}
				    </Select>
        </div>
  </Col> */}
					</Row>						

				</div>
  <div className="steps-action">
					<Button type="primary" onClick={ AddLocationHandler} className={'btn btn-primary'} >
						Update Location
					</Button>
				</div>
        

                
                </div>}
                <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={descriptionMessage} />
		            <ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={descriptionMessage}  />

        </div>
    )
}
export default EditLocation;