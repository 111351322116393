import axios from "axios";
let prefixURL = process.env.REACT_APP_V2

let token = 'ezrsrdfghawtsetrrAZSXCxfa'

export function CreteGeoMap(requestBody) {
    
    return axios.post(prefixURL + `/location/create_geo_map` , requestBody).then(res=>res.data).catch(err=>err)
}

export function getLocationCordinate(requestBody) {
    return axios.get(prefixURL + `/location/get_location_cordinate?session=${requestBody.session}&org_id=${requestBody.org_id}`).then(res=>res.data).catch(err=>err)
}


export function CreteGeoMapLocation(requestBody) {
    
    return axios.post(prefixURL + `/location/create_geo_map_location` , requestBody).then(res=>res.data).catch(err=>err)
}

export function removeMapLocation(requestBody){
    return axios.post(prefixURL + `/location/remove_geo_map_location` , requestBody).then(res=>res.data).catch(err=>err)
}