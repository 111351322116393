import React, { useState, useEffect,useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import '../style/styles.scss';
import moment from 'moment'
import { useHistory } from 'react-router-dom';

import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getShifttList } from '../../usermanagement/actionMethods/actionMethods';
import LoaderSpinner from '../../assets/images/loader.svg'



function ShiftList(props){

    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
    let org_id = userDetails.org_id?userDetails.org_id:""
    const [shiftList,SetShiftList] =useState([]);
    const [shiftListAll,SetShiftListAll] =useState([]);
    const [WidthContainer,setWidthContainer] = useState(0);
    const elementRef = useRef(null);   
    const [loader,Setloader] =useState(true);
    useEffect(() => {
        setWidthContainer(elementRef.current.getBoundingClientRect().width);
       
    }, [elementRef]);
   

    useEffect(()=>{
        let requestBody={}
        requestBody.session=session;
        requestBody.org_id= org_id

        getShifttList(requestBody).then(res=>{
            if(res.status==200 || res.code==200 ){
            
                SetShiftList(res.data);
                SetShiftListAll(res.data);
                Setloader(false);
            }
        }) 
    },[props.permission,props.inc])

    useEffect(()=>{
        if(props.shiftList){
            let data = [...shiftListAll];
            let filter = data.filter( item=> item.shift_name.toString().toLowerCase().includes(props.searchShift.toString().toLowerCase()) )
            
            SetShiftList(filter)
        }
    },[props.searchShift])


    function editHandlerAlert(data){

        props.updateModalHandlerOpen(data);
    }

    function deleteHandlerAler(data){

    }
    function EdiButtonRender(params){
        
        var op =document.createElement('div');

        var eGui = document.createElement('div');
      
        eGui.addEventListener('click', event => {
          if(event.target.getAttribute("data-action")=='edit'){
            editHandlerAlert(params.data)
          }else if(event.target.getAttribute("data-action")=='delete'){
            deleteHandlerAler(params.data);
          }
        });
        eGui.innerHTML=`<div class="element-icon ant-table-cell">
                                <button class="btn btn-edit btn-smaller btn-icon bt-perm-${props.permission.edit} ${props.permission && props.permission.edit=='True'?'acessEnable':'hover-message disabled'}" data-action="edit" data-el="${params.value}" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M432 320c-8.836 0-16 7.164-16 16V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h112C184.8 96 192 88.84 192 80S184.8 64 176 64H63.1C28.65 64 0 92.65 0 128v320c0 35.35 28.65 64 63.1 64h319.1c35.35 0 63.1-28.65 63.1-64L448 336C448 327.2 440.8 320 432 320zM497.9 42.19l-28.13-28.14c-9.373-9.373-21.66-14.06-33.94-14.06s-24.57 4.688-33.94 14.06L162.4 253.6C153.5 262.5 147.4 273.9 144.9 286.3l-16.66 83.35c-1.516 7.584 4.378 14.36 11.72 14.36c.7852 0 1.586-.0764 2.399-.2385l83.35-16.67c12.39-2.479 23.77-8.566 32.7-17.5l239.5-239.5C516.7 91.33 516.7 60.94 497.9 42.19zM235.8 326.1c-4.48 4.48-10.13 7.506-16.35 8.748l-53.93 10.79L176.3 292.6c1.244-6.219 4.27-11.88 8.754-16.36l178.3-178.3l50.76 50.76L235.8 326.1zM475.3 87.45l-38.62 38.62l-50.76-50.76l38.62-38.62c4.076-4.076 8.838-4.686 11.31-4.686s7.236 .6094 11.31 4.686l28.13 28.14C479.4 68.9 480 73.66 480 76.14C480 78.61 479.4 83.37 475.3 87.45z"></path></svg> Edit <span class='messageTag'>Access Restricted</span></button>
                                <button class="btn btn-edit btn-smaller btn-icon bt-perm-${props.permission.remove} ${props.permission && props.permission.remove=='True'?'acessEnable':'hover-message disabled'}" data-action="delete" data-el="${params.value}"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 128C391.2 128 384 135.2 384 144v288c0 26.47-21.53 48-48 48h-224C85.53 480 64 458.5 64 432v-288C64 135.2 56.84 128 48 128S32 135.2 32 144v288C32 476.1 67.89 512 112 512h224c44.11 0 80-35.89 80-80v-288C416 135.2 408.8 128 400 128zM432 64h-96l-33.63-44.75C293.4 7.125 279.1 0 264 0h-80C168.9 0 154.6 7.125 145.6 19.25L112 64h-96C7.201 64 0 71.2 0 80C0 88.8 7.201 96 16 96h416C440.8 96 448 88.8 448 80C448 71.2 440.8 64 432 64zM152 64l19.25-25.62C174.3 34.38 179 32 184 32h80c5 0 9.75 2.375 12.75 6.375L296 64H152zM148.7 363.3c6.242 6.246 16.37 6.254 22.62 0L224 310.6l52.69 52.69c6.242 6.246 16.37 6.254 22.62 0c6.25-6.25 6.25-16.38 0-22.62L246.6 288l52.69-52.69c6.25-6.25 6.25-16.38 0-22.62s-16.38-6.25-22.62 0L224 265.4L171.3 212.7c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L201.4 288l-52.69 52.69C142.4 346.9 142.4 357.1 148.7 363.3z"></path></svg> Delete <span class='messageTag'>Access Restricted</span></button>
                            </div>`;

         return eGui;
    }

  
    function showCardProductiveList(data){
      let arr = <div className="ag-theme-alpine" style={{height: (60 + ( 40 * (shiftList.length>0?shiftList.length:2) + 1 ) ), width: '100%'}}>
        <AgGridReact
            rowData={data}
            defaultColDef={{
                             sortable: true,
                             resizable: true,
                             //flex:1,
                             width: ( (WidthContainer - 205)/3 ),   
                             height:38,
                             headerHeight:38
                         }}
              key={'shiftList'}           
         >
             <AgGridColumn 
                 field="shift_name"
                 headerName={"Shift Name"}
             ></AgGridColumn>
              <AgGridColumn 
                 field="start_time"
                 headerName={"Start Time"}
             ></AgGridColumn>
              <AgGridColumn 
                 field="end_time"
                 headerName={"End Time"}
             ></AgGridColumn>
            <AgGridColumn 
                 field="id"
                 headerName={"Action"}
                 width={200}
                 cellRenderer= {(params)=>EdiButtonRender(params)}
             ></AgGridColumn>
        </AgGridReact>
         </div>                
return arr;

    } 
    return(<div ref={elementRef}> {loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>:showCardProductiveList(shiftList)}</div>)
}


const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(ShiftList))
