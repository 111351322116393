import React, { useState, useEffect,useRef } from 'react'
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';
import  '../styles/style.scss';
import moment from 'moment';
import {Checkbox, Divider,AutoComplete  } from 'antd';
import {ReactComponent as ArrowDown} from '../../assets/images/arrow-down-long.svg'
import {ReactComponent as ArrowUp} from '../../assets/images/arrow-up-long.svg'
import {ReactComponent as ArrowDownUp} from '../../assets/images/arrow-down-arrow-up.svg'
import {ReactComponent as CircleArrow} from '../../assets/images/caret-right.svg';
import {ReactComponent as CircleArrowDown} from '../../assets/images/caret-down.svg'
import {ReactComponent as EditIcon} from '../../assets/images/pen-to-square.svg'
import {ReactComponent as DeleteIcon} from '../../assets/images/trash-can-xmark.svg'
import {ReactComponent as AddIcon} from '../../assets/images/square-plus.svg'
import {ReactComponent as EyeIcon} from '../../assets/images/eye.svg'
import { Select } from 'antd';
import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {getRoleList,removeRole} from '../actionMethods/actionMethods'
import LoaderSpinner from '../../assets/images/loader.svg'


const { Option } = Select;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '90%'
    },
};

function RoleList(props){
    const defaultCheckedList = ['Apple', 'Orange'];
    const plainOptions = ['Apple', 'Pear', 'Orange'];

    const [checkedList, setCheckedList] = React.useState(defaultCheckedList);
    const [indeterminate, setIndeterminate] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);    
    const [activeAccord,SetActiveAccord] =useState([]);
    const [modalEdit,SetModalEdit]=useState(false);
    const[WidthContainer,setWidthContainer]=useState('');
    const [sort,SetSort]=useState('desc');
    const[sortKey,SetSortKey] =useState('');  
    const [roleListData,SetRoleListData] =useState([]);
    const [roleListDefaultData,SetRoleListDefaultData] =useState([]);
    const [loader,SetLoader]=useState(true);
    const elementRef = useRef(null);   
    const [searchRole,SetSearchRole]=useState(props.searchRole);
    const [permission,SetPermission]=useState(props.permission)
    const [apiCall,SetAPICall]=useState(false)
    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';

    useEffect(() => {
        setWidthContainer(elementRef.current.getBoundingClientRect().width);
 
    }, [elementRef]);
    useEffect(()=>{

        SetPermission(props.permission);
    },[props.permission])



    useEffect(()=>{
        let requestBody={}
        requestBody.session=userDetails.session
        requestBody.org_id=userDetails.org_id

        getRoleList(requestBody).then(res=>{
            if(res.status==200){
                let data = res.data;
                let index2 = 1;
                let dataFormat =[];
                 
                Object.keys(data).map(function(key){
                    
                    dataFormat.push({id:data[key].role_id,role_name:key,active:data[key].active,inactive:data[key].inactive,total:data[key]['total users'],data:data[key]['total employees list']})
                    index2++;
                });
                //console.log("Api Response",data,dataFormat)
                SetRoleListData(dataFormat);
                SetRoleListDefaultData(dataFormat)
                SetAPICall(true)
                    SetLoader(false)
            }else{
                SetLoader(false)
            }
            if(res.status==300){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)
            }

        }).catch();

    },[props.inc])

    useEffect(()=>{
        let data = [...roleListDefaultData];

        let arr=  data.filter((item) => {
                     return item.role_name.toString().toLowerCase().includes(props.searchRole.toLowerCase());
                    })
        SetRoleListData(arr);           


    },[props.searchRole])
    useEffect(() => {
        let data = [...roleListDefaultData]
        let arr=[];
        if(sortKey=='role'){
            arr = data.sort((a, b) => {
                a = a.role_name.toUpperCase()
                b = b.role_name.toUpperCase()
                return sort === 'desc' ? (a == b ? 0 : b > a ? 1 : -1) : (a == b ? 0 : a > b ? 1 : -1)
            })
        }
        if(sortKey=='tag_count'){
            arr = data.sort(function (x, y) {
                return sort === 'desc' ?y.total - x.total:x.total-y.total;
            });
        }
        SetRoleListData(arr);

    },[sort,sortKey])
    const onChange = list => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
      };
    
      const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
      };

    


    function ChangeSorting(key){
        SetSort( sort=='desc'?'asc':'desc');
        SetSortKey(key);
    }
    function AccordionHandler(val,type='secondary'){
        
        let ActivList = [...activeAccord];
        let index = ActivList.indexOf(val)
        if(index !== -1){  
            ActivList.splice(index, 1);
            SetActiveAccord(ActivList);
        } 
        else if(type=='primary'){
            SetActiveAccord([val]);
     }  
      else {  
            ActivList.push(val);
            SetActiveAccord(ActivList);
        }
        

    }


    function editHandlerAlert(id){
        SetModalEdit(true);
    }

function ShowCardListRole(data){
    let arr= []


    let index = 1;
    if(data.length >0 ){
    for(let i=0;i<data.length; i++){
        //data[key].reported_time
        arr.push(
            <Row key={'contentGroup'+i} className={activeAccord.indexOf(i)!==-1?'contentGroup active':'contentGroup'}>
            <div className='group-header'> 
             <Col lg={6} className="align-middle">
                 {/* <span className='align-with-title' style={{width:"50px"}}><Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} /></span> */}
                 {data[i].data.length>0?<span className='accordion-icon' onClick={()=>AccordionHandler(i,'primary')} >
                   {activeAccord.indexOf(i)!==-1?<CircleArrowDown/>:<CircleArrow />}   
                 </span>:""}
                 <span className='align-title-left'>
                 <h4 className='textCap' >{data[i].role_name.toLowerCase()}</h4>
                 <div className='sub-stats'><small>Active : <strong>{data[i].active}</strong> Inactive <strong>{data[i].inactive}</strong></small></div></span>
             </Col>
             <Col lg={3} className="align-middle roledataCount" style={{textAlign:"center"}}>{data[i].total}</Col>
             <Col lg={3} className="align-middle">
                {permission && permission.View=='True'?<button className='btn btn-smaller btn-icon' onClick={()=>{props.history.push(`/role-detail/${data[i].id}`);props.ToggleProfile();}}><EyeIcon/><span>View</span></button>:
                 <button className='btn btn-smaller btn-icon disablled hover-message' disabled ><EyeIcon/><span>View</span><span className='messageTag'>Access Restricted</span></button> }
              / 
              {permission && permission.edit=='True'?<button className='btn btn-smaller btn-icon' onClick={()=>props.history.push(`/edit-role/${data[i].id}`)}><EditIcon/><span> Edit</span></button>:
              <button className='btn btn-smaller btn-icon disabled hover-message'><EditIcon/><span> Edit</span><span className='messageTag'>Access Restricted</span></button>} 
              / 
              {permission && permission.remove=='True'?<button className='btn btn-smaller btn-icon btn-delete' onClick={()=>props.modalDeleteModal(data[i].role_name,data[i].id)}><DeleteIcon/><span>Delete</span></button>:<button className='btn btn-smaller btn-icon disabled hover-message btn-delete' disabled><DeleteIcon/><span>Delete</span><span className='messageTag'>Access Restricted</span></button>}
                            </Col>
             </div>            
               {data[i].data.length>0?
               <div className={activeAccord.indexOf(i)!==-1?'group-content active':'group-content'} >
                    <div className="ag-theme-alpine" style={{height: (60 + ( 40 * data[i].data.length + 1 ) ), width: '100%'}}>
           <AgGridReact
               rowData={data[i].data}
               defaultColDef={{
                                sortable: true,
                                resizable: true,
                                flex:1,
                                width: ( WidthContainer - 200)/3,   
                                height:38,
                                headerHeight:38
                            }}
            >
                <AgGridColumn 
                    field="name"
                    headerName={"Employee Name"}
                    
                ></AgGridColumn>
                                <AgGridColumn 
                    field="email"
                    headerName={"Email"}
                    
                ></AgGridColumn>
                <AgGridColumn 
                    field="tag serial number"
                    headerName={"Tag Serial"}
                    
                ></AgGridColumn>
                {/* <AgGridColumn 
                    field="id"
                    headerName={"Action"}
                    cellRenderer= {(params)=>EdiButtonRender(params)}
                    width={150}
                ></AgGridColumn> */}
           </AgGridReact>
       </div>                 
             </div>
             :''}
         </Row>
        )
index++;
            }
        }else if(apiCall===false){
            
        }
        else{
           arr.push( 
                    <Row className='contentGroup nofound' key={'contentGroup nofound'}>
                        <div className='group-header'>
                            <Col className='align-middle'>
                                <h6 className='listNOFound' style={{marginBottom:"0px"}}>No Data found</h6>
                            </Col>
                        </div>
                    </Row>
                    ) 
        }
    return arr;
}

    function EdiButtonRender(params){
        

        var op =document.createElement('div');

        var eGui = document.createElement('div');
      
        eGui.addEventListener('click', event => {
          if(event.target.getAttribute("data-action")=='edit'){
            editHandlerAlert(event.target.getAttribute("data-el"))
          }else if(event.target.getAttribute("data-action")=='delete'){
            
          }
        });
      
        var eGui2 = document.createElement('div');
      
         eGui.innerHTML= '<div class="element-icon ant-table-cell">'+
                            '<button class="btn btn-edit btn-smaller btn-icon" data-action="edit" data-el="'+params.value+'"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M432 320c-8.836 0-16 7.164-16 16V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h112C184.8 96 192 88.84 192 80S184.8 64 176 64H63.1C28.65 64 0 92.65 0 128v320c0 35.35 28.65 64 63.1 64h319.1c35.35 0 63.1-28.65 63.1-64L448 336C448 327.2 440.8 320 432 320zM497.9 42.19l-28.13-28.14c-9.373-9.373-21.66-14.06-33.94-14.06s-24.57 4.688-33.94 14.06L162.4 253.6C153.5 262.5 147.4 273.9 144.9 286.3l-16.66 83.35c-1.516 7.584 4.378 14.36 11.72 14.36c.7852 0 1.586-.0764 2.399-.2385l83.35-16.67c12.39-2.479 23.77-8.566 32.7-17.5l239.5-239.5C516.7 91.33 516.7 60.94 497.9 42.19zM235.8 326.1c-4.48 4.48-10.13 7.506-16.35 8.748l-53.93 10.79L176.3 292.6c1.244-6.219 4.27-11.88 8.754-16.36l178.3-178.3l50.76 50.76L235.8 326.1zM475.3 87.45l-38.62 38.62l-50.76-50.76l38.62-38.62c4.076-4.076 8.838-4.686 11.31-4.686s7.236 .6094 11.31 4.686l28.13 28.14C479.4 68.9 480 73.66 480 76.14C480 78.61 479.4 83.37 475.3 87.45z"></path></svg> Edit</button>'+
                           '</div>';
      
         return eGui;



    }
    
    

    return(

        <div className='roleList'>
            <div className='br-seperator-row'>                
        <Row className='headerGroup' ref={elementRef}>
            <Col lg={6} className="align-middle">
                {/* <span className='align-with-title' style={{width:"50px"}}><Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} /></span> */}
                <span className="filter-parent" onClick={()=>ChangeSorting('role')}>Role Name
                <span className={sortKey=='role'?'filter-icon active':'filter-icon'} >{sortKey=='role'?sort=='desc'?<ArrowUp/>:<ArrowDown/>:<ArrowDownUp/>}</span></span>            
            </Col>
            <Col lg={3} className="align-middle" style={{textAlign:"center"}}>
            <span className="filter-parent" onClick={()=>ChangeSorting('tag_count')} >Employee count
                <span className={sortKey=='tag_count'?'filter-icon active':'filter-icon'}>{sortKey=='tag_count'?sort=='desc'?<ArrowUp/>:<ArrowDown/>:<ArrowDownUp/>}</span></span>
            </Col>
            <Col lg={3} className="align-middle role-management-act">Action</Col>
        </Row> 
        {loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>:
        
        ShowCardListRole(roleListData)}
            

        </div>
        </div>
    )
    

}
export default RoleList;