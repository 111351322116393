import React, { useState, useEffect,useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import '../style/styles.scss'
import { useHistory } from 'react-router-dom'
import ProductivityList from './employeeList'
import {getRoleDetail} from '../../rolemanagement/actionMethods/actionMethods'
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import { getlocationType } from '../../sitemanagement/actionMethods/actionMethods';
import ExcelJs from "exceljs";
import {ReactComponent as PlusIcon} from '../../assets/images/plus.svg'
import {getClientDBList} from '../actionMethods/actionMethods'
import LoaderSpinner from '../../assets/images/loader.svg'
import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';



const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        maxHeight: '90%'
    },
	
};

function ClientDB(props) {
  let history = useHistory();
  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
  let org_id = userDetails.org_id?userDetails.org_id:"";

  const [permission,SetPermission]=useState([]);
  const [emplyeeList,SetemplyeeList] =useState([]);
  const [permissionAPICall,SetPermissionAPICall] =useState(false);
  const [modalSucess,SetModalSucess] = useState(false);
  const [modalError,SetModalError]= useState(false);
  const [title,SetTitle]= useState('');
  const [description,SetDescription]=useState('');
  const [productiveIDData,SetProductiveIDData]=useState([]);
  const [emplyeeListAll,SetemplyeeListAll] =useState([]);
  const [WidthContainer,setWidthContainer] = useState(0);
  const elementRef = useRef(null);   
  const [loader,Setloader] =useState(true);
  
  const [modalCreate,SetModalCreate]=useState(false);
  const [modalUpdate,SetModalUpdate]=useState(false);
  const [modalDelete,SetModalDelete] = useState(false);
  const [modalWarning,SetModalWarning]= useState(false);
  const [ID,SetID]=useState('');
  const [searchEmployee,SetsearchEmployee] =useState('');
  
  const [locationType,SetLocationType]=useState([]);

  const [inc,SetInc]=useState(1)

    useEffect(() => {
        if (props.match.path == '/') {
            props.history.push('/dashboard')
        }
        let requestBody={}
        requestBody.session=session;
        requestBody.org_id= org_id
        requestBody.role_id = userDetails.role;
        getRoleDetail(requestBody).then(res=>{
            if(res.status==200 || res.code==200){
                SetPermission(JSON.parse(res.data[0].admin_productive_permissions));
                SetPermissionAPICall(true)
            }
            if(res.status==300){
              localStorage.removeItem('isLoggedInGatewayTraceAdmin')
              localStorage.removeItem('userLoginDetailsTraceAdmin')
              props.history.push(`/login`)        
          }
        }).catch()
        getlocationType(requestBody).then(res=>{
            if(res.status==200){
                SetLocationType(res.data);
            }   
        })


    }, []);

    function Reload(){
        SetInc((prev)=>prev+1);
    }
    
    useEffect(()=>{
        if (permission) {
            getClientDBList(session, org_id).then(res => {
              if (res.status === 200) {
                let filteredList = res.data;
                console.log("filtered data",filteredList)
                SetemplyeeList(filteredList);
                 SetemplyeeListAll(res.data);
                Setloader(false);
              }
            });}
          },[permission,inc])
    

          const exportToExcel = () => {
            const workbook = new ExcelJs.Workbook();
            const worksheet = workbook.addWorksheet("Employee Data");
          
            // Add headers to the worksheet with styling
            const headerRow = worksheet.addRow(["Name", "Worker ID", "Site", "Agency", "Worker Type"]);
          
            headerRow.eachCell((cell) => {
              cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF16B95' } }; // Blue background color
              cell.font = { bold: true, color: { argb: 'FFFFFF' } }; // White font color
              cell.alignment = { horizontal: 'center', vertical: 'middle' }; // Center align text vertically and horizontally
              cell.border = {
                top: { style: 'thin', color: { argb: '000000' } }, // Black top border
                bottom: { style: 'thin', color: { argb: '000000' } }, // Black bottom border
                left: { style: 'thin', color: { argb: '000000' } }, // Black left border
                right: { style: 'thin', color: { argb: '000000' } } // Black right border
              };
            });
          
            // Add data to the worksheet
            emplyeeList.forEach(employee => {
              const row = worksheet.addRow([
                employee.name,
                employee.worker_id, // Convert to number
                employee.site,
                employee.agency,
                employee.worker_type
              ]);
          
              // Apply styling to data rows
              row.alignment = { vertical: 'middle' }; // Center align text vertically
              row.border = {
                top: { style: 'thin', color: { argb: '000000' } }, // Black top border
                bottom: { style: 'thin', color: { argb: '000000' } }, // Black bottom border
                left: { style: 'thin', color: { argb: '000000' } }, // Black left border
                right: { style: 'thin', color: { argb: '000000' } } // Black right border
              };
            });
          
            // Set column widths based on content
            worksheet.columns.forEach((column, index) => {
              let maxWidth = 0;
              column.eachCell({ includeEmpty: true }, (cell) => {
                const cellWidth = cell.value ? String(cell.value).length : 10; // Minimum width 10
                maxWidth = Math.max(maxWidth, cellWidth);
              });
          
              column.width = Math.min(maxWidth + 2, 30); // Set max width for each column
            });
          
            // Freeze the headers to prevent them from scrolling
            worksheet.views = [
              { state: 'frozen', xSplit: 0, ySplit: 1, activeCell: 'A2' }
            ];
          
            // Create a blob from the Excel file
            workbook.xlsx.writeBuffer().then(buffer => {
              const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          
              // Trigger the download
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = "employee_data.xlsx";
              link.click();
            });
          };
          
          
          
        // const exportToExcel = () => {
        //     // Create a workbook and a worksheet
        //     const workbook = new ExcelJs.Workbook();
        //     const worksheet = workbook.addWorksheet("Employee Data");
        
        //     // Add headers to the worksheet
        //     worksheet.addRow(["Name", "Worker ID", "Site", "Agency", "Worker Type"]);
        
        //     // Add data to the worksheet
        //     emplyeeList.forEach(employee => {
        //       worksheet.addRow([
        //         employee.name,
        //         employee.worker_id,
        //         employee.site,
        //         employee.agency,
        //         employee.worker_type
        //       ]);
        //     });
        
        //     // Create a blob from the Excel file
        //     workbook.xlsx.writeBuffer().then(buffer => {
        //       const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        
        //       // Trigger the download
        //       const link = document.createElement("a");
        //       link.href = window.URL.createObjectURL(blob);
        //       link.download = "employee_data.xlsx";
        //       link.click();
        //     });
        //   };


    function handleCloseModalSucess(){
        SetModalCreate(false);
        SetModalDelete(false);
        SetModalUpdate(false)
        SetModalSucess(false)
        SetInc((prev)=>prev +1);
     
    }
    function handleCloseModalError(){
        SetModalError(false)
    }
    

    useEffect(()=>{
        if(searchEmployee.length> 0){
            console.log()
            let filter = emplyeeListAll.filter( item=> item.name.toString().toLowerCase().includes(searchEmployee.toString().toLowerCase()) )
            SetemplyeeList(filter)
        }else{
            SetemplyeeList(emplyeeListAll)
        }
    },[searchEmployee]) 
    

    function showCardemplyeeList(){
       
        let arr = <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
          <AgGridReact
              rowData={emplyeeList}
              pagination={true}
              domLayout='autoHeight'
              paginationPageSize={100} // Set the number of rows per page
              defaultColDef={{
                               sortable: true,
                               resizable: true,
                               flex:1,
                               height:38,
                               headerHeight:38,
                           }}       
           >
               <AgGridColumn 
                   field="name"
                   headerName={"Name"}
                  //  width={WidthContainer - 220}
               ></AgGridColumn>
            <AgGridColumn 
                   field="worker_id"
                   headerName={"Worker ID"}
                  //  width={WidthContainer - 220}
               ></AgGridColumn>
               <AgGridColumn 
                   field="site"
                   headerName={"Site"}
                  //  width={WidthContainer - 220}
               ></AgGridColumn>
                <AgGridColumn 
                   field="agency"
                   headerName={"Agency"}
                  //  width={WidthContainer - 220}
               ></AgGridColumn>
               <AgGridColumn 
                   field="worker_type"
                   headerName={"Worker Type"}
                  //  width={WidthContainer - 220}
               ></AgGridColumn>
          </AgGridReact>
           </div>                
  return arr;
  
                          }
  


    return (

        <div className="main-content side-content pt-0">

        <div className="container-fluid">
            

            {permissionAPICall&&permission.View=='True'?
            
            <div className="inner-body">
        
        
            <div className="page-header">
                    <Row>
                        <Col lg={4}>
                        </Col>
                        <Col lg={2} className="tagAvailStyle"></Col>
                        <Col lg={6} className="header-btn">
                        <input type="text" className='form-control' placeholder="Search Employee" value={searchEmployee} onChange={(e)=>SetsearchEmployee(e.target.value) } style={{width:200,display:"inline-block"}} />
                            {permission && permission.Create=='True'?<button className='btn btn-large btn-header btn-transparent btn-icon' onClick={exportToExcel} ><PlusIcon/> Export</button>:<button className='btn btn-large btn-header btn-transparent btn-icon hover-message disabled btn-delete' disabled><PlusIcon/> Export To Excel<span className='messageTag'>Access Restricted</span></button>}
                            </Col> 
                        </Row>
            </div>
            <div className='br-seperator-row'>
            <div ref={elementRef}>
            {loader?<div className='loaderContainer'>
                    <img src={LoaderSpinner}/></div>:showCardemplyeeList()}
                     
                </div>
            </div>
        
            </div>:permissionAPICall && permission.View=='False'?<Col lg={12}><div className='errorPermission'>Access Restricted to this page, Please contact administrator</div></Col>:""}
        </div>
        
                   
                    <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
                <ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />    
               
        </div>
        
        
              )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(ClientDB))
