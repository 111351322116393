import React, { useEffect, useRef, useState } from 'react';
import { useScript, useStyle } from './useScriptAndStyle';
import { getLocationList } from '../../sitemanagement/actionMethods/actionMethods';
import Light from '../../assets/images/light.png'
import Street from '../../assets/images/street.png'
import Satellite from '../../assets/images/satellite.png'
import Outdoors from '../../assets/images/outdoor.png'
import Dark from '../../assets/images/dark.png'
import '../style/styles.scss'
import { Button, Modal, Select } from 'antd';
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import { CreteGeoMapLocation, getLocationCordinate, removeMapLocation } from '../actionMethods/actionMethods';
import zIndex from '@mui/material/styles/zIndex';
import ReactModal from 'react-modal';
import WarningModal from '../../components/WarningModal';
import LoaderSpinner from  '../../assets/images/loader.svg'
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '350px',
        maxHeight: '90%'
    },
};

const MapComponent = () => {
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const existingPolygons = useRef([]); 
    const [draw, setDraw] = useState(null); // To store the MapboxDraw instance
    const [selectedStyle, setSelectedStyle] = useState('mapbox://styles/mapbox/streets-v11'); // Default style
    const [locationList,SetLocationList]= useState([])
    const [loader,SetLoader]= useState(true);
    const [currentMode,setCurrentMode]=  useState('Streets');
    const [currentModeImg,setCurrentModeImg]= useState(Street);
    const [widthBox,SetWidthBox]= useState(80);
    const [locationSelected,SetLocationSelected]= useState('');
    const [modalLocation,SetModalLocation] = useState(false);
    const [modalSucess,SetModalSucess]= useState(false);
    const [modalError,SetModalError]= useState(false);
    const [title,SetTitle] = useState('');
    const [description,SetDescription]= useState('');
    const [locationCordinates,SetLocationCordinates] = useState([]);
    const [existLocation,SetExistLocation]= useState([]);
    const [deleteModal,SetDeleteModal]= useState(false);
    const [loaderAPI,SetLoaderAPI] = useState(false);

    



    

    let org_id = userDetails.org_id?userDetails.org_id:"";
    const changeMapStyle = (newStyle,title,image) => {

        setSelectedStyle(newStyle);
        setCurrentMode(title);
        setCurrentModeImg(image)
        setTimeout(function(){
          SetWidthBox(80);
        },500)
        
        if (mapRef.current) {
            mapRef.current.setStyle(newStyle);
        }
    };
    const mapBoxMode = [
      {function:'mapbox://styles/mapbox/streets-v11',title:"Streets",image:Street},
      {function:'mapbox://styles/mapbox/outdoors-v11',title:"Outdoors",image:Outdoors},
      {function:'mapbox://styles/mapbox/light-v10',title:"Light",image:Light},
      {function:'mapbox://styles/mapbox/dark-v10',title:"Dark",image:Dark},
      {function:'mapbox://styles/mapbox/satellite-v9',title:"Satellite",image:Satellite}
    ]

    const handleCloseModalSucess =()=>{
      SetModalSucess(false);
      SetModalLocation(false);
      SetDeleteModal(false);
    }
    const handleCloseModalError=()=>{
      SetModalError(false);
    }

    // Load external scripts and styles dynamically
    useScript('https://api.mapbox.com/mapbox-gl-js/v3.7.0/mapbox-gl.js');
    useScript('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.4.0/mapbox-gl-draw.js');
    useScript('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v5.0.0/mapbox-gl-geocoder.min.js');

    useStyle('https://api.mapbox.com/mapbox-gl-js/v3.7.0/mapbox-gl.css');
    useStyle('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.4.0/mapbox-gl-draw.css');
    useStyle('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v5.0.0/mapbox-gl-geocoder.css');
    useScript('https://cdnjs.cloudflare.com/ajax/libs/Turf.js/6.5.0/turf.min.js');

    useEffect(() => {

        // Check if mapboxgl is loaded from the external script

        if (window.mapboxgl && window.MapboxDraw && !loader) {
            window.mapboxgl.accessToken = 'pk.eyJ1IjoiZmFpc2FsMTc3NDAiLCJhIjoiY2p4c3M2dG42MG41bTNndDVzNmF4bHVtbiJ9.ENo4nKK3gSEasAePjO3JDw';

            // Initialize the map
            mapRef.current = new window.mapboxgl.Map({
                container: mapContainerRef.current,
                style: selectedStyle,
                // center: [72.821927, 19.184837],  // Example coordinates
                center: [100.58317249183762,13.72696169281258],  // Example coordinates
                zoom: 19,
                projection: 'globe',
                pitch: 10,
                bearing: -15.6,
            });

            // Add navigation controls to the map
            const nav = new window.mapboxgl.NavigationControl();
            mapRef.current.addControl(nav, 'top-right');

            // Initialize the geocoder
            const geocoder = new window.MapboxGeocoder({
                accessToken: window.mapboxgl.accessToken,
                mapboxgl: window.mapboxgl,
                marker: { color: 'orange' },
            });
            mapRef.current.addControl(geocoder, 'top-left');

            // Handle geocoder results
            geocoder.on('result', (e) => {
                const coordinates = e.result.geometry.coordinates;
                console.log('Selected location:', e.result.place_name, 'Coordinates:', coordinates);
                mapRef.current.flyTo({ center: coordinates, zoom: 12 });
            });

            // Initialize window.MapboxDraw
            console.log("new window.MapboxDraw",new window.MapboxDraw())
            const drawInstance = new window.MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    polygon: true,
                    circle:true,
                    // line_string: true,
                    trash: true,
                    
                },
            });
            mapRef.current.addControl(drawInstance);
            setDraw(drawInstance); // Store the draw instance
            
            // Event listeners for drawing updates
            mapRef.current.on('draw.create', updateCoordinates);
            // mapRef.current.on('draw.update', updateCoordinates);

            // function updateCoordinates() {
            //     const data = drawInstance.getAll();
            //     if (data.features.length > 0) {
            //         data.features.forEach((feature) => {
            //             console.log('Coordinates:', feature.geometry.coordinates);
            //         });
            //     }
            // }
function updateCoordinates() {
  const data = drawInstance.getAll();  
  if (data.features.length > 0) {
    let overlap =false;
    data.features.forEach((feature) => {
      const newPolygon = feature.geometry.coordinates;
      // Check if the new polygon overlaps with any existing polygons using Turf.js
      const isOverlap = existingPolygons.current.some((existingPolygon, index) => {
        const newPolyFeature = window.turf.polygon(newPolygon);
        const existingPolyFeature = window.turf.polygon(existingPolygon);
        console.log("New Feature: ", newPolyFeature, "Existing Feature: ", existingPolyFeature);

        // Check for overlap
        const doesOverlap = window.turf.booleanOverlap(newPolyFeature, existingPolyFeature);
        
        if (doesOverlap) {
          // If an overlap is detected, remove the last polygon from the existing polygons array
          existingPolygons.current.splice(index, 1); // Remove overlapping polygon from the ref
          
          console.log("existingPolygons",existingPolygons)
          console.log('Removed overlapping polygon from ref');
          existingPolygons.current = []; 
          // Display modal error
          SetTitle(<h4>Location Overlapped</h4>);
          SetModalError(true);

          // Clear the drawn polygons from Mapbox
          drawInstance.deleteAll(); 
          setDraw(drawInstance);
          overlap= true;
          console.log('Polygon overlaps with an existing one. It has been removed.');
        }else{
          SetTitle("");
          
        }

        return doesOverlap;
      });

      if (!isOverlap) {
        // If no overlap, add the new polygon to the map
        existingPolygons.current.push(newPolygon);  // Save the polygon coordinates
        SetModalLocation(true)
        console.log('Coordinates:', newPolygon);  // Log the new polygon coordinates
      }else{
                console.log('overlapped occcur Coordinates:', newPolygon);  // Log the new polygon coordinates
      }
    });
  }
}


            
            mapRef.current.on('click', (e) => {
                console.log('Coordinates selected:', e.lngLat);
            });
            
        }

        return () => {
            // Cleanup the map on component unmount
            if (mapRef.current) {
                mapRef.current.remove();
            }
        };
    }, [selectedStyle,loader,locationList,locationCordinates]);

      useEffect(()=>{
      let requestBody={}
      requestBody.session=session;
      requestBody.org_id=org_id
      getLocationList(requestBody).then(res=>{
        if(res.status=200 || res.code == 200){
          SetLocationList(res.data);


          getLocationCordinate(requestBody).then(res2=>{
            if(res2.status === 200){
              SetLocationCordinates(res2.data)
              SetExistLocation(res2.data);
              SetLoader(false);              
            }
          })

        }
      }).catch();
    },[])

    useEffect(() => {
  // Display existing coordinates on the map
  if (locationCordinates.length > 0 && mapRef.current && !loader ) {
    
    locationCordinates.forEach(location => {

    const coordinates = location.coordinates;
    let loc = [...locationList].find(el=>el.tag_serial === location.location_serial	);
          console.log("initial add cordinates",location,loc);
    mapRef.current.on('style.load', () => {
   existingPolygons.current.push([coordinates])
          mapRef.current.addLayer({
        id: `polygon-${loc.id}`,
        type: 'fill',
        source: {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates]
            },
          },
        },
        layout: {},
        paint: {
          // 'fill-color': '#0080ff',
          'fill-opacity': 0
        }
      });

      // Add borders to the polygons
      mapRef.current.addLayer({
        id: `outline-${loc.id}`,
        type: 'line',
        source: {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates]
            },
          },
        },
        layout: {},
        paint: {
          'line-color': '#4ec274',
          'line-width': 1
        }
      });
      addPolygonWithLabel([coordinates],loc.location_name,loc.id)
});

      

    });
  }
}, [locationCordinates,loader,locationList])


    // Function to change map style


    // Function to save coordinates using an API
    const checkCoordinates = (draw) => {
        if (draw) {
            const data = draw.getAll();
            if (data.features.length > 0) {
               SetTitle("");
               SetModalLocation(true)
            }else{
              SetTitle(<h4>Please Select Cordinates First</h4>)
              SetModalError(true)
            }
          }else{
             SetTitle(<h4>Please Select Cordinates First</h4>)
            SetModalError(true)
          }
    }
//     const isOverlapping = (newPolygon, existingPolygons) => {
//   // Convert the coordinates of the new polygon into a Turf.js feature
//   const newPolyFeature = turf.polygon(newPolygon);

//   // Loop through all existing polygons to check for overlap
//   for (let i = 0; i < existingPolygons.length; i++) {
//     const existingPolyFeature = turf.polygon(existingPolygons[i]);

//     // Check if new polygon overlaps with the existing polygon
//     if (turf.booleanOverlap(newPolyFeature, existingPolyFeature)) {
//       return true;  // Overlap found
//     }
//   }

//   return false;  // No overlap
// };

const saveCoordinates = (draw) => {
  if (draw) {
    const data = draw.getAll();
    SetLoaderAPI(true);
    if (data.features.length > 0) {

      const newCoordinates = data.features.map((feature) => feature.geometry.coordinates);
      const coordinates = data.features.map((feature) => feature.geometry.coordinates);
      let requestBody = {
        org_id: org_id,
        session: userDetails.session,
        location_id: locationSelected,
        position: coordinates[0],  // Save first set of coordinates
        sub_org_id: userDetails.sub_org_id
      };

      CreteGeoMapLocation(requestBody).then(res => {
        if (res.status === 200) {
          SetTitle(<h4>Coordinates saved successfully</h4>);
          SetDescription('')
          SetModalSucess(true);
          SetModalLocation(false);
          draw.deleteAll(); 
          SetLocationSelected('');
          let req2= {}
          req2.session = userDetails.session;
          req2.org_id = userDetails.org_id;
          SetLoaderAPI(false);
          getLocationCordinate(req2).then(res2=>{
            if(res2.status === 200){
              SetExistLocation(res2.data);
            }
          })
          // SetLocationCordinates(prevCoordinates => [...prevCoordinates, ...newCoordinates]);
          if(mapRef.current){
            existingPolygons.current.push(requestBody.position)
              mapRef.current.addLayer({
                id: `polygon-${requestBody.location_id}`,  // Unique ID for the new polygon
                type: 'fill',
                source: {
                  type: 'geojson',
                  data: {
                    type: 'Feature',
                    geometry: {
                      type: 'Polygon',
                      coordinates: requestBody.position,  // Polygon coordinates
                    },
                  },
                },
                layout: {},
                paint: {
                  // 'fill-color': '#FF8000',  // Fill color
                  // 'fill-opacity': 1
                  'fill-opacity':0
                }
              });

            // Add outline layer for the polygon
              mapRef.current.addLayer({
                id: `outline-${requestBody.location_id}`,  // Unique ID for the outline
                type: 'line',
                source: {
                  type: 'geojson',
                  data: {
                    type: 'Feature',
                    geometry: {
                      type: 'Polygon',
                      coordinates: requestBody.position,  // Same coordinates as polygon
                    },
                  },
                },
                layout: {},
                paint: {
                  'line-color': '#4ec274',  // Outline color
                  'line-width': 1
                }
              });
              let locExist = [...locationList].find(el=>el.id === locationSelected);
              addPolygonWithLabel(requestBody.position,locExist.location_name ,requestBody.location_id)
              
          }
        } else {
          SetTitle(<h4>Failed to save</h4>);
          SetDescription('Failed to save coordinates');
          SetModalError(true);
        }
      });
    }

  let requestBody = {org_id:36,session:"90d5c7a657774568a33d90678ef28754",location_id:502,position:[[[100.58331119461593,13.726950434259408],[100.58330489572336,13.726865218117496],[100.58343003179579,13.72685313994495],[100.5834552536868,13.726938706446148],[100.58331119461593,13.726950434259408]]],sub_org_id:"0"}
  let newCord = {
    coordinates:requestBody.position[0],
    id:requestBody.location_id,
    location_serial:"",
    org_id:org_id

  }
  console.log("Hurray",newCord);
  try{
   
  }catch(err){
    console.log("err",err);
  }

  

  }

  


};

// const removeLocation=()=>{
//   let id = locationSelected;
//   if (!id) {
//     console.error("Location ID is not valid");
//     return;
//   }

//   console.log(`Removing location with ID: ${id}`);

//   // Construct the unique layer IDs
//   const polygonLayerId = `polygon-${id}`;
//   const outlineLayerId = `outline-${id}`;


//   let requestBody ={}
//   requestBody.session =userDetails.session;
//   requestBody.org_id = userDetails.org_id;
//   requestBody.location_id = id;
//   SetLoaderAPI(true);
//   removeMapLocation(requestBody).then(res=>{
//     if(res.status === 200){
//       let req2= {}
//       req2.session = userDetails.session;
//       req2.org_id = userDetails.org_id;

//       getLocationCordinate(req2).then(res2=>{
//         if(res2.status === 200){
//           SetExistLocation(res2.data);
//         }
//       })
//       SetLoaderAPI(false);
//       SetTitle(<h4>Location is Deleted</h4>);
//       SetLocationSelected('');
//       SetDeleteModal(false);
//       SetDescription('');
//       SetModalSucess(true);
      

//       // Check if the layers exist before trying to remove them
//       if (mapRef.current.getLayer(polygonLayerId)) {
//         mapRef.current.removeLayer(polygonLayerId);  // Remove the polygon layer
//       }else{
//         console.log("id not exist 1",polygonLayerId)
//       }

//       if (mapRef.current.getLayer(outlineLayerId)) {
//         mapRef.current.removeLayer(outlineLayerId);  // Remove the outline layer
//       }else{
//         console.log("id not exist 2",outlineLayerId)
//       }

//       // Also remove the source if no other layers are dependent on it
//       if (mapRef.current.getSource(polygonLayerId)) {
//         mapRef.current.removeSource(polygonLayerId);  // Remove the data source
//       }else{
//         console.log("id not exist 3",outlineLayerId)
//       }

//     }else{
//       SetTitle("Fail to Delete Location");
//       SetDescription('');
//       SetModalError(true);
//     }
//   })
  
// }

const removeLocation = () => {
  let id = locationSelected;
  if (!id) {
    console.error("Location ID is not valid");
    return;
  }

  console.log(`Removing location with ID: ${id}`);

  // Construct the unique layer IDs
  const polygonLayerId = `polygon-${id}`;
  const outlineLayerId = `outline-${id}`;
  const labelLayerId = `label-layer-${id}`;

  let requestBody = {};
  requestBody.session = userDetails.session;
  requestBody.org_id = userDetails.org_id;
  requestBody.location_id = id;
  
  SetLoaderAPI(true);
  removeMapLocation(requestBody).then(res => {
    if (res.status === 200) {
      let req2 = {};
      req2.session = userDetails.session;
      req2.org_id = userDetails.org_id;

      getLocationCordinate(req2).then(res2 => {
        if (res2.status === 200) {
          SetExistLocation(res2.data);
        }
      });

      SetLoaderAPI(false);
      SetTitle(<h4>Location is Deleted</h4>);
      SetLocationSelected('');
      SetDeleteModal(false);
      SetDescription('');
      SetModalSucess(true);

      // Check if the polygon layer exists and retrieve its coordinates
      if (mapRef.current.getSource(polygonLayerId)) {
          console.log("mapRef.current.getSource(polygonLayerId)",mapRef.current.getSource(polygonLayerId))
        const sourceData = mapRef.current.getSource(polygonLayerId)._data;

        // Assuming the data follows the GeoJSON structure
        const polygonCoordinates = sourceData.geometry.coordinates;

        // Remove the polygon from existingPolygons.current based on matching coordinates
        existingPolygons.current = existingPolygons.current.filter((polygon) => {
          return !arePolygonsEqual(polygon, polygonCoordinates);  // Use a helper function to compare
        });
        console.log("existingPolygons.current",existingPolygons.current)
        console.log("Updated existing polygons after deletion:", existingPolygons.current);

        // Now remove the polygonlayerID
        if (mapRef.current.getLayer(polygonLayerId)) {
          mapRef.current.removeLayer(polygonLayerId);  // Remove the polygon layer
        } else {
          console.log("Polygon layer not found:", polygonLayerId);
        }

        //Now remove the outlineLayerId
        if (mapRef.current.getLayer(outlineLayerId)) {
          mapRef.current.removeLayer(outlineLayerId);  // Remove the outline layer
        } else {
          console.log("Outline layer not found:", outlineLayerId);
        }

        //Now remove the getSource polygonLayerId
        if (mapRef.current.getSource(polygonLayerId)) {
          mapRef.current.removeSource(polygonLayerId);  // Remove the data source
        } else {
          console.log("Source not found:", polygonLayerId);
        }


        if (mapRef.current.getSource(outlineLayerId)) {
          mapRef.current.removeSource(outlineLayerId);  // Remove the outline layer
        } else {
          console.log("Outline layer not found:", outlineLayerId);
        }


                // Remove the data source if it's still present
        if (mapRef.current.getSource(labelLayerId)) {
          mapRef.current.removeSource(labelLayerId);  // Remove the data source
        } else {
          console.log("Source not found:", labelLayerId);
        }

        if (mapRef.current.getLayer(labelLayerId)) {
          mapRef.current.removeLayer(labelLayerId);  // Remove the outline layer
        } else {
          console.log("Outline layer not found:", labelLayerId);
        }

      } else {
        console.log("Polygon source not found:", labelLayerId);
      }

    } else {
      SetTitle("Failed to Delete Location");
      SetDescription('');
      SetModalError(true);
    }
  });
};

// Helper function to compare polygons
function arePolygonsEqual(polygon1, polygon2) {
  // Check if the two polygons have the same coordinates
  return JSON.stringify(polygon1) === JSON.stringify(polygon2);
}

function addPolygonWithLabel(polygonCoordinates, labelText, locationId) {
  // Add the polygon layer as before
  mapRef.current.addLayer({
    id: `polygon-${locationId}`,
    type: 'fill',
    source: {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: polygonCoordinates,  // Polygon coordinates
        },
      },
    },
    layout: {},
    paint: {
      'fill-color': '#FF8000',  // Polygon fill color
      'fill-opacity': 0.5,
    },
  });

  // Calculate the centroid of the polygon using Turf.js
  const polygonFeature = window.turf.polygon(polygonCoordinates);
  const centroid = window.turf.centroid(polygonFeature);

  // Add a source and layer for the label using the centroid coordinates
  mapRef.current.addSource(`label-${locationId}`, {
    type: 'geojson',
    data: {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: centroid.geometry.coordinates,  // Label position (polygon center)
      },
      properties: {
        title: labelText,  // Label text
      },
    },
  });

  mapRef.current.addLayer({
    id: `label-layer-${locationId}`,
    type: 'symbol',
    source: `label-${locationId}`,
    layout: {
      'text-field': ['get', 'title'],  // Get text from the 'title' property
      'text-size': 10,
      'text-offset': [0, 0.5],  // Adjust the position of the text relative to the point
      'text-anchor': 'top',  // Position the label above the center point
    },
    paint: {
      'text-color': '#000000',  // Label color
    },
  });
}


    return (
        <div>
            {/* Map container */}
            {loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>:""}
   {/* {!loader?<button className='btn btn-pink btn-move-up' style={{marginTop:-60,zIndex:999,position:"relative"}} onClick={()=>checkCoordinates()}>Add Location</button>:""} */}
    {/* <button className='btn btn-pink btn-move-up' style={{marginTop:-60,zIndex:999,position:"relative"}} onClick={()=>saveCoordinates()}>Save Corrdinates</button> */}
                <div className='removeBtn' style={{marginBottom:20}}>
                {existLocation.map(el=>{
                  let locExist  =locationList.find(elm=>elm.tag_serial	=== el.location_serial);
                  return <button className='btn btn-transparent' style={{marginRight:10}} onClick={()=>{SetLocationSelected(locExist?locExist.id:null);SetTitle(<span style={{color:"red"}}>{locExist.location_name}</span>);SetDeleteModal(true)}}>{locExist?locExist.location_name:el.location_serial} <span className='removeIcon' >x</span></button>
                })}
              </div>
            <div id="container" style={{ display: 'flex', height:"calc(100vh - 120px)",position:"relative" }}>
  
                <div ref={mapContainerRef} style={{ width: '100%' }} />
                            {/* Style changer */}
            {!loader?<div className='absGreatStyle' style={{width:widthBox}}>
              <div onClick={()=>SetWidthBox((prev)=>{if(prev === 80){return 480}else{ return 80}})} className='currentStyle boxStyle' >
                <img src={currentModeImg} />
                <small>{currentMode}</small>
              </div>
              <div className='expandMode'>
                {mapBoxMode.map(el=>{
                  return (<div key={el.title} className={el.title===currentMode?' boxStyle active':'boxStyle'} onClick={()=>changeMapStyle(el.function,el.title,el.image)}>
                                    <img src={el.image} />
                                    <small>{el.title}</small>
                  </div>)
                })}
              </div>

            </div>:""}
          
            </div>


            <ReactModal
                   
          title="Map Location"
          isOpen={modalLocation}
          size={"small"}
          width={350}
// style={{zIndex:0}}
    style={customStyles}
          onCancel={() => {if (draw) {draw.deleteAll()};SetModalLocation(false)}}
                
  
        >

<div className='modalHeader'>
                    <h4>Map location</h4>
                    <div className='closeMark' onClick={()=>SetModalLocation()}>x</div>
                </div>
                <div className='modalBody'>
                    <div className='field-row'>
                        <label>Select Location Name </label>
                                 <Select 
          value={locationSelected}
          onChange={(val)=>SetLocationSelected(val)}
         >
          {locationList.map(el=>{

            return <Select.Option value={el.id} disabled={existLocation.findIndex(elm=>elm.location_serial	 === el.tag_serial) > -1}>{el.location_name}</Select.Option>
          })}

         </Select>
                        
                    </div>
         <button key="close" disabled={loaderAPI} style={{minWidth:"100px",width:"100%"}} className='btn btn-primary btn-large'  onClick={() => {saveCoordinates(draw);}}>
              {loaderAPI?<>Saved Corrdinates <img src={LoaderSpinner} style={{maxWidth:20}} wdith={35} /></>:"Saved Corrdinates"}
            </button>

                </div>
        </ReactModal>
          <WarningModal modalWarning={deleteModal} handleDeleteConfirm={removeLocation} handleCloseModalWarning={()=>{SetLocationSelected('');SetDeleteModal(false)}} title={title} />
				<SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description}  key={'addEMployee'}/>
				<ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  key={'addEMployeeError'} />

            

            {/* Save coordinates */}
            <div>
            </div>
        </div>
    );
};

export default MapComponent;
