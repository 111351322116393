import React, { useState, useEffect } from 'react'
import { CommonHeading } from '../../common/commonHeading';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';

import '../../assets/styles/common.scss'


import moment from 'moment'
import 'antd/dist/antd.css';
import spinnerLoader from '../../assets/images/loader.svg'
import { logoutUser } from '../../login/actionMethods/actionMethods';          
import axios from 'axios';

import { getRoleDetail } from '../../rolemanagement/actionMethods/actionMethods';
import { getCategoryList } from '../../sitemanagement/actionMethods/actionMethods';
import LoaderSpinner from  '../../assets/images/loader.svg'

import ExcelTemplate from '../../assets/working_data_template.xlsm'
import {ReactComponent as HelpIcon} from  '../../assets/images/circle-info.svg'

import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import Loader from '../../assets/images/loader.svg'
import '../styles/styles.scss'
import DatePicker from "react-datepicker";
import { read, utils, writeFile } from 'xlsx';
import {importDataCycleCount,importDataCycleCountValidation} from '../../massupload/actionMethods/actionMethods';
import CycleWarningModal from '../../components/CycleWarningModal';
import { getCycleCount,getLocationCounter,createFacilityCount,updateFacilityCount } from '../actionMethods/actionMethods';
import { Checkbox } from 'antd';
import Scrollbars from 'react-custom-scrollbars';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxWidth:'90%',
        maxHeight: '90%'
    },
};

function FacilityCounter(props) {

    

  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
  let sub_org_id =userDetails.sub_org_id?userDetails.sub_org_id:""
  const [file,setFile] =useState('')
   


    const [selectedLocation,SetSelectedLocation]=useState([])

    const [permission,SetPermission]=useState([])
    const [permissionAPICall,SetPermissionAPICall]=useState(false);
    
    const [inc,SetInc]=useState(0);
    const [loader,Setloader]= useState(true);
    const [ title,SetTitle] =useState('')
    const [description,SetDescription]=useState('')
    const [modalSucess,SetModalSucess]= useState(false)
    const [modalError,SetModalError] = useState(false)
    const [loaderImport,SetLoaderImport] =useState(false);
    const [loaderExport,SetLoaderExport] =useState(false);
    const [locationList,SetLocationList] =useState([]);
    const [modalWarning,SetModalWarning] = useState(false);
    const [apiType,SetAPIType] =useState('update')
    const[facility_count_id , Setfacility_count_id] =useState("");
    useEffect(() => {
        if (props.match.path == '/') {
            props.history.push('/dashboard');
        }
        let requestBody={}
        requestBody.session=session
        requestBody.org_id=userDetails.org_id
        requestBody.role_id =userDetails.role;
        getRoleDetail(requestBody).then(res=>{
            if(res.status==200 || res.code==200){
                SetPermission(JSON.parse(res.data[0].dashboard))
                //Setloader(false);
                SetPermissionAPICall(true)
            }
            if(res.status==300){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)        
            }
        }).catch();
        
    }, []);


    function handleCloseModalSucess(){
      SetModalSucess(false);
      if(apiType=="add"){
        window.location.reload();
      }
      
    }

    function handleCloseModalError(){
      
      SetModalError(false);
    }
    useEffect(()=>{
          let requestBody={};
      
        requestBody.session=userDetails.session;
        requestBody.org_id= userDetails.org_id;
        requestBody.sub_org_id = userDetails.sub_org_id;      

        getCategoryList(requestBody).then(res=>{
            if(res.status==200){
                res.data = res.data.filter(item=>item.location.length > 0);
                let cat = res.data;
                let locLength = []
                for(let i =0;i<cat.length;i++){
                    if(cat[i].location.length> 0){
                        locLength.push(...cat[i].location)
                    }
                    
                }
                SetLocationList(locLength);
                //SetSelectedLocation(locLength)
                Setloader(false);
            }

        })
    },[])
    useEffect(()=>{
          let requestBody={};
      
        requestBody.session=userDetails.session;
        requestBody.org_id= userDetails.org_id;
        requestBody.sub_org_id = userDetails.sub_org_id;
        getLocationCounter(requestBody).then(res=>{
            if(res.status==200){
                //console.log(res.data);
                if(res.data.length==0){
                    SetAPIType('add');
                    SetSelectedLocation(locationList)                    
                }else{
                   // console.log("res.data",res.data,JSON.parse(res.data[0].facility_locations).included_locations.map(e=> { let ex= [...locationList].find(item=>item.id ==e ); if(ex){return ex;} }))
            let incLoc = JSON.parse(res.data[0].facility_locations).included_locations
            let newAr =[]
            for(const item of incLoc){
               // console.log("tested",item,locationList,[...locationList].find(el=>parseInt(el.id) == item));
                newAr.push([...locationList].find(el=>parseInt(el.id) == item));
                
            }
            newAr = newAr.filter(function (element) {

return element !== undefined;

});


                    SetSelectedLocation(newAr);
                    Setfacility_count_id(res.data[0].id);
                }
            }
        })

    },[locationList])



    
     function slugify(string) {  
    return string.toString().toLowerCase().replace(/[^a-zA-Z0-9]/g,'_');
  }






      function checkboxEvent(e,item){
        //console.log(e);
        let loc = [...selectedLocation];
        if(e.target.checked){
            loc.push(item);
            SetSelectedLocation(loc);
        }else{
            let index= loc.findIndex(e=>e.id == item.id);
            loc.splice(index, 1);
           // console.log("index",index,loc)
            SetSelectedLocation(loc);
        }
      }

      function submitHandler(){
        //update api
            let requestBody ={
                "facility_locations" : {
                    "included_locations" : selectedLocation.map(item=>item.id), 
                    "excluded_locations" : locationList.filter(item=> selectedLocation.findIndex(e=>e.id == item.id ) === -1).map(item=>item.id),
                },
                "org_id" : userDetails.org_id,
                "sub_org_id" : userDetails.sub_org_id,
                "session" : userDetails.session
            };

        if(apiType=='add'){
           
           createFacilityCount(requestBody).then(res=>{
            if(res.status==200){
                SetTitle("Facility Counter Created sucessfully");
                //SetDescription("");
                SetModalSucess(true);
            }else{
                SetTitle("Failed to create");
                SetModalError(true);
            }
           })
        }else{
            requestBody.facility_count_id= facility_count_id
           updateFacilityCount(requestBody).then(res=>{
            if(res.status==200){
                SetTitle("Facility Counter updated sucessfully");
                //SetDescription("");
                SetModalSucess(true);
            }else{
                SetTitle("Failed to Update");
                SetModalError(true);
            }
           })
            

        }

      }
    return (

         <div className="main-content side-content pt-0">

<div className="container-fluid">
    <div className="inner-body">

        {loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>: 
        <> 
         {permissionAPICall && permission.View=='True'?<>
        <div className="page-header">
                    <Row>
                
                        <Col lg={4}>
                        </Col>
                        <Col lg={2}></Col>
                        <Col lg={6} className="header-btn">
                            <button type="submit" onClick={submitHandler} className='btn btn-submit btn-transparent'>Submit</button>
                            
                        </Col>
                    
                    </Row>
                </div> 
                
                 <div>
                    <div className="br-seperator-row">
                        <Row style={{margin:"0px"}}>
                            <Col lg={4} className='headerAreaCol'>
                                <h4>Location List</h4>
                            </Col>
                            <Col lg={4} className='headerAreaCol headerAreaColCOlor2'>
                                <h4>Include Location</h4>
                            </Col>
                            <Col lg={4} className='headerAreaCol disabled'>
                                <h4>Excluded Location</h4>
                            </Col>
                        </Row>
                         <Row style={{margin:"0px"}}>
                            <Col lg={4} className='bodyAreaCol'>
                              
                               <div className='area1 SelectIon'>
                              <Scrollbars style={{maxHeight:"600px",height:"600px"}}>
                                    {locationList.map(item=>{
                                        return <div className='locationListLi'><Checkbox checked={selectedLocation.findIndex(el=>el.id==item.id)> -1} onChange={(e)=>checkboxEvent(e,item)}>{item.location_name}</Checkbox></div>
                                    })}
                                    </Scrollbars>  
                               </div>

                            </Col>
                            <Col lg={4}  className='bodyAreaCol bodyAreaCol2'>
                                <div className='area2 selected'>
 <Scrollbars style={{maxHeight:"600px",height:"600px"}}>
                                    {selectedLocation.map(item=>{
                                        return <div className='locationListLi'>{item.location_name}</div>
                                    })}
 </Scrollbars>
                                </div>
                            </Col>
                            <Col lg={4} className='bodyAreaCol disabled'>
                              
                               <div className='area1 SelectIon'>
                              <Scrollbars style={{maxHeight:"600px",height:"600px"}}>
                                    {locationList.map(item=>{
                                        let exist = selectedLocation.findIndex(el=>el.id == item.id);

                                        if(exist == -1){
                                            return <div className='locationListLi'>{item.location_name}</div>
                                        }
                                        
                                    })}
                                    </Scrollbars>  
                               </div>

                            </Col>

                        </Row>
                     <div className='field-row' style={{margin:"20px 0px 40px",textAlign:'right'}}>
                
                          <button type="submit" onClick={submitHandler} className='btn btn-submit btn-transparent'>Submit</button>
                    </div>




                    </div>

                 </div>


    
       
            </>:"" }
 
                {permissionAPICall && permission.View=='False'?<Col lg={12}><div className='errorPermission'>Access Restricted to this page, Please contact administrator</div></Col>:''}

    
        </>}


    </div>
</div>

        <CycleWarningModal modalWarning={modalWarning} handleDeleteConfirm={()=>SetModalWarning(false)} handleCloseModalWarning={()=>SetModalWarning(false)} title={title} />
            <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description}  key={'dashboardSucess'}/>
				<ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  key={'dashboardError'} />

</div>
              )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(FacilityCounter))
