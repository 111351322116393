import React, { useState, useEffect } from 'react'
import { CommonHeading } from '../../common/commonHeading';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';

import '../../assets/styles/common.scss'
import '../../dashboard/styles/dashboard.scss'
import '../styles/styles.scss'

import 'antd/dist/antd.css';
import spinnerLoader from '../../assets/images/loader.svg'
import { logoutUser } from '../../login/actionMethods/actionMethods';          
import { getRoleDetail } from '../../rolemanagement/actionMethods/actionMethods';

import {
        importDataPlanAttendance
      } from '../actionMethods/actionMethods';
      
import LoaderSpinner from  '../../assets/images/loader.svg'


import {ReactComponent as FileImport} from '../../assets/images/file-import.svg'


import {ReactComponent as HelpIcon} from  '../../assets/images/circle-info.svg'
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import Loader from '../../assets/images/loader.svg'
import { Button, Progress, message,Upload ,Modal, Switch,Form } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import moment from 'moment';



const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxWidth:'90%',
        maxHeight: '90%'
    },
};
const { Dragger } = Upload;

function PlanImport(props) {

    

  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';

    const [permission,SetPermission]=useState([])
    const [permissionAPICall,SetPermissionAPICall]=useState(false);
    
    const [loader,Setloader]= useState(true);
  
    const [file, setFile] = React.useState("");
    const [fileError,SetFileError] =useState('');
    const [ title,SetTitle] =useState('')
    const [description,SetDescription]=useState('')

    const [modalSucess,SetModalSucess]= useState(false)
    const [modalError,SetModalError] = useState(false)
    const [loaderImport,SetLoaderImport] =useState(false);
    const [loaderImportPrgogress,SetLoaderImportPrgogress] = useState(false);
    const [inc,SetInc]=useState(0)
    const [errorMessage,SetErrorMessage] =useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [showError, setShowError] = useState(false);
    const [oneChange,SetOneChange] = useState(0);
    const [alreadyExist,SetAlreadyExist]  = useState(true);
    const [validated,SetValidated] = useState(true);
    const [responseText, setResponseText] = useState('');
  
    const [countdown, setCountdown] = useState(300); // 5 minutes countdown
    const [progress, setProgress] = useState(0);
    const [importing, setImporting] = useState(false);
    const [type,SetType]= useState('')

    const handleUpload = async (file,apiEndpoint,type) => {
    setResponseText(''); // Reset the response text

    const orgId = userDetails.org_id;
    const apiUrl = process.env.REACT_APP_URL;
    const removePlan = alreadyExist;
    if(type==="upload"){
      SetLoaderImport(true)
    }
    
    setImporting(true);
    SetType(type)
       
    const interval = setInterval(() => {
      setCountdown(prev => prev - 1);
      setProgress(prev => prev + 1);
      if (countdown === 0) {
        clearInterval(interval);
        setImporting(false);
        message.success('Import done!');
      }
    }, 1000);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('data', JSON.stringify({
      org_id: orgId,
      sub_org_id: userDetails.sub_org_id,
      session: userDetails.session,
      remove_the_already_present_plan: removePlan?"True":"False"
    }));

    console.log(JSON.stringify({
      org_id: orgId,
      sub_org_id: userDetails.sub_org_id,
      session: userDetails.session,
      remove_the_already_present_plan: removePlan?"True":"False"
    }),file)
    try {
      const response = await fetch(`${apiUrl}${apiEndpoint}`, {
        method: 'POST',
        body: formData
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let result = '';
      let done = false;

      while (!done) {
        const { value, done: streamDone } = await reader.read();
        done = streamDone;
        const chunk = decoder.decode(value, { stream: !done });

        if (chunk.trim().startsWith('{')) {
          try {
            const jsonResponse = JSON.parse(chunk);
            
              console.log("jsonResponse1",jsonResponse)  

            if(jsonResponse.data && jsonResponse.status ==200){
                Modal.success({
                  content:<> <h5>Import is successfull</h5><div className='validationResult'>
                  <p style={{marginBottom:5}}>Employees Data Entering Count: <strong>{jsonResponse.data.employees_data_entering_count}</strong></p>
                  <p style={{marginBottom:5}}>Employees Data Updating Count: <strong>{jsonResponse.data.employees_data_updating_count}</strong></p>
                </div></>,})              
            clearInterval(interval)
            SetValidated(true)
            SetLoaderImport(false);
            setImporting(false);
            setProgress(0)
            setCountdown(300)
              
            }else{
              console.log("jsonResponse2",jsonResponse)  
              displayResponse(jsonResponse, apiEndpoint,interval);
            }
            
          } catch (error) {
            setResponseText(prev => prev + `\nError parsing JSON: ${error.message}`);
            SetLoaderImport(false);
            console.log("error",error);

          }
        } else {
          console.log("chunk",chunk)
          if(chunk.includes('"status": 200')){
            console.log("status check true")
              Modal.success({
                  content:<> <h5>Import is successfull</h5></>,})              
            clearInterval(interval)
            SetValidated(true)
            SetLoaderImport(false);
            setImporting(false);
            setProgress(0)
            setCountdown(300)


          }else if(chunk.includes('"status": 201')){
            clearInterval(interval)
            SetValidated(true)
            SetLoaderImport(false);
            setImporting(false);
            setProgress(0)
            setCountdown(300)
          }else{
            console.log("status check False")
          }
          setResponseText(prev => prev + chunk);
          SetLoaderImport(false);
        }
      }
    } catch (error) {
      message.error('Error uploading file: ' + error.message);
    }
  };

  const displayResponse = (response, apiEndpoint,interval) => {
    if (apiEndpoint.includes('create_employees_attendance_report_validation')) {
      const { employees_data_entering_count, employees_data_updating_count, total_time_taken } = response.data;
      
      if(response.status === 200){
        Modal.success({
          content:<> <h5>File Is Validated</h5><div className='validationResult'>
              <p style={{marginBottom:5}}>Validation Result:</p>
              <p style={{marginBottom:5}}>Employees Data Entering Count: <strong>{employees_data_entering_count}</strong></p>
              <p style={{marginBottom:5}}>Employees Data Updating Count: <strong>{employees_data_updating_count}</strong></p>
            </div></>,
  });
      }

      SetValidated(true)
      SetLoaderImport(false);
      setImporting(false);

      
      clearInterval(interval);
            setProgress(0)
      setCountdown(300)
    } else {
      console.log("response ",response)
      setResponseText(prev => prev + "\n\n" + JSON.stringify(response, null, 2));
    }
  };



  
  function formatError(data) {
    let formattedErrors = [];

    // Loop through each key-value pair in the data
    for (const key in data) {
        // If the value is a string, format it directly
        if (typeof data[key] === 'string') {
            formattedErrors.push(<><strong>{key.replaceAll("_"," ")}</strong> {data[key]}</>);
        } else if (typeof data[key] === 'object') {
            // If the value is an object, it may contain multiple worker IDs with errors
            for (const workerId in data[key]) {
                data[key][workerId].forEach(error => {
                    formattedErrors.push(<><strong>{key.replaceAll("_"," ")}</strong> {error}</>);
                });
            }
        }
    }

    return formattedErrors;
}
  const handleImport = () => {
    let requestBody={
      session:userDetails.session,
      org_id:userDetails.org_id,
      sub_org_id:userDetails.sub_org_id
    }
    setImporting(true);
        const interval = setInterval(() => {
      setCountdown(prev => prev - 1);
      setProgress(prev => prev + 1);
      if (countdown === 0) {
        clearInterval(interval);
        setImporting(false);
        message.success('Import done!');
      }
    }, 1000);
    importDataPlanAttendance(requestBody,file).then(res=>{
      if(res.status === 200){
        setCountdown(300);
        setProgress(0);
        setImporting(false);
        SetTitle(<h4>Plan Attendance imported</h4>)
        SetModalSucess(true);
        clearInterval(interval);
      }else{
      
    let err = formatError(res.employees_details_not_entered);

    SetErrorMessage(err);
    setShowError(true);

        let error=[];


        setCountdown(300);
        setProgress(0);
        setImporting(false);
        SetTitle(<h4>Failed To Import</h4>)
        clearInterval(interval);
        // SetModalError(true);
      }
    
    })

    




  };

   const handleFileDrop = fileList => {
    console.log("fileList",fileList)
    const selectedFile = fileList[0];
    console.log("selectedFiles",selectedFile);
    SetOneChange((prev)=>prev + 1);
if(selectedFile){
      if (selectedFile && selectedFile.type === 'application/vnd.ms-excel' || selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setFile(selectedFile.originFileObj);
        setShowAlert(false);
        
      } else {
        setFile([]);
          setShowAlert(true);
          return false;

      }
}

    
  };
    useEffect(() => {

        if (props.match.path == '/') {
            props.history.push('/dashboard')
        }
        let requestBody={}
        requestBody.session=session
        requestBody.org_id=userDetails.org_id
        requestBody.role_id =userDetails.role;
        getRoleDetail(requestBody).then(res=>{
            if(res.status==200 || res.code==200){
              
              let perm = JSON.parse(res.data[0].bulk_permissions)
                  perm.View = res.data[0].bulk_management_page;
                SetPermission(perm)
                SetPermissionAPICall(true)
                Setloader(false);
            }
            if(res.status==300){
                localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                localStorage.removeItem('userLoginDetailsTraceAdmin')
                props.history.push(`/login`)        
            }
        }).catch()
        
    }, []);


    function handleCloseModalSucess(){
      
      SetModalSucess(false);
      // setFile([])
      SetFileError('')
    }

    function handleCloseModalError(){
      
      SetModalError(false);
    }
    






    useEffect(()=>{
    if(inc==6){
        Setloader(false);
    }
    },[inc])

    function loadFile(event){

        let arry = event.target.files[0].name.split('.');
        let lastElement = arry[arry.length - 1];
        // SetDailyWorkerEnable(false);           
        if(lastElement=='xls' || lastElement=='xlsx' || lastElement=='xlsm' ){    
            setFile(event.target.files[0]);    
        }else{
            SetFileError('Only Support Excel file')
            setFile('');    
            setTimeout(function(){
               SetFileError('')
            },3000)
        }
    
    }
    




   return (

         <div className="main-content side-content pt-0">

<div className="container-fluid">
    <div className="inner-body">




        {loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>:
            permission.View=='True' && permissionAPICall?<>
        <div className='page-header'>
          <Row className='box-stat-rows' >
            <Col >
                          <div className='btn-left'>
                <h5 style={{margin:0,fontSize:16}}>Plan Import Attendance</h5>  
               
               
              </div>

            </Col>
          </Row>
         </div>
        <Row className='box-stat-rows' style={{marginTop:30}}>

        


                {permissionAPICall && permission.View=='False'?<Col lg={12}><div className='errorPermission'>Access Restricted to this page, Please contact administrator</div></Col>:
          
                <>
                <div className="box-space-bottom box-space-bottom-2 col-lg-12">
                <div style={{padding: '40px',background:"#fff"}}>
      <h4>Import Plan Attendance</h4>
      <Row>
        <Col lg={12} style={{marginBottom:15}}><Switch checked={alreadyExist} onChange={(val)=>{SetAlreadyExist(val);console.log("swicthed",val)}} /><label style={{display:"inline-block",marginLeft:10}}>Remove The Already Present Plan</label></Col>
      </Row>
      
      <Dragger
        accept=".xlsx, .xls"
        style={{ padding: '20px', textAlign: 'center' }}
        // onDrop={(file)=>handleFileDrop(file)}
        value={file}
        onChange={(file)=>handleFileDrop(file.fileList)}
        beforeUpload={()=> { return false; }}
        maxCount={1}
        disabled={importing}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">Only Excel files are allowed</p>
        
      </Dragger>
      {file.length ===0 && oneChange >0?<div className='overlayWrongFIle'></div>:""}
      <div style={{ marginTop: '20px' }}>
        
        <button className='btn btn-transparent' style={{marginRight:15}} 
          // onClick={handleImport}
          onClick={() => handleUpload(file, '/employee/create_employees_attendance_report_validation','validate')}
          disabled={importing || file.length===0}>
          Validate File
        </button>
        <button className='btn btn-pink' 
        // onClick={handleImport} 
        onClick={() => handleUpload(file, '/employee/create_employees_attendance_report','upload')}
        disabled={importing || file.length===0 || !validated}>
          Start Import
        </button>

      </div>
      
      
        <Modal
          title="Invalid File Format"
          open={showAlert}
          onCancel={() => setShowAlert(false)}
          footer={[
            <Button style={{minWidth:"100px"}} key="ok" onClick={() => setShowAlert(false)}>
              Close
            </Button>
          ]}
        >
          <p>The selected file format is invalid. Please upload a valid Excel file.</p>
        </Modal>
        

        <Modal
          title="File Have Some Issue"
          open={showError}
          onCancel={() => setShowError(false)}
          footer={[
            <Button key="ok" style={{minWidth:"100px"}} onClick={() => setShowError(false)}>
              Close
            </Button>
          ]}
        >
         {errorMessage.map((el,index)=>{
          return <div className='errorList' key={"error"+index}>{el}</div>
         })}
        </Modal>
    
{importing && (
        <div style={{ marginTop: '20px' }}>
          <Progress percent={(progress / 300 * 100).toFixed(2)} status="active" />
          <p>{`Time Left: ${Math.floor(countdown / 60)}:${countdown % 60 < 10 ? '0' : ''}${countdown % 60}`}</p>
          <p className='note'><strong style={{color:"red"}}>Note:</strong> Please Wait {type} may take 5 - 7 min</p>
          <div class="loaderinfinite">
            <div class="arrow arrow-left"></div>
            <div class="arrow arrow-right"></div>
          </div>
        </div>
        
      )}

        {loaderImport?<pre className='ant-upload ant-upload-drag' style={{backgroundColor:"#fafafa",textAlign:"left"}}><div style={{width:"100%",textAlign:"center"}}><img src={LoaderSpinner}/></div></pre>:''}
        {responseText?<pre className='ant-upload ant-upload-drag' style={{backgroundColor:"#fafafa",textAlign:"left",marginTop:20}}>{responseText}</pre>:""}

    </div>
    </div>
                               
                  
                </>
                }

    
        </Row></>:<Col lg={12}><div className='errorPermission'>Access Restricted to this page, Please contact administrator</div></Col>
        
      
      }


    </div>
</div>

            <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description}  key={'dashboardSucess'}/>
				<ErrorModal errorMessage={errorMessage} modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  key={'dashboardError'} />

</div>
              )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(PlanImport))
