import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Modal from 'react-modal'
import Aside from './aside';
import { useHistory } from 'react-router-dom';


import traceplusLogo from '../assets/traceplusImages/trace_logo.png';

import SpinnerLoader from '../assets/images/loader.svg'
import OrganizationList2 from '../organizationmanagement/oemsettings/leftsidebarsettings';

const customStyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.75)'
    },
    content: {
        position: 'absolute',
        width: '25rem',
        height: '13rem',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '1px solid #ccc',
        background: '#fff',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '4px',
        outline: 'none',
        padding: '0'
    }
}


function LeftSideBar(props) {

    const [showLoader, updateShowLoader] = useState(false)

    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let userLogin =localStorage.getItem('isLoggedInGatewayTraceAdmin')
    const history = useHistory();

function ToggledMenu(){
    props.ToggledMenu()
    props.ToggleProfile()

}



    // return (
        // <React.Fragment>
        //     <div className={props.menuActive?"main-sidebar main-sidebar-sticky side-menu":"main-sidebar main-sidebar-sticky side-menu menu-active"}>
                
        //         <div className="sidemenu-logo">
		// 			<a className="main-logo" href="/dashboard">
        //                 <img
        //             src={userDetails && userDetails.org_logo ? userDetails.org_logo : traceplusLogo}
        //             alt="TracePlus Logo"
        //             onError={(e) => {
        //                 e.target.src = traceplusLogo
        //             }}
        //             style={{display:"block"}}
        //         /> 
		// 			</a>
		// 		</div>
  

        //              <Aside ToggledMenu={ToggledMenu} permission ={props.permission} />
        //         {/* <div className="LogoutDiv" onClick={handleLogoutModal}>
        //             <span>Logout</span>
        //         </div> */}
        //     </div>



        //     {
        //         showLoader ?
        //             <div className="wholePageLoaderMainDiv">
        //                 <img src={SpinnerLoader} />
        //             </div> : ''
        //     }
        if(userDetails.org_type === "oem"){
    return (
        <>
        <React.Fragment>
            <div className={props.menuActive?"main-sidebar main-sidebar-sticky side-menu":"main-sidebar main-sidebar-sticky side-menu menu-active"}>
                
                <div className="sidemenu-logo">
					<a className="main-logo"  onClick={() => {
                        props.changeFirstLoad(true);
                        history.push('/organization-management');
                    }}>
                        <img
                    src={userDetails && userDetails.org_logo ? userDetails.org_logo : traceplusLogo}
                    alt="TracePlus Logo"
                    onError={(e) => {
                        e.target.src = traceplusLogo
                    }}
                    style={{display:"block"}}
                /> 
                    {userDetails.org_logo2?<img
                    src={userDetails && userDetails.org_logo2 ? userDetails.org_logo2 : ""}
                    alt="TracePlus Logo"
                    className='sizeVerySMall'
                    onError={(e) => {
                        e.target.src = traceplusLogo
                        e.style.display = "none";
                    }}
                    style={{display:"block"}}
                />:""} 

					</a>
				</div>
                {/* <OrganizationList2/> */}

                    <Aside firstLoad={props.firstLoad} changeFirstLoad={props.changeFirstLoad} ToggledMenu={ToggledMenu} permission ={props.permission}  />
                {/* <div className="LogoutDiv" onClick={handleLogoutModal}>
                    <span>Logout</span>
                </div> */}
            </div>
          
        </React.Fragment>  
        </>
        )}else{
            return (
                <>
                <React.Fragment>
                    <div className={props.menuActive?"main-sidebar main-sidebar-sticky side-menu":"main-sidebar main-sidebar-sticky side-menu menu-active"}>
                        
                        <div className="sidemenu-logo">
                            <a className="main-logo" href="/dashboard">
                                <img
                            src={userDetails && userDetails.org_logo ? userDetails.org_logo : traceplusLogo}
                            alt="TracePlus Logo"
                            onError={(e) => {
                                e.target.src = traceplusLogo
                            }}
                            style={{display:"block"}}
                        /> 
                            </a>
                        </div>
        
                            <Aside   ToggledMenu={ToggledMenu} permission ={props.permission} />
                        {/* <div className="LogoutDiv" onClick={handleLogoutModal}>
                            <span>Logout</span>
                        </div> */}
                    </div>
                  
                </React.Fragment>  
                </>
                )
        }
    
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {})(withRouter(LeftSideBar))

